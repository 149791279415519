import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RoleService } from 'src/app/services/roles/role.service';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { BehaviorSubject, find, firstValueFrom, mergeAll } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AllowedUsers, getUserType, transformCourseData } from './courses.service.helpers';
import { User } from 'src/app/shared/models';
import { Course, CourseProgress } from 'src/app/pages/configuration-pages/interfaces/global-config.interfaces';
import { CourseFEM, defaultCourse } from './courses.service.models';
import { LessonsService } from '../lessons/lessons.service';
import { ProgressService } from '../progress/progress.service';
import { get } from 'lodash';
import { LanguagesControllerService } from 'src/app/core/openapi';

@Injectable({
  providedIn: 'root',
})
export class CoursesService {
  // HELPERS --
  private isOrganization: boolean = this._roles.isOrgOwner() || this._roles.isPortalOwner();
  private isB2c: boolean = this._roles.isB2C();

  // DATA --

  private allCoursesSubject = new BehaviorSubject<CourseFEM[]>([]);
  public allCourses$ = this.allCoursesSubject.asObservable();

  private currentCourseSubject = new BehaviorSubject<CourseFEM>(defaultCourse);
  public currentCourse$ = this.currentCourseSubject.asObservable();

  private rawCourseData: Course[] = [];
  private rawProgressData: CourseProgress[] = [];

  // INIT --

  private shouldReload = false;

  constructor(
    private _auth: AuthService,
    private _rest: RestAPIService,
    private _roles: RoleService,
    private _lessons: LessonsService,
    private _progress: ProgressService,
    private _router: Router,
    private _route: ActivatedRoute,
    private languagesService: LanguagesControllerService,
  ) {}

  /**
   * @scope Loads all courses relevant to a type of user when Course Service initializes and formats the shape of the data from the server into a shape that is easy for the front-end to consume.
   */
  public async initCourses() {
    const params = this._router['currentUrlTree']['queryParams'];

    if (get(params, 'reload', false)) {
      this.shouldReload = true;
    }

    // filter out unnessessary courses
    const user: User = await this._auth.getUser(this.shouldReload);
    const userType: AllowedUsers = getUserType(this.isOrganization, this.isB2c);

    // get courses and progresses
    const courses: Course[] = await this._rest.get('/courses/avaliableCourses/' + userType);
    this.rawCourseData = courses;
    const progresses: CourseProgress[] = user.courseProgresses || [];
    this.rawProgressData = progresses;

    // transform course data in one place
    const allCourses = transformCourseData(courses, progresses);

    this._progress.loadProgresses(progresses);

    // save courses data in service
    this.allCoursesSubject.next(allCourses);

    return allCourses;
  }

  // API --

  public getRawCourseData() {
    return this.rawCourseData;
  }

  public getRawProgressData() {
    return this.rawProgressData;
  }

  public async loadCourses() {
    await this.initCourses();
  }

  public getCurrentCourseId() {
    return this.currentCourseSubject.value.id;
  }

  public getAllCourses() {
    return this.allCoursesSubject.value;
  }

  public loadCourseByActivatedRoute(route: ActivatedRoute): void {
    route.params.subscribe((params) => {
      this.allCoursesSubject
        .pipe(
          mergeAll(),
          find((course) => course.id === params.id),
        )
        .subscribe((course) => {
          this.currentCourseSubject.next(course);
          this._lessons.loadLessons(course);

          this._route.queryParams.subscribe((params) => {
            this._lessons.selectSectionById(params.section);
          });
        });
    });
  }

  public async getLanguages() {
    const languages = await firstValueFrom(this.languagesService.languagesControllerGetLanguages());

    return languages;
  }
}
