import { Component, OnInit } from '@angular/core';
import { RoleService } from 'src/app/services/roles/role.service';

@Component({
  selector: 'header-auth',
  templateUrl: './auth-header.component.html',
  styleUrls: ['./auth-header.component.scss'],
})
export class AuthHeaderComponent implements OnInit {
  constructor(private _roles: RoleService) {}

  public showCredits: boolean = false;

  public isSchoolStudent: boolean = this._roles.isSchoolStudent();
  public isOrgClient: boolean = this._roles.isOrgClient();

  ngOnInit(): void {
    this.showCredits = this.shouldShowCredits();
  }

  public shouldShowCredits(): boolean {
    if (this.isSchoolStudent) {
      return false;
    }
    if (this.isOrgClient) {
      return false;
    }
    return true;
  }
}
