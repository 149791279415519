import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

interface DialogData {
  message: string;
  link: string;
}

@Component({
  selector: 'app-dialog-with-link',
  standalone: true,
  templateUrl: './dialog-with-link.component.html',
  styleUrl: './dialog-with-link.component.scss',
})
export class DialogWithLinkComponent {
  public message: string;
  public link: string;

  constructor(
    public dialogRef: MatDialogRef<DialogWithLinkComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    if (data) {
      this.message = data.message;
      this.link = data.link;
    }
  }

  public async goToLink() {
    if (!this.link) {
      this.closeDialog();
    }

    await this.router.navigate([this.link]);
    this.closeDialog();
  }

  public closeDialog() {
    this.dialogRef.close();
  }
}
