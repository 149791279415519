<app-main-layout
  [breadcrumbs]="[
    { name: 'Home', url: '/' },
    { name: 'Students', url: '/students' },
    {
      name: editUser && editUser.id ? 'Edit Student' : 'New Student',
      url: '/students/profile'
    }
  ]"
>
  <div
    class="inner-content"
    *ngIf="!loading"
  >
    <form [formGroup]="formGroup">
      <div class="form-inputs">
        <h2>{{ editUser && editUser.id ? 'Edit Student' : 'New Student' }}</h2>
        <mat-form-field appearance="outline">
          <mat-label>Full Name</mat-label>
          <input
            matInput
            required
            placeholder="Full Name"
            formControlName="fullname"
          />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Nickname</mat-label>
          <input
            matInput
            placeholder="Nickname"
            formControlName="nickname"
          />
        </mat-form-field>
        <div class="two-columns">
          <mat-form-field
            appearance="outline"
            class="mr-1"
          >
            <mat-label>Theme</mat-label>
            <mat-select
              formControlName="theme"
              [disabled]="themes.length <= 1"
            >
              <mat-option
                *ngFor="let studentTheme of themes"
                value="{{ returnThemeLabel(studentTheme) }}"
                (click)="resetLanguage(studentTheme)"
                >{{ returnThemeLabel(studentTheme) }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Language</mat-label>
            <mat-select formControlName="language">
              <mat-option
                *ngFor="let language of returnLanguages()"
                [value]="language.languageCode"
              >
                {{ language.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <mat-form-field appearance="outline">
          <mat-label>Birthdate</mat-label>
          <input
            matInput
            [matDatepicker]="birthdatePicker"
            placeholder="Birthdate"
            required
            formControlName="birthdate"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="birthdatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #birthdatePicker></mat-datepicker>
        </mat-form-field>
        <mat-checkbox
          formControlName="alertOnSessionEnd"
          *ngIf="level !== 'B2C'"
        >
          Send me an email when student completes a session
        </mat-checkbox>

        <mat-checkbox
          (click)="AllowHomeAccess()"
          formControlName="enableHomeAccess"
          *ngIf="level !== 'B2C'"
        >
          Allow home access
        </mat-checkbox>

        <p
          class="form-error"
          *ngIf="checkFormDisabled()"
        >
          Please check the form, there are invalid fields or required fields
          missing.
        </p>
        <div class="actions">
          <app-simple-button
            *ngIf="!editUser || !editUser.id"
            text="OPEN ARCHIVE"
            (click)="openArchive()"
          ></app-simple-button>
          <app-simple-button
            text="SAVE"
            [disabled]="!formGroup.valid"
            (click)="save()"
          ></app-simple-button>
        </div>
      </div>
      <div class="user-photo">
        <img [src]="formGroup.get('image').value" />
        <app-simple-button
          text="UPLOAD IMAGE"
          (click)="uploadToCloudinary()"
        ></app-simple-button>

        <div class="mt-5">
          <app-simple-button
            *ngIf="!checkIfHasSavedAgenda()"
            text="Create agenda"
            (click)="openOptionsDialog()"
          ></app-simple-button>

          <app-simple-button
            *ngIf="checkIfHasSavedAgenda()"
            text="Open agenda"
            (click)="openStudentAgenda()"
          ></app-simple-button>
        </div>

        <div class="my-3">
          <app-simple-button
            *ngIf="editUser && studentHasTokens"
            text="Access Neuralign"
            (click)="redirectToNeuralign()"
          >
          </app-simple-button>
        </div>
      </div>
    </form>
  </div>
</app-main-layout>
