<div class="container-fluid">
  <div class="row mx-0 mb-3">
    <div class="col-12 mx-0 px-0">
      <h3>{{ titleType }}</h3>
    </div>
  </div>
  <div class="row mx-0 mb-3">
    <div class="col-12 mx-0 px-0">
      <div [ngSwitch]="exportType">
        <ng-container *ngSwitchCase="'user'">
          <div class="table-wrapper">
            <table>
              <tr>
                <th>Id</th>
                <th>Name</th>
                <th>Email</th>
                <th>User Type</th>
                <th>Created At</th>
              </tr>
              <tr *ngFor="let user of preparedUsers;">
                <td>{{user.id}}</td>
                <td>{{user.name}}</td>
                <td>{{user.email}}</td>
                <td>{{user.userType}}</td>
                <td>{{user.creationDate}}</td>
              </tr>
            </table>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'progress'">
          <div
            [ngSwitch]="progressType"
            class="table-wrapper"
          >
            <ng-container *ngSwitchCase="'assessment'">
              <table>
                <tr>
                  <th>Student ID</th>
                  <th>Birthdate</th>
                  <th>Level</th>
                  <th>Pre Assessment</th>
                  <th>Pre Assessment Date</th>
                  <th>Assessment</th>
                  <th>Assessment Date</th>
                  <th>Post Assessment</th>
                  <th>Post Assessment Date</th>
                </tr>
                <tr *ngFor="let prog of preparedProgress;">
                  <td>{{prog.studentId}}</td>
                  <td>{{prog.birthDate}}</td>
                  <td>{{prog.level}}</td>
                  <td>{{prog.pre_assessment}}</td>
                  <td>{{prog.pre_assessment_date}}</td>
                  <td>{{prog.assessment}}</td>
                  <td>{{prog.assessment_date}}</td>
                  <td>{{prog.post_assessment}}</td>
                  <td>{{prog.post_assessment_date}}</td>
                </tr>
              </table>
            </ng-container>
            <ng-container *ngSwitchCase="'speed reading'">
              <table>
                <tr>
                  <th>Student ID</th>
                  <th>Birthdate</th>
                  <th>Lesson Date</th>
                  <th>Session</th>
                  <th>Level</th>
                  <th>Week</th>
                  <th>Score</th>
                  <th>Raw Score</th>
                  <th>Bonus</th>
                </tr>
                <tr *ngFor="let prog of preparedProgress;">
                  <td>{{prog.studentId}}</td>
                  <td>{{prog.birthDate}}</td>
                  <td>{{prog.lessonDate}}</td>
                  <td>{{prog.session}}</td>
                  <td>{{prog.level}}</td>
                  <td>{{prog.week}}</td>
                  <td>{{prog.score}}</td>
                  <td>{{prog.rawScore}}</td>
                  <td>{{prog.bonus}}</td>
                </tr>
              </table>
            </ng-container>
            <ng-container *ngSwitchCase="'cognitive therapy'">
              <table>
                <tr>
                  <th>Student ID</th>
                  <th>Birthdate</th>
                  <th>Lesson Date</th>
                  <th>Session</th>
                  <th>Level</th>
                  <th>Language</th>
                  <th>Game Name</th>
                  <th>Week</th>
                  <th>Score</th>
                  <th>Raw Score</th>
                  <th>Bonus</th>
                </tr>
                <tr *ngFor="let prog of preparedProgress;">
                  <td>{{prog.studentId}}</td>
                  <td>{{prog.birthDate}}</td>
                  <td>{{prog.lessonDate}}</td>
                  <td>{{prog.session}}</td>
                  <td>{{prog.level}}</td>
                  <td>{{prog.lang}}</td>
                  <td>{{prog.name}}</td>
                  <td>{{prog.week}}</td>
                  <td>{{prog.score}}</td>
                  <td>{{prog.rawScore}}</td>
                  <td>{{prog.bonus}}</td>
                </tr>
              </table>
            </ng-container>
            <ng-container *ngSwitchCase="'reading exercises'">
              <table>
                <tr>
                  <th>Student ID</th>
                  <th>Birthdate</th>
                  <th>Lesson Date</th>
                  <th>Session</th>
                  <th>Level</th>
                  <th>Language</th>
                  <th>Game Name</th>
                  <th>Week</th>
                  <th>Score</th>
                  <th>Raw Score</th>
                  <th>Bonus</th>
                </tr>
                <tr *ngFor="let prog of preparedProgress;">
                  <td>{{prog.studentId}}</td>
                  <td>{{prog.birthDate}}</td>
                  <td>{{prog.lessonDate}}</td>
                  <td>{{prog.session}}</td>
                  <td>{{prog.level}}</td>
                  <td>{{prog.lang}}</td>
                  <td>{{prog.name}}</td>
                  <td>{{prog.week}}</td>
                  <td>{{prog.score}}</td>
                  <td>{{prog.rawScore}}</td>
                  <td>{{prog.bonus}}</td>
                </tr>
              </table>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="row mx-0 mt-4">
    <div class="col-12 px-0 button-row">
      <button (click)="exportAsXLSX()">Export</button>
      <button
        class="warn"
        (click)="onCancel()"
      >
        Close
      </button>
    </div>
  </div>
</div>
