import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { get } from 'lodash';
import { AuthService } from '../../../services/auth/auth.service';
import { UsersHelperService } from 'src/app/pages/users/users-helper.service';
@Injectable()
export class SchoolBoardGuard {
  constructor(private _auth: AuthService, private _router: Router, private userHelper: UsersHelperService) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const user = await this._auth.getUser();
    const org = get(user, 'organization', undefined);
    const isSchool = get(org, 'isSchool', false);
    const firstTokenPurchased = await this.userHelper.confirmFirstPurchase();

    if (firstTokenPurchased) {
      this._router.navigate(['users']);
      return false;
    }

    if (!user || !org || !isSchool) {
      this._router.navigate(['']);
      return false;
    }

    return true;
  }
}
