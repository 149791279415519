import { ExcelService } from './../../../services/excel/excel.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { get, startCase, every } from 'lodash';
import { isValidLevel, isWeek } from './export-data.helper';
import { progressTypes, exportTypes } from './../../consts/global-constants';

@Component({
  selector: 'export-data-dialog',
  templateUrl: './export-data.dialog.html',
  styleUrls: ['./export-data.dialog.scss'],
})
export class ExportDataDialogComponent implements OnInit {
  public progress;
  public preparedProgress = [];
  public preparedUsers = [];
  public date = new Date();
  public report = this.data.report;
  public exportType = this.data.exportType;
  public progressType = this.data.progressType;
  public titleType;

  constructor(
    public dialogRef: MatDialogRef<ExportDataDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private excel: ExcelService,
  ) {}

  ngOnInit() {
    this.exportDecider(this.exportType);
    this.titleType =
      (this.exportType ? startCase(this.exportType) : '') +
      (this.progressType ? ' - ' + startCase(this.progressType) : '');
  }

  exportDecider(exportType) {
    if (exportType === exportTypes.USER) {
      this.prepareUsers();
    } else if (exportType === exportTypes.PROGRESS) {
      this.preparedProgress = this.report;
    }
  }

  prepareUsers() {
    this.preparedUsers = this.report.map((user) => {
      const { id, email, createdAt } = user;
      let userType;
      let name;

      if (user.patron) {
        userType = 'Patron';
        name = user.patron.givenName + ' ' + user.patron.familyName;
      } else if (user.organization) {
        userType = 'Organization';
        name = user.organization.name;
      }

      return {
        id,
        name,
        userType,
        email,
        creationDate: new Date(createdAt).toLocaleDateString(),
      };
    });
  }

  prepareProgress(progressType?) {
    if (progressType === progressTypes.ASSESSMENT) {
      this.preparedProgress = this.report.map((rep: any) => {
        return rep.progress.forEach((prog: any) => {
          const {
            creationTime,
            session,
            studentId,
            tag,
            metadata: { score, level },
          } = prog;

          return {
            studentId,
            gameName: tag,
            lessonDate: creationTime,
            score,
            level,
            session,
          };
        });
      });

      this.progress = this.report.map((prog) => {
        const {
          progress: {
            metadata: { score, level },
            studentId,
            session,
            creationTime,
          },
          birthdate,
        } = prog;

        const birthDate = new Date(birthdate).toLocaleDateString();
        const lessonDate = new Date(creationTime).toLocaleDateString();

        let pre_assessment;
        let pre_assessment_date;
        let assessment;
        let assessment_date;
        let post_assessment;
        let post_assessment_date;

        if (session) {
          if (prog.progress.session === 1) {
            pre_assessment = score;
            pre_assessment_date = lessonDate;
          } else if (prog.progress.session === 2) {
            assessment = score;
            assessment_date = lessonDate;
          } else if (prog.progress.session === 3) {
            post_assessment = score;
            post_assessment_date = lessonDate;
          }
        }

        return {
          studentId,
          birthDate,
          level,
          pre_assessment,
          pre_assessment_date,
          assessment,
          assessment_date,
          post_assessment,
          post_assessment_date,
        };
      });

      if (this.progress) {
        this.concatAssesment();
      }
    } else if (progressType === progressTypes.SPEED_READING) {
      this.preparedProgress = this.report.map((rep) => {
        rep.progress.map((prog) => {
          const {
            birthdate,
            progress: {
              studentId,
              session,
              creationTime,
              metadata: {
                exercises: [{ name = '', score = '', rawScore = '', bonus = '', level = '', week = '' } = {}] = [],
              },
            },
          } = prog;

          const birthDate = birthdate ? new Date(birthdate).toLocaleDateString() : '';
          const lessonDate = creationTime ? new Date(creationTime).toLocaleDateString() : '';
          const splitName = name.split('-');
          const currentWeek = splitName.find((str) => isWeek(str)) ?? week;
          const currentLevel = splitName.find((str) => isValidLevel(str)) ?? level;

          return {
            studentId,
            birthDate,
            lessonDate: lessonDate,
            session,
            level: currentLevel,
            week: currentWeek,
            score,
            rawScore,
            bonus,
          };
        });
      });
    } else if (progressType === progressTypes.COGNITIVE_THERAPY || progressType === progressTypes.READING_EXERCISES) {
      let array = [];

      this.preparedProgress = this.report.map((prog) => {
        let preparedExerciseArray = [];

        const {
          birthdate,
          progress: { studentId, session, creationTime },
        } = prog;

        // conditional check to be able to use old and new version of progress exercises structure
        if (every(get(prog, 'progress.metadata.exercises', ''), 'lang')) {
          const {
            progress: {
              metadata: { exercises = [] },
            },
          } = prog;

          preparedExerciseArray = exercises.map((e) => {
            return {
              birthDate: birthdate ? new Date(birthdate).toLocaleDateString() : '',
              lessonDate: creationTime ? new Date(creationTime).toLocaleDateString() : '',
              studentId,
              session,
              ...e,
            };
          });

          array = array.concat(preparedExerciseArray);
        }
        // conditional check to be able to use old and new version of progress exercises structure
        else if (get(prog, 'progress.metadata.exercises', '').every((v) => v.name.includes('-'))) {
          const {
            progress: {
              metadata: { exercises = [] },
            },
          } = prog;

          preparedExerciseArray = exercises.map((e) => {
            const splitName = e.name.split('-');
            e.level = splitName[0];
            e.lang = splitName[1];
            e.name = splitName[2];
            e.week = splitName[3];

            return {
              birthDate: birthdate ? new Date(birthdate).toLocaleDateString() : '',
              lessonDate: creationTime ? new Date(creationTime).toLocaleDateString() : '',
              studentId,
              session,
              ...e,
            };
          });

          array = array.concat(preparedExerciseArray);
        } else {
          const {
            progress: {
              metadata: { exercises = [] },
            },
          } = prog;

          preparedExerciseArray = exercises.map((e) => {
            return {
              birthDate: birthdate ? new Date(birthdate).toLocaleDateString() : '',
              lessonDate: creationTime ? new Date(creationTime).toLocaleDateString() : '',
              studentId,
              session,
              ...e,
            };
          });
          array = array.concat(preparedExerciseArray);
        }
      });

      this.preparedProgress = array;
    } else {
      return;
    }
  }

  concatAssesment() {
    this.progress.forEach((prog) => {
      const foundProgress = this.preparedProgress.find((r) => r.studentId === prog.studentId);
      if (foundProgress) {
        if (prog.pre_assessment) {
          foundProgress.pre_assessment = prog.pre_assessment;
          foundProgress.pre_assessment_date = prog.pre_assessment_date;
        }
        if (prog.assessment) {
          foundProgress.assessment = prog.assessment;
          foundProgress.assessment_date = prog.assessment_date;
        }
        if (prog.post_assessment) {
          foundProgress.post_assessment = prog.post_assessment;
          foundProgress.post_assessment_date = prog.post_assessment_date;
        }
      } else {
        this.preparedProgress.push(prog);
      }
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }
  exportAsXLSX(): void {
    let exportName;

    if (this.exportType === exportTypes.USER) {
      exportName = this.preparedUsers;
    } else if (this.exportType === exportTypes.PROGRESS) {
      exportName = this.preparedProgress;
    }

    this.excel.exportAsExcelFile(exportName, this.titleType + '_' + this.date.toLocaleDateString());
  }
}
