<div class="main">
  <div class="container">
    <div class="title">
      <h2 *ngIf="!userType">Neuralign</h2>
      <h2
        *ngIf="
          userType === 'admin' && data.type !== 'update' && !data.isSettings
        "
      >
        {{ 'Create Organization Admin' | textTransform }}
      </h2>
      <h2
        *ngIf="
          userType === 'admin' && data.type === 'update' && !data.isSettings
        "
      >
        {{ 'Update Organization Admin' | textTransform }}
      </h2>
      <h2
        *ngIf="
          userType === 'manager' && data.type !== 'update' && !data.isSettings
        "
      >
        {{ 'Create Organization Manager' | textTransform }}
      </h2>
      <h2
        *ngIf="
          userType === 'manager' && data.type === 'update' && !data.isSettings
        "
      >
        {{ 'Update Organization Manager' | textTransform }}
      </h2>
      <h2 *ngIf="data.isSettings">
        {{ 'Update your account' | textTransform }}
      </h2>
    </div>

    <div
      *ngIf="userType"
      class="content"
    >
      <div class="profile-photo-container">
        <div
          *ngIf="organizationAdminAndManagerForm.image"
          class="profile-photo-preview"
        >
          <img
            [src]="
              organizationAdminAndManagerForm.image ||
              './assets/img/StudentImagePlaceholder.png'
            "
            alt="Student photo preview"
            class="preview-image"
            (click)="addOrganizationImage()"
          />
          <div class="hover-content">
            <button (click)="addOrganizationImage()">
              <fa-icon [icon]="camera"></fa-icon>
              <span>Change your picture</span>
            </button>
          </div>
        </div>
        <div
          *ngIf="!organizationAdminAndManagerForm.image"
          class="profile-photo-button"
        >
          <button (click)="addOrganizationImage()">
            <fa-icon [icon]="camera"></fa-icon>
          </button>
        </div>
      </div>

      <form
        #form="ngForm"
        novalidate
      >
        <div class="input-block">
          <label for="organizationAdminAndManagerForm.fullname"
            >Full Name <span>*</span></label
          >
          <input
            type="text"
            name="organizationAdminAndManagerForm.fullname"
            [(ngModel)]="organizationAdminAndManagerForm.fullname"
            required
            #fullname="ngModel"
            fullname
            [ngClass]="{
              'is-invalid':
                fullname.invalid && (fullname.dirty || fullname.touched)
            }"
          />
          <div
            *ngIf="fullname.invalid && (fullname.dirty || fullname.touched)"
            class="invalid-feedback"
          >
            <div *ngIf="fullname.errors?.required">
              The fullname is required.
            </div>
          </div>
        </div>

        <div class="input-block">
          <label for="organizationAdminAndManagerForm.nickname">Nickname</label>
          <input
            type="text"
            name="organizationAdminAndManagerForm.nickname"
            [(ngModel)]="organizationAdminAndManagerForm.nickname"
          />
        </div>

        <div class="input-block">
          <label for="organizationAdminAndManagerForm.email"
            >Email <span>*</span></label
          >
          <input
            [disabled]="data.isSettings"
            type="email"
            name="organizationAdminAndManagerForm.email"
            [(ngModel)]="organizationAdminAndManagerForm.email"
            #email="ngModel"
            email
            [ngClass]="{
              'is-invalid': email.invalid && (email.dirty || email.touched)
            }"
          />
          <div
            *ngIf="email.invalid && (email.dirty || email.touched)"
            class="invalid-feedback"
          >
            <div *ngIf="email.errors?.required">The email is required.</div>
            <div *ngIf="email.errors?.email">The Email is invalid.</div>
          </div>
        </div>

        <div
          *ngIf="userType == 'manager' && !data.isSettings"
          class="content-manager"
        >
          <div
            class="checkbox-container"
            *ngIf="!isSchool"
          >
            <label class="custom-checkbox">
              <input
                #fullAccessInput
                name="organizationAdminAndManagerForm.allowFullAccess"
                [checked]="organizationAdminAndManagerForm.allowFullAccess"
                type="checkbox"
                (change)="toggleFullAccess()"
              />
              <span class="checkmark"></span>
              <span class="checkbox-label">
                {{ 'Allow full access to client list' | textTransform }}
              </span>
            </label>
          </div>

          <button
            *ngIf="!isSchool"
            class="btn-manager"
            id="clientListButton"
            [disabled]="organizationAdminAndManagerForm.allowFullAccess"
            (click)="openClientList()"
          >
            {{ 'Open allowed client list' | textTransform }}
          </button>
        </div>

        <p
          *ngIf="userType == 'admin' && !data.isSettings"
          class="notes"
        >
          {{
            'Organization Admins can purchase tokens, add and edit organization
          managers, and add and edit students.'
              | textTransform
          }}
        </p>
        <p
          *ngIf="userType == 'manager' && !data.isSettings"
          class="notes"
        >
          {{
            'Organization Managers can add and edit students.' | textTransform
          }}
        </p>
        <div class="button-group">
          <button
            *ngIf="!stateSave"
            class="btn-secondary"
            (click)="close()"
          >
            Close
          </button>
          <button
            [disabled]="!form.valid"
            *ngIf="!stateSave"
            class="btn-primary"
            (click)="save()"
          >
            Save
          </button>
          <div
            *ngIf="stateSave"
            class="spinner-container"
          >
            <div class="d-flex justify-content-center align-items-center">
              <fa-icon
                class="mx-2"
                [icon]="spinnerIcon"
                [spin]="true"
              >
              </fa-icon>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
