import { Injectable } from '@angular/core';
import { Student } from 'src/app/pages/students/interfaces/student.interface';
import { get } from 'lodash';
import { ThemeService } from '../themes/themes.service';
import { GameStundentDTO } from 'src/app/shared/components/game/game.interface';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class StudentHelperService {
  constructor(private _themeService: ThemeService) {}

  public async getProfileQueryParams(student: Student, themeLabel: string = '') {
    const tokens = get(student, 'tokens', []).length > 0 ? student.tokens.map((t) => JSON.stringify(t)) : [];

    if (!themeLabel) {
      themeLabel = await this._getStudentThemeLabel(student);
    }

    const queryParams = { ...student, themeLabel, tokens };

    return queryParams;
  }

  private async _getStudentThemeLabel(student: Student): Promise<any> {
    const themes = await this._themeService.getEnabledThemes();

    let theme = themes.find((t) => t.id === student.theme);

    if (!theme) {
      theme = this._themeService.getClassicTheme();
    }

    return theme.label.en_ca;
  }

  public _transformStudentAge(student: Student | GameStundentDTO): number {
    return Number(this.getStudentAge(student));
  }

  public _transformStudentName(student: Student): string {
    if (student?.fullname) {
      return student.fullname;
    } else {
      return student.givenName + ' ' + student.familyName;
    }
  }

  public checkDatePattern(date: string) {
    const pattern = /^\d{4}-\d{1,2}$/;
    const fullDatePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

    if (pattern.test(date)) {
      const [year, month] = date.split('-');
      const formattedMonth = month.padStart(2, '0');
      return `${formattedMonth}/${year}`;
    }

    if (fullDatePattern.test(date)) {
      const birthDate = moment.utc(date);
      const month = Number(birthDate.month()) + 1;
      const year = birthDate.year();
      return `${month}/${year}`;
    }

    return date;
  }

  public getStudentAge(student: Student | GameStundentDTO) {
    if (!student.birthdate) {
      return '0';
    }

    if (student.birthdate.startsWith('0')) {
      student.birthdate = student.birthdate.substring(1);
    }

    const birthdate = moment.utc(this.checkDatePattern(student.birthdate), 'M/YYYY', true);
    const age = moment.utc().diff(birthdate, 'years');

    return age.toString();
  }
}
