input[type='month']:placeholder-shown {
  color: gray;
}

.mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.mat-mdc-form-field-error {
  font-size: 12px;
  height: 5px;
  display: flex !important;
  align-items: center;
}

.validationError {
  color: red;
  font-size: 12px;
}

.disabled {
  background-color: grey !important;
  pointer-events: none;
}

.birthDateInput {
  width: 49% !important;

  placeholder-shown {
    color: gray;
  }
}

.loading {
  display: flex;
  justify-content: center;
}

.main {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 100vh;

  overflow: hidden;
}

.container {
  background-color: white;
  padding: 30px;
  border-radius: 5px;
  position: relative;
  width: 700px;
}

.container form {
  margin-top: 20px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  background: none;
  border: none;
}

.container .title {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}

.container .title button {
  position: absolute;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  font-size: 1.2rem;
  display: flex;
  align-items: center;

  margin-top: -30px;
}

.container .title button fa-icon {
  color: #333;
  transition: color 0.3s;
}

.container .title button:hover fa-icon {
  color: #3a3372;
}

.container .title h2 {
  font-weight: bold;
  font-size: 1.5rem;
  font-family: 'Montserrat', Arial, sans-serif;

  color: var(--primary-colour);

  margin-bottom: 20px;

  text-align: center;
}

.container .input-block {
  margin-bottom: 15px;
}

.container .input-group {
  display: flex;
  column-gap: 15px;
}

.container .input-block label {
  display: block;
  font-size: 1rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.input-block input {
  width: 100%;
  padding: 8px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.container .input-block label span {
  color: red !important;
}

.container .input-block-select label span {
  color: red !important;
}

.container .button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 23px;

  button {
    width: 8rem;
    height: 2.6rem;
    font-size: 1rem;
    border-radius: 4px;
  }

  .btn-primary {
    background-color: var(--primary-colour);
    border: none;
    color: #fff;
    float: right;
  }

  .btn-primary:hover {
    background-color: #4d4498;
  }

  .btn-secondary {
    background-color: #6c757d;
    border: none;
    color: #fff;
    float: left;
  }

  .btn-secondary:hover {
    background-color: #5a6268;
  }
}

.profile-photo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.profile-photo-button {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #3a3372;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 1rem;
}

.profile-photo-button button {
  background: none;
  border: none;
}

.profile-photo-button fa-icon {
  color: #fff;
  font-size: 2rem;
}

.profile-photo img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.profile-photo-preview {
  position: relative;
  width: 150px;
  height: 150px;
  overflow: hidden;

  .hover-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(221, 221, 221, 0.8);
    color: white;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease;

    button {
      background: none;
      border: none;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    fa-icon {
      font-size: 24px;
      color: var(--primary-colour);
      margin-bottom: 8px;
    }

    span {
      color: var(--primary-colour);
      font-size: 14px;
      font-weight: 700;
    }
  }

  &:hover .hover-content {
    opacity: 1;
    cursor: pointer;
  }
}

.preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.input-block-select {
  margin-bottom: 15px;
}

.input-block-select select {
  position: relative;
  height: 40px;
  width: 100%;
  outline: none;
  font-size: 1rem;
  color: #707070;
  margin-top: 8px;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 8px 8px;
  box-sizing: border-box;
  appearance: auto;
}

.input-block-select label {
  display: block;
  width: 100%;
}

.container-split {
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
  justify-content: center;
}

.container-left-column,
.container-right-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-group {
  display: flex;
  justify-content: center;
  column-gap: 15px;
  width: 100%;
}

.input-block,
.input-block-select {
  width: 100%;
}

.checkbox-container {
  display: flex;
  align-items: center;
  margin: 15px 0;
}

.custom-checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 1rem;
  user-select: none;
}

.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 2px solid #ddd;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.custom-checkbox:hover input ~ .checkmark {
  border-color: #3a3372;
}

.custom-checkbox input:checked ~ .checkmark {
  background-color: #3a3372;
  border-color: #3a3372;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-label {
  margin-left: 10px;
  color: #333;
  font-family: 'Montserrat', Arial, sans-serif;
  font-weight: 500;
  font-size: 13px;
}

.student-config {
  display: block;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;

  button {
    margin-top: 10px;
    width: 200px;
    height: 40px;
    font-size: 1rem;
    border-radius: 4px;
  }

  .btn-primary {
    background-color: var(--primary-colour);
    border: none;
    color: #fff;
    float: right;
  }

  .btn-primary span {
    margin-left: 10px;
  }
}

.student-config-left {
  display: block;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.stutend-config-right {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.invalid-feedback {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.container-loading {
  min-width: 700px;
  min-height: 700px;
}

.loading-students {
  padding-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  margin-top: 200px;

  .mat-spinner::ng-deep circle {
    stroke: #3a3372;
  }
}

@media (max-width: 1600px) and (min-resolution: 120dpi), (max-width: 1600px) and (min-resolution: 1.25dppx) {
  .main {
    max-height: 90vh;
    overflow-y: auto;
  }

  .container {
    margin-top: 150px;
  }

  .container .button-group {
    margin-top: 30px;

    button {
      width: 6rem;
      height: 2.2rem;
      font-size: 0.875rem;
    }
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--primary-colour);
    border-radius: 4px;

    &:hover {
      background: #4d4498;
    }
  }
}
