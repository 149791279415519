import { Component, OnInit } from '@angular/core';
import { faExclamationCircle, faFileExport, faSchool } from '@fortawesome/free-solid-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GlobalConfiguration } from '../interfaces/global-config.interfaces';

@Component({
  selector: 'app-global-configurations',
  templateUrl: './global-configurations.component.html',
  styleUrls: ['../configurations.style.scss'],
})
export class GlobalConfigurationsComponent implements OnInit {
  public readonly exports: IconDefinition = faFileExport;
  public readonly warningMessage: IconDefinition = faExclamationCircle;
  public readonly school: IconDefinition = faSchool;

  public globalConfigurations: GlobalConfiguration;
  public loading: boolean;
  public selectedOption = 'warningMessage';

  constructor(private _rest: RestAPIService, protected _snackBar: MatSnackBar) {}

  async ngOnInit() {
    await this.getGlobalConfiguration();
  }

  public async getGlobalConfiguration() {
    this.globalConfigurations = await this._rest.get('globalConfig/config', { msg: 'Could not get global config.' });
  }

  public reset() {
    this.ngOnInit();
  }

  public setLoading = (loading: boolean) => {
    this.loading = loading;
  };
}
