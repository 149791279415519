import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { Student } from '../../../students/interfaces/student.interface';
import { StudentsListService } from '../../menus/clients-menu/students-list/students-list.service';
import { get } from 'lodash';
import { TokenService } from 'src/app/services/token/token.service';
import { MatSnackBar } from '@angular/material/snack-bar';

const CHECKMARK_TIMEOUT = 1000;

/**
 * TableAddProgramButton is a button on a table of students
 * that allows the user to spend a credit and enroll a student
 * into Neuralign.
 */
@Component({
  selector: 'users-table-add-program-button',
  templateUrl: './table-add-program-button.component.html',
  styleUrls: ['./table-add-program-button.component.scss'],
})
export class TableAddProgramButtonComponent implements OnInit {
  @Input() student: Student;
  @Output() onAddProgramSuccess = new EventEmitter<Student>();

  template: 'add-program' | 'remove-program' | 'checkmark' | 'loading' | 'locked';

  constructor(
    private _studentsListService: StudentsListService,
    private tokenService: TokenService,
    private snackbar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.setTemplate();
  }

  public startedProgress() {
    return get(this.student, 'hasProgress', false);
  }

  public async handleClick(action: 'add' | 'remove') {
    // Show spinner
    this.template = 'loading';

    if (action === 'add') {
      const tokens = this.tokenService.getAvaliableTokens();

      if (tokens.length === 0) {
        this.snackbar.open(`You dont have credits to add`, 'Close', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          duration: 5000,
        });

        this.template = 'add-program';
        return;
      }
      // Use a token and add the student to Neuralign
      await this._studentsListService.addStudentProgram(this.student);
    } else {
      // Restore token and remove student from Neuralign
      await this._studentsListService.removeStudentProgram(this.student);
    }

    // Show checkmark
    this.template = 'checkmark';

    // Wait a moment, then transfrom the success checkmark into the next icon
    setTimeout(() => {
      this.setTemplate();
    }, CHECKMARK_TIMEOUT);
  }

  /**
   * Show add or remove buttons only if the student hasn't
   * already started using the program. Otherwise, the
   * student is in progress so we show no button.
   */
  private setTemplate() {
    if (this._studentsListService.doesStudentHaveTokens(this.student)) {
      if (this._studentsListService.doesStudentHaveProgress(this.student)) {
        // Student has a token and has started the program
        this.template = 'locked';
      } else {
        // Student has a token but has not started the program
        this.template = 'remove-program';
      }
    } else {
      // Student does not have a token
      this.template = 'add-program';
    }
  }
}
