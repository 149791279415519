import { Router } from '@angular/router';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { LoadingService } from 'src/app/services/utils/loading.service';
import { StripeRedirect } from 'src/app/shared/interfaces/Stripe.interfaces';
import { AuthService } from 'src/app/services/auth/auth.service';
import { PortalConfig } from 'src/app/services/auth/auth-consts/auth-consts';

@Component({
  selector: 'users-organizations-menu',
  templateUrl: './organizations-menu.component.html',
  styleUrls: ['./organizations-menu.component.scss'],
})
export class OrganizationsMenuComponent implements OnInit {
  @Output() setLoading = new EventEmitter();
  constructor(
    private router: Router,
    private _rest: RestAPIService,
    private loading: LoadingService,
    private auth: AuthService,
  ) {}

  @Input() public userHasStripeAccount: boolean = true;
  @Input() public userHasStripeEnabled: boolean = true;

  public listLoaded = false;

  async ngOnInit() {}

  public isSchool(): boolean {
    return this.auth.activeConfig === PortalConfig.SCHOOL;
  }

  adjustDefaultPrice() {
    this.router.navigate(['admin-pricing']);
  }

  public finishLoading() {
    this.listLoaded = true;
  }

  async generateStripeAccount() {
    this.loading.ActivateLoading();
    const res: StripeRedirect = await this._rest.post('account/linkStripe', {
      baseUrl: window.location.href,
    });

    const stripeURL = res.redirectUrl;
    window.open(stripeURL, '_self');
  }
}
