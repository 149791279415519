import { PricingBundle } from '../../pricing/interfaces/pricing-bundle.interface';
import { ContainerTypes } from '../content-configurations/components/categories/utils/categories-utils';

export enum CategoryType {
  VIDEO = 'videoCategory',
  GAME = 'gameCategory',
}

export interface Language {
  flagCode: string;
  id: string;
  languageCode: string;
  name: string;
}

export interface GlobalConfiguration {
  id: string;
  categories: Category[];
  warningMessage: { enabled: boolean; text: string };
  bundles: PricingBundle[];
}

export interface BonusPack {
  enabled: boolean;
  startingSession: number;
  isBonus: boolean;
  id: string;
  games: Game[];
  sessionInterval: number;
  maxSessions: number;
  img: string;
  lockedImg: string;
}

export interface Category extends BonusPack {
  games: Game[];
  img: string;
  lockedImg: string;
  name: string;
  title: string;
  description: string;
  maxSessions: number;
  graphType: string;
  id: string;
  globalConfigId?: string;
  unlockCondition: UnlockCondition[];
  sessionInterval: number;
  bonusPack: BonusPack;
  avaliableLanguages: string[];
  mandatory?: boolean;
  courseId?: string;
  importedFromCourse?: boolean;
  usedOnDemos?: boolean;
  type: CategoryType;
  containerType: ContainerTypes;
  order: number;
  gameDuration: number;
  descriptionList: CategoryDescription[];
  hasProgress: boolean;
}

export interface CategoryDescription {
  languageId: string;
  languageCode: string;
  languageName: string;
  flagCode: string;
  text: string;
}

export interface Game {
  type: string;
}

export interface UnlockCondition {
  name: string;
  session: number;
}

export interface Course {
  title: string;
  name: string;
  price?: number;
  category?: string;
  image?: string;
  lessons?: CourseLesson[];
  id?: string;
  draft: boolean;
  allowedUsers: string[];
  certificateType?: string;
  hasCertificate: boolean;
  enabledToAll: boolean;
  allowedOrganizations: string[];
  useForGameCategory?: boolean;
  language?: string;
}

export interface CourseLesson {
  sections: Section[];
  title: string;
  completed?: boolean;
  _id: string;
  draft: boolean;
}

export interface Section {
  title: string;
  type: string;
  content?: SectionContent;
  completed?: boolean;
  _id: string;
  draft: boolean;
}

export interface SectionContent {
  url?: string;
  slides?: string[];
  download?: string;
  quizes?: Quiz[];
  text?: string;
  survey?: Quiz[];
  slideIndex?: number;
  quizIndex?: number;
}

export interface Quiz {
  question: string;
  type: string;
  choices: QuizChoice[];
  selectedChoices: string[];
  feedBack?: string;
}

export interface QuizChoice {
  correctChoice: boolean;
  text: string;
  isOptional?: boolean;
}

export interface Survey {
  question: string;
  type: string;
  choices: QuizChoice[];
}

export interface CourseProgress {
  id: string;
  lessons: ProgressLessons[];
  courseId: string;
  creationTime: string;
  accountId: string;
  completed: boolean;
  certificateIssued?: boolean;
}

export interface ProgressLessons {
  lessonId: string;
  completed: boolean;
  sections: ProgressSections[];
}

export interface ProgressSections {
  sectionId: string;
  completed: boolean;
}
