import { Injectable } from '@angular/core';
import FileSaver from 'file-saver';
import { WorkSheet, WorkBook, write, utils, writeFile, read } from 'xlsx';
import { EXCEL_EXTENSION, EXCEL_TYPE } from './excel.constants';
import { saveAs } from 'file-saver';

@Injectable()
export class ExcelService {
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const myworksheet: WorkSheet = utils.json_to_sheet(json);
    const myworkbook: WorkBook = { Sheets: { data: myworksheet }, SheetNames: ['data'] };
    const excelBuffer = write(myworkbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(data, fileName + '_exported' + EXCEL_EXTENSION);
  }

  public exportClasswalletStatement(formattedHeaders: string[], rows) {
    const worksheet = utils.aoa_to_sheet([formattedHeaders, ...rows]);

    const borderStyle = {
      top: { style: 'thin', color: { rgb: '000000' } },
      left: { style: 'thin', color: { rgb: '000000' } },
      bottom: { style: 'thin', color: { rgb: '000000' } },
      right: { style: 'thin', color: { rgb: '000000' } },
    };

    const range = utils.decode_range(worksheet['!ref']!);
    for (let row = range.s.r; row <= range.e.r; row++) {
      for (let col = range.s.c; col <= range.e.c; col++) {
        const cell = worksheet[utils.encode_cell({ r: row, c: col })];

        if (cell) {
          cell.border = borderStyle;
          cell.alignment = { horizontal: 'center', vertical: 'center' };
          const colors = ['#FFFFF0', '#D3D3D3'];
          const colorIndex = row % 2;
          cell.fill = {
            fgColor: { rgb: colors[colorIndex].replace('#', '') },
          };

          if (row === 0) {
            cell.font = { bold: true };
          }
        }
      }
    }
    worksheet['!cols'] = [{ wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 15 }, { wch: 18 }, { wch: 18 }];

    const workbook = { Sheets: { Statement: worksheet }, SheetNames: ['Statement'] };

    writeFile(workbook, 'statement.xlsx');
  }

  public downloadFileExample<T>(data: T[], sheetName: string, fileName: string) {
    try {
      const workbook: WorkBook = {
        SheetNames: [],
        Sheets: {},
      };
      workbook.SheetNames.push(sheetName);
      workbook.Sheets[sheetName] = utils.json_to_sheet(data);
      const excelBuffer = write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      const blob = new Blob([excelBuffer], {
        type: EXCEL_TYPE,
      });
      saveAs(blob, `${fileName}-${new Date().toISOString()}${EXCEL_EXTENSION}`);
    } catch (error) {
      throw new Error(error?.message);
    }
  }

  public exportData<T>(data: T[], sheetName: string, fileName: string) {
    try {
      const workbook: WorkBook = {
        SheetNames: [],
        Sheets: {},
      };

      workbook.SheetNames.push(sheetName);
      workbook.Sheets[sheetName] = utils.json_to_sheet(data);
      const excelBuffer = write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      const blob = new Blob([excelBuffer], {
        type: EXCEL_TYPE,
      });
      saveAs(blob, `${fileName}-${new Date().toISOString()}${EXCEL_EXTENSION}`);
    } catch (error) {
      throw new Error(error?.message);
    }
  }

  public readSpreadsheet(file: File, sheetName: string) {
    const reader = new FileReader();
    const data = [];

    return new Promise((resolve, reject) => {
      reader.onload = async (event: ProgressEvent<FileReader>) => {
        try {
          const fileContent = event.target?.result as ArrayBuffer;
          const workbook: WorkBook = read(fileContent, { type: 'binary' });

          const sheet: WorkSheet = workbook.Sheets[sheetName];
          const sheetData = utils.sheet_to_json(sheet);
          sheetData.forEach((row) => {
            data.push(row);
          });
          resolve(data);
        } catch (error) {
          reject(error);
        }
      };

      reader.onerror = () => reject(reader.error);
      reader.readAsArrayBuffer(file);
    });
  }
}
