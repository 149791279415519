import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { get } from 'lodash';
import { Roles } from 'src/app/shared/consts/roles-const';
import { Access, User } from 'src/app/shared/models';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  access: Access;
  user: User;
  orgAcc: User;
  roles: Roles[] = [];

  constructor(private _auth: AuthService) {}

  public setupAccount(access: Access, user: User, orgAcc: User) {
    this.access = access;
    this.user = user;
    this.orgAcc = orgAcc;

    this.setupRoles();
  }

  public setupRoles() {
    if (this.isPortalOwner()) {
      this.roles.push(Roles.PortalOwner);
    }
    if (this.isOrgOwner()) {
      this.roles.push(Roles.OrgOwner);
    }
    if (this.isOrgAdmin()) {
      this.roles.push(Roles.OrgAdmin);
    }
    if (this.isOrgManager()) {
      this.roles.push(Roles.OrgManager);
    }
    if (this.isB2C()) {
      this.roles.push(Roles.B2C);
    }
    if (this.isB2CProvider()) {
      this.roles.push(Roles.B2CProvider);
    }
    if (this.isPatron()) {
      this.roles.push(Roles.Patron);
    }
    if (this.isReseller()) {
      this.roles.push(Roles.Reseller);
    }
    if (this.isOrganizationPortal()) {
      this.roles.push(Roles.Organization);
    }
  }

  public isLogged = (): boolean => get(this._auth, 'accessToken');

  // Ingrid's Account
  public isPortalOwner = (): boolean => get(this.access, 'role') === Roles.PortalOwner;

  // Every other organization account
  public isOrgOwner = (): boolean =>
    get(this.access, 'level') === Roles.B2B && get(this.access, 'role') === Roles.Owner;

  public isOrgAdmin = (): boolean =>
    get(this.access, 'level') === Roles.B2B && get(this.access, 'role') === Roles.OrgAdmin;

  public isOrgManager = (): boolean =>
    get(this.access, 'level') === Roles.B2B && get(this.access, 'role') === Roles.OrgManager;

  public isOrgClient = (): boolean =>
    get(this.user, 'patron') &&
    get(this.access, 'role') === Roles.Client &&
    get(this.access, 'level', '') === Roles.B2B;

  public isB2C = (): boolean => get(this.access, 'level', '') === Roles.B2C;

  public isB2CProvider = (): boolean =>
    (get(this.user, 'organization.isB2CProvider') || get(this.orgAcc, 'organization.isB2CProvider')) &&
    get(this.user, 'organization', undefined);

  public isB2CPortal = (): boolean =>
    get(this.access, 'level') === Roles.B2C && get(this.access, 'role') === Roles.Client;

  public isPatron = (): boolean => get(this.access, 'level') === Roles.B2C || get(this.access, 'role') === Roles.Client;

  public isReseller = (): boolean =>
    get(this.access, 'level') === Roles.B2B &&
    (get(this.access, 'role') === Roles.PortalOwner ||
      get(this.access, 'role') === Roles.Owner ||
      get(this.access, 'role') === Roles.OrgAdmin) &&
    get(this.orgAcc, 'organization.isReseller');

  public isSchool = (): boolean => get(this.orgAcc, 'organization.isSchool', false);

  public checkRole = (role): boolean => role.includes(role);

  public checkAllRoles = (roles: Roles[]): boolean => roles.every((role) => this.checkRole(role));

  public checkAnyRoles = (roles: Roles[]): boolean => roles.some((role) => this.checkRole(role));

  public haveAccessToLms = (): boolean =>
    this.isOrgOwner() ||
    this.isPortalOwner() ||
    this.isB2CPortal() ||
    this.isOrgClient() ||
    this.isOrgAdmin() ||
    this.isOrgManager();

  public isOrganizationPortal = (): boolean =>
    this.isOrgOwner() || this.isPortalOwner() || this.isOrgAdmin() || this.isOrgManager();

  public haveAccessToB2cPaymentsDashboard = (): boolean =>
    this.isB2CPortal() || (this.isB2CProvider() && !this.isOrgAdmin() && !this.isOrgManager());

  public isSchoolStudent = (): boolean => get(this.access, 'role') === Roles.Student;
}
