import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { get, isEmpty } from 'lodash';
import { IconDefinition, faCamera, faCalendar, faArchive, faSpinner } from '@fortawesome/free-solid-svg-icons';

import { RoleService } from 'src/app/services/roles/role.service';
import { User } from 'src/app/shared/models/user.model';
import { SAVE_MODE } from 'src/app/pages/students/student-profile/calendar-dialog/consts/save-mode';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { Organization, Student } from 'src/app/core/openapi';
import { Theme } from 'src/app/pages/configuration-pages/content-configurations/components/themes/interfaces/themes.interface';
import { ThemeService } from 'src/app/services/themes/themes.service';
import { CalendarDialogComponent } from 'src/app/pages/students/student-profile/calendar-dialog/calendar.dialog';
import { StudentsListService } from '../../menus/clients-menu/students-list/students-list.service';
import { DataManager, ManagerActions } from 'src/app/shared/interfaces/Manager.interface';
import { AuthService } from 'src/app/services/auth/auth.service';
import { PortalConfig } from 'src/app/services/auth/auth-consts/auth-consts';
import { CloudnaryService } from 'src/app/services/cloudnary/cloudnary.service';
import { Language } from 'src/app/pages/configuration-pages/interfaces/global-config.interfaces';
import { StudentControllerService } from 'src/app/core/openapi';
import { firstValueFrom } from 'rxjs';
import moment, { Moment } from 'moment';
import { FormControl, Validators } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { defaultLanguageLabel } from 'src/app/pages/students/student-profile/student-constants/default-languages.constant';
import { StudentHelperService } from 'src/app/services/student/student-helper.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'student-create-modal',
  templateUrl: './student-create-modal.component.html',
  styleUrls: ['./student-create-modal.component.scss'],
  providers: [provideMomentDateAdapter(MY_FORMATS)],
  encapsulation: ViewEncapsulation.None,
})
export class StudentCreateModalComponent implements OnInit {
  @ViewChild('picker') datepicker: MatDatepicker<any>;

  public readonly camera: IconDefinition = faCamera;
  public readonly calendar: IconDefinition = faCalendar;
  public readonly archive: IconDefinition = faArchive;
  public readonly spinnerIcon: IconDefinition = faSpinner;

  public orgAcc: User;
  public org: Organization;

  public archivedStudents: Student[];

  public clientId: string;

  public studentHasTokens = false;
  public student;
  public studentAgenda: any;

  public date: FormControl<Moment | string> = new FormControl('', [Validators.required]);

  //Form and data properties
  studentForm: Student = {
    fullname: '',
    nickname: '',
    givenName: '',
    theme: '',
    language: '',
    birthdate: '',
    alertOnSessionEnd: false,
    enableHomeAccess: true,
    image: '',
    grade: 1,
  };

  public studentEmail: string = '';
  public languages: Language[];
  public languagesTheme: Language[] = [];
  public themes: Theme[] = [];

  public isSchoolSetup = false;
  public saveLoading = false;
  public initLoading = false;
  public imagePlaceholder = './assets/img/StudentImagePlaceholder.png';
  public today = new Date();

  public birthdateValidationError = false;

  public errorMessage = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DataManager,
    private _roles: RoleService,
    private _rest: RestAPIService,
    protected _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<StudentCreateModalComponent>,
    private _dialog: MatDialog,
    private themeService: ThemeService,
    private _studentsListService: StudentsListService,
    private authService: AuthService,
    private cloudinary: CloudnaryService,
    private studentControllerService: StudentControllerService,
    private studentHelperService: StudentHelperService,
  ) {
    this.isSchoolSetup = this.authService.activeConfig === PortalConfig.SCHOOL;
    this.clientId = data?.clientId;
    if (data?.student) {
      this.initStudentForm(data);
    }
  }

  async ngOnInit() {
    this.initLoading = this.data?.student ? true : false;
    await this.whoAmI();
    await this.loadThemes();
    await this.loadLanguages();
    this.initLoading = false;
    if (this.student) {
      await this.findStudentAgenda();
    }
  }

  open() {
    this.datepicker.open();
  }

  setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
    const currentYear = moment().year();
    const selectedYear = normalizedMonthAndYear.year();

    if (selectedYear > currentYear) {
      this._snackBar.open(
        'Please select a valid year. The chosen year cannot be greater than the current year.',
        'Close',
        {
          horizontalPosition: 'center',
          verticalPosition: 'top',
        },
      );
      this.studentForm.birthdate = '';
      datepicker.close();
    } else {
      const value = moment();
      value.month(normalizedMonthAndYear.month());
      value.year(normalizedMonthAndYear.year());
      this.date.setValue(value);

      const month = Number(value.month()) + 1;
      const year = value.year();

      this.studentForm.birthdate = `${month}/${year}`;

      datepicker.close();
    }
  }

  public formatDateToMonthYear(dateString: string): Moment {
    const date = moment.utc(this.studentHelperService.checkDatePattern(dateString), 'M/YYYY', true);
    const value = moment();
    value.month(date.month());
    value.year(date.year());

    return value;
  }

  public onThemeChange(themeId: string) {
    const theme = this.themes.find((theme) => theme.id === themeId);
    if (!theme) return;

    const languageCodesMap = new Map(theme.languages?.map((lang) => [lang.languageCode, true]) ?? []);

    this.languagesTheme = this.languages.filter((language) => languageCodesMap.has(language.languageCode));

    const languageCodes = this.languagesTheme.map((l) => l.languageCode);

    if (!languageCodes.includes(this.studentForm.language)) {
      const defaultLanguage = this.languages.find((l) => l.languageCode === defaultLanguageLabel);

      if (defaultLanguage) {
        this.studentForm.language = defaultLanguage.languageCode;
      }
    }
  }

  public getBirthDate(birthdate: Moment) {
    if (!birthdate) {
      return;
    }
    const month = Number(birthdate.month()) + 1;
    const year = birthdate.year();

    return `${month}/${year}`;
  }

  private async initStudentForm(data: DataManager) {
    await this.loadThemes();
    await this.loadLanguages();
    this.student = data.student;
    this.onThemeChange(this.student.theme);

    if (this.isSchoolSetup) {
      this.studentEmail = data.student?.email ?? (await this.getStudentEmail());
    }

    const birthdate = this.formatDateToMonthYear(data.student.birthdate);
    this.date.setValue(birthdate);

    const language = this.languages.find((language) => language.languageCode === data.student.language);

    this.studentForm = {
      ...data.student,
      birthdate: this.getBirthDate(birthdate),
      language: language ? language.languageCode : '',
    };
  }

  private async whoAmI() {
    this.orgAcc = this._roles.orgAcc;
    const response = await this._rest.get('organization/self', { msg: 'Could not get organization.' });
    if (response) {
      this.org = response?.organization;
    }
  }

  private async loadThemes() {
    this.themes = await this.themeService.getEnabledThemes();
  }

  private async loadLanguages() {
    this.languages = await this._rest.get('languages', {
      msg: 'Could not get languages.',
    });

    this.languagesTheme = this.languages;
  }

  async save() {
    if (this.date.invalid) {
      return;
    }

    switch (this.data?.type) {
      case ManagerActions.CREATE:
        await this.create();
        break;

      case ManagerActions.UPDATE:
        await this.update();
        break;

      default:
        break;
    }
  }

  public onInputChange(event) {
    if (event) {
      const isValid = /^\d{4}-(0[1-9]|1[0-2])$/.test(event);

      if (isValid) {
        this.errorMessage = '';
        this.birthdateValidationError = false;
      }
    }
  }

  public async create() {
    try {
      this.saveLoading = true;
      // Ensure we have the user loaded
      await this.authService.getUser();
      const activeConfig = this.authService.activeConfig;
      const currentOrgAcc = this.authService.getOrgAcc();
      const currentOrg = currentOrgAcc.organization;

      const fullNameSplit = this.studentForm.fullname.split(' ');
      const givenName = fullNameSplit.shift();
      const familyName = fullNameSplit.join(' ');

      this.studentForm.birthdate = this.getBirthDate(this.date.value as Moment);

      const student = {
        ...this.studentForm,
        givenName,
        familyName,
      };

      if (isEmpty(student.image)) {
        student.image = this.imagePlaceholder;
      }

      const creationUrl =
        activeConfig === PortalConfig.SCHOOL
          ? this.getSchoolStudentCreationUrl(currentOrg.id)
          : this.getDefaultStudentCreationUrl();

      const response = await this._rest.post(creationUrl, student);
      if (!response) {
        throw new Error('Student creation failed. Please try again');
      }
      this.close(response);
      this.openSnackbar('Student created succefully!');
      this.saveLoading = false;
    } catch (error) {
      this.saveLoading = false;
      this.openSnackbar(`${error.message}`);
    }
  }

  public shouldDisableSubmitBtn() {
    let isValidPattern = true;
    const language = this.studentForm.theme;
    const theme = this.studentForm.theme;
    const name = this.studentForm.fullname;
    const invalidBirthdate = this.date.invalid;

    if (this.isSchoolSetup) {
      const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      const isValidEmailPattern = this.studentEmail.match(validRegex);

      isValidPattern = isValidEmailPattern != undefined;
    }

    return !language || !theme || !name || !isValidPattern || invalidBirthdate;
  }

  public async getStudentEmail() {
    if (this.authService.activeConfig !== PortalConfig.SCHOOL) {
      return '';
    }

    const studentEmail = await firstValueFrom(
      this.studentControllerService.studentControllerGetStudentEmail(this.student.id),
    );
    return studentEmail?.email ?? '';
  }

  public getSchoolStudentCreationUrl(orgId: string) {
    return '/school/id/' + orgId + '/student/' + this.studentEmail + '/create';
  }

  public getDefaultStudentCreationUrl() {
    return 'student/' + (this.clientId || 'self');
  }

  public async update() {
    const studentId = get(this.data, 'student.id', '');

    if (!studentId) {
      this.openSnackbar('Student not found');
      return;
    }

    try {
      const [givenName, familyName = ''] = this.studentForm.fullname.split(' ');
      this.studentForm.givenName = givenName;
      this.studentForm.familyName = familyName;
      this.studentForm.birthdate = this.getBirthDate(this.date.value as Moment);

      const response = await this._rest.put('student/' + studentId, { student: this.studentForm });
      if (!response) {
        throw new Error('Student updated failed');
      }

      this.close(response);
      this.openSnackbar('Student updated successfully!');
    } catch (error) {
      this.openSnackbar(`Student update failed ${error.message}`);
    }
  }

  public close(student?: Student) {
    const result = student ? { student: student } : true;
    this.dialogRef.close(result);
  }

  public async addStudentImage() {
    try {
      const image: string = await this.cloudinary.handleUpload();
      this.studentForm.image = image;
    } catch (error) {
      this.openSnackbar(error.message);
    }
  }

  public async findStudentAgenda() {
    if (this.student.id) {
      const response = await this._rest.get('student/' + this.student.id, {
        msg: 'Could not get student.',
      });
      if (response) {
        this.studentAgenda = response.student.agenda;
      }
    }
  }

  public async openOptionsDialog() {
    await Promise.resolve();
    const calendarDialog = this._dialog.open(CalendarDialogComponent, {
      data: {
        agenda: this.studentAgenda,
        saveMode: SAVE_MODE.SAVE,
      },
      panelClass: 'modal-border',
    });

    calendarDialog.afterClosed().subscribe((agenda) => {
      if (!agenda) {
        return;
      }

      this.studentAgenda = agenda;
    });
  }

  public checkIfHasSavedAgenda() {
    if (!this.student) {
      return false;
    }

    return this.studentAgenda || this.student.agenda ? true : false;
  }

  public openStudentAgenda() {
    const calendarDialog = this._dialog.open(CalendarDialogComponent, {
      data: {
        agenda: this.studentAgenda,
        saveMode: SAVE_MODE.SAVE,
      },
      panelClass: 'modal-border',
    });

    calendarDialog.afterClosed().subscribe((agenda) => {
      if (!agenda) {
        return;
      }

      this.studentAgenda = agenda;
    });
  }

  private openSnackbar(message) {
    this._snackBar.open(message, 'Close', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }
}
