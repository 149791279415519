<app-main-layout
  [breadcrumbs]="[
    { name: 'Home', url: '/' },
    { name: 'Course configs', url: '/course-configurations' }
  ]"
>
  <div class="header-container">
    <span>Courses</span>
    <div class="actions">
      <input
        type="text"
        placeholder="Search course"
        [(ngModel)]="searchTerm"
      />
      <div class="add-button">
        <button
          mat-raised-button
          (click)="openCourseCreator()"
        >
          Add new
          <fa-icon [icon]="faPlus"></fa-icon>
        </button>
      </div>
    </div>
  </div>

  <div
    *ngIf="loading"
    class="loading-courses"
  >
    <mat-spinner></mat-spinner>
  </div>

  <div
    class="mainContainer border"
    *ngIf="!loading"
  >
    <div
      class="col-8 mx-auto px-0 mt-5"
      *ngIf="selectedOption === 'courses'"
    >
      <app-learning-management-system
        [getCourseList]="courseList"
        [searchTerm]="searchTerm"
        (searchTermChange)="searchTerm = $event"
        (reset)="reset()"
      >
      </app-learning-management-system>
    </div>
  </div>
</app-main-layout>
