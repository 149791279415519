import { Component, OnInit } from '@angular/core';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { Student } from './interfaces/student.interface';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faEdit,
  faUsers,
  faTrash,
  faSearch,
  faPlus,
  faMinus,
  faCalendarAlt,
  faGamepad,
} from '@fortawesome/free-solid-svg-icons';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { TutorialComponent } from 'src/app/shared/models/tutorial.model';
import { isEmpty } from 'lodash';
import { User } from 'src/app/shared/models/user.model';
import { Router } from '@angular/router';
import { AlertDialogComponent } from 'src/app/shared/dialogs/alert/alert.dialog';
import { CalendarDialogComponent } from './student-profile/calendar-dialog/calendar.dialog';
import { SAVE_MODE } from './student-profile/calendar-dialog/consts/save-mode';
import { ConfirmationService } from 'src/app/services/confirmation/confirmation.service';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { RoleService } from 'src/app/services/roles/role.service';
import { get } from 'lodash';
import { ThemeService } from 'src/app/services/themes/themes.service';
import { Theme } from '../configuration-pages/content-configurations/components/themes/interfaces/themes.interface';
import { TutorialsService } from 'src/app/services/tutorials/tutorials.service';
import { StudentHelperService } from 'src/app/services/student/student-helper.service';
import { Token } from 'src/app/shared/models';

import { MessagesService } from '../messages/messages.service';
import { StudentCreateModalComponent } from '../users/modals/student-create-modal/student-create-modal.component';
import { ManagerActions } from 'src/app/shared/interfaces/Manager.interface';
import { TokenService } from 'src/app/services/token/token.service';
import { StudentsListService } from '../users/menus/clients-menu/students-list/students-list.service';
import { StudentControllerService } from 'src/app/core/openapi';
import { ProgramControllerService } from 'src/app/core/openapi';
import { TokenControllerService } from 'src/app/core/openapi';
import { firstValueFrom } from 'rxjs';

enum Sort {
  ASC = 'asc',
  DESC = 'desc',
}

@Component({
  selector: 'app-students',
  templateUrl: './students.component.html',
  styleUrls: ['./students.component.scss'],
})
export class StudentsComponent implements OnInit {
  public readonly displayedColumns: string[] = ['name', 'nickname', 'age', 'tag', 'actions'];
  public readonly usersIcon: IconDefinition = faUsers;
  public readonly editIcon: IconDefinition = faEdit;
  public readonly searchIcon: IconDefinition = faSearch;
  public readonly trash: IconDefinition = faTrash;
  public readonly plusIcon: IconDefinition = faPlus;
  public readonly minusIcon: IconDefinition = faMinus;
  public readonly calendarIcon: IconDefinition = faCalendarAlt;
  public readonly gamepadIcon: IconDefinition = faGamepad;

  public students: MatTableDataSource<Student>;
  public tutorial: TutorialComponent;
  public showTutorial = false;
  public onCompleteTutorial: () => void;
  public user: User;
  public org: any = 'loading';
  public themes: Theme[];
  public selectedStudent: any;
  public today = new Date();

  public isDropdownOpen: boolean = false;

  private _pendingWork = false;

  public sortField = null;
  public sortDirection = Sort.ASC;
  public columns = ['fullname', 'nickname', 'age', 'programs'];
  public searchTerm: string;
  public originalStudents: Student[];

  public isB2CPortal: boolean = this._roles.isB2CPortal();
  public clientId: string;
  public isLoading: boolean = false;
  public isActionLoading: { [studentId: string]: boolean } = {};

  constructor(
    protected _snackBar: MatSnackBar,
    private _studentsListService: StudentsListService,
    private _rest: RestAPIService,
    private _dialog: MatDialog,
    private _router: Router,
    private _roles: RoleService,
    private _confirm: ConfirmationService,
    private _logger: LoggerService,
    private themeService: ThemeService,
    private tutorialService: TutorialsService,
    private studentHelper: StudentHelperService,
    private messageService: MessagesService,
    private tokenService: TokenService,
    private studentController: StudentControllerService,
    private programController: ProgramControllerService,
    private tokenController: TokenControllerService,
  ) {}

  async ngOnInit() {
    this.isLoading = true;
    this.user = this._roles.user;
    this.clientId = get(this.user, 'patron.id', '');

    if (get(this.user, 'organization')) {
      this._router.navigate(['/users']);

      return;
    }

    await this._loadStudents();
    this._setupTutorial();
    this.org = await this._rest
      .get('organization/self', { msg: 'Could not get organization.' })
      .catch(() => (this.org = null));
    await this.loadThemes();
    await this.messageService.initUserMessage();
  }

  public acceptTerms() {
    this._confirm
      .createConfirmation(
        'Terms of Service',
        'You must agree with our &nbsp; <a target="_blank" href="/terms-of-service"> Terms of Service </a>  to continue with access to your LSWorks portal',
        'I agree',
        undefined,
        '400px',
        true,
      )
      .then(async () => {
        try {
          this.user.patron.acceptedTerms = true;
          await this._rest.put(
            'patron/self',
            {
              patron: this.user.patron,
            },
            { msg: 'Could not put organization' },
          );
          // eslint-disable-next-line no-empty
        } catch {}
      });
  }

  public isOrgClient(): boolean {
    if (!this._roles.access) {
      return true;
    }
    return this._roles.access.level === 'B2B' && this._roles.access.role === 'Client';
  }

  public getAgeFromBirthday(student: Student): string {
    const studentAge = this.studentHelper.getStudentAge(student);
    return studentAge.toString();
  }

  public presentArchiveStudent(student: Student): void {
    const canArchive = !this.canDisassociateStudent(student);
    if (canArchive) {
      this._confirm
        .createConfirmation(
          'Warning',
          `Are you sure you want to delete ${!isEmpty(student.fullname) ? student.fullname : 'this student'}?`,
          'Yes',
          'No',
          '350px',
        )
        .then(async () => {
          this._archiveStudent(student);
        });
    } else {
      this.isLoading = false;
      this._dialog.open(AlertDialogComponent, {
        width: '450px',
        data: 'The assigned credit should be retrieved by clicking on the - sign before archiving the student.',
      });
    }
  }

  public async associateStudent(student: Student): Promise<void> {
    this.isActionLoading[student.id] = true;
    try {
      if (this._pendingWork) {
        return;
      }

      this._pendingWork = true;
      const response = await firstValueFrom(this.tokenController.tokenControllerReadSelf());
      if (!response.tokens || response.tokens.length === 0) {
        this._pendingWork = false;
        this._router.navigate(['programs-pricing']);
        return;
      }

      const availableToken = response.tokens.find((t: Token) => !t.studentId && t.paymentConfirmed);
      if (!availableToken) {
        this._pendingWork = false;
        this._router.navigate(['programs-pricing']);
      } else {
        const allowHomeAccess = get(student, 'enableHomeAccess', false);

        const response = await firstValueFrom(
          this.tokenController.tokenControllerAssossiateStudent(availableToken.id, student.id, allowHomeAccess),
        );

        if (!response) {
          this._snackBar.open(
            'Could associate the token to this student, please try again. If the problem persists, contact us!',
            'Close',
            {
              horizontalPosition: 'center',
              verticalPosition: 'top',
            },
          );
          return;
        }

        const result = await this.checkAssociateStudentToken(student);
        if (result) {
          this.isActionLoading[student.id] = false;
          this.tokenService.refreshCredits.emit();
          this._pendingWork = false;
        }
      }
    } catch {
      this._pendingWork = false;
      this.isActionLoading[student.id] = false;
    }
  }

  public canDisassociateStudent(student: Student): boolean {
    return student.tokens && student.tokens.length > 0 && student.progress && student.progress.length === 0;
  }

  public async disassociateStudent(student: Student): Promise<void> {
    this.isActionLoading[student.id] = true;
    try {
      if (this._pendingWork) {
        return;
      }
      this._pendingWork = true;

      const token = student.tokens[0];
      const updatedToken = await firstValueFrom(this.tokenController.tokenControllerDisassossiateStudent(token.id));

      if (!updatedToken) {
        this._snackBar.open(
          'Could disassociate the token to this student, please try again. If the problem persists, contact us!',
          'Close',
          {
            horizontalPosition: 'center',
            verticalPosition: 'top',
          },
        );
        return;
      }

      const result = await this.checkDisassociateStudentToken(student, updatedToken);

      if (result) {
        this.isActionLoading[student.id] = false;
        this.tokenService.refreshCredits.emit();
        this._pendingWork = false;
      }
    } catch {
      this._pendingWork = false;
      this.isActionLoading[student.id] = false;
    }
  }

  public async checkAssociateStudentToken(student: Student): Promise<boolean> {
    try {
      const studentResult = await firstValueFrom(this.studentController.studentControllerRead(student.id));
      const tokens = get(studentResult, 'tokens', []);
      const updatedStudent = get(studentResult, 'student', {});
      const programId = get(tokens[0], 'programs', [])[0];

      if (!programId) {
        throw new Error('Program not found');
      }

      const program = await firstValueFrom(this.programController.programControllerGetProgram(programId));
      const newStudent = {
        ...updatedStudent,
        tokens: [
          {
            allowCompleteAtHome: get(tokens[0], 'allowCompleteAtHome', false),
            id: get(tokens[0], 'id', ''),
            paymentConfirmed: get(tokens[0], 'paymentConfirmed', false),
          },
        ],
        progress: [],
        programs: [program],
      };
      this.updateDataSource(newStudent);
      return true;
    } catch (error) {
      return false;
    }
  }

  public updateDataSource(student: Student): void {
    const dataSourceCopy = [...this.students.data];
    const studentIndex = this.students.data.findIndex((s: Student) => s.id === student.id);

    if (studentIndex !== -1) {
      dataSourceCopy[studentIndex] = student;
    } else {
      dataSourceCopy.push(student);
    }
    this.students.data = dataSourceCopy;
    this.originalStudents = dataSourceCopy;
  }

  public async checkDisassociateStudentToken(student: Student, token: Token): Promise<boolean> {
    try {
      const studentTokens = get(student, 'tokens', []);
      if (studentTokens.length > 0) {
        if (studentTokens.some((st: Token) => st.id === token.id)) {
          return false;
        }
      }
      const resultStudent = await firstValueFrom(this.studentController.studentControllerRead(student.id));
      const updatedStudent = get(resultStudent, 'student', {});
      const preparedStudent = this.prepareStudent(updatedStudent);
      this.updateDataSource(preparedStudent);
      return true;
    } catch (error) {
      return false;
    }
  }

  public editClientStudent(student: Student): void {
    const dialogRef = this._dialog.open(StudentCreateModalComponent, {
      data: {
        type: ManagerActions.UPDATE,
        student: student,
        clientId: this.clientId,
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result?.student) {
        this.isActionLoading[student.id] = true;
        this.updateDataSource(result.student);
        this.isActionLoading[student.id] = false;
      }
    });
  }

  public getThemeLabel(student: Student) {
    let theme = this.themes.find((t) => t.id === student.theme);

    if (!theme) {
      theme = this.themeService.getClassicTheme();
    }

    return theme.label.en_ca;
  }

  private async _archiveStudent(student: Student): Promise<void> {
    this.isLoading = true;
    try {
      const response = await firstValueFrom(this.studentController.studentControllerArchiveStudent(student.id));

      if (response) {
        this.students.data = this.students.data.filter((s: Student) => s.id !== student.id);
        this.isLoading = false;
      }
      // eslint-disable-next-line no-empty
    } catch {}
  }

  private _setupTutorial() {
    const { lastLogin } = this.user;
    const showTutorial: boolean = isEmpty(lastLogin);

    if (showTutorial) {
      this.tutorialService.setupTutorial(this.user);
    } else if (get(this.user, 'patron.acceptedTerms', false) === false) {
      this.acceptTerms();
    }
  }

  private async _loadStudents(): Promise<void> {
    try {
      const response = await firstValueFrom(this.studentController.studentControllerReadSelf());
      this.students = new MatTableDataSource(get(response, 'students', []));
      this.students.filterPredicate = (student: Student, filter: string): boolean => {
        return (
          (student.givenName && student.givenName.toLowerCase().includes(filter)) ||
          (student.familyName && student.familyName.toLowerCase().includes(filter)) ||
          (student.nickname && student.nickname.toLowerCase().includes(filter))
        );
      };
      this.originalStudents = this.students.data;
    } finally {
      this._pendingWork = false;
      this.isLoading = false;
    }
  }

  public applyFilter(filterValue: string) {
    this.students.filter = filterValue.trim().toLowerCase();
  }

  public async loadThemes() {
    this.themes = await this.themeService.getEnabledThemes();
  }

  public openAgenda(student) {
    if (!student.agenda) {
      return;
    }

    const dialog = this._dialog.open(CalendarDialogComponent, {
      data: {
        agenda: student.agenda,
        saveMode: SAVE_MODE.UPDATE,
      },
    });

    dialog.afterClosed().subscribe(async (shouldUpdate: boolean) => {
      if (shouldUpdate) {
        try {
          await firstValueFrom(this.studentController.studentControllerUpdate(student.id, { student }));

          this._snackBar.open('Student agenda has been saved', 'Close', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });

          await this.ngOnInit();
        } catch (error) {
          this._logger.error(error);
        }
      }
    });
  }

  public sortTable(field: string) {
    if (this.sortField === field) {
      this.sortDirection = this.sortDirection === Sort.ASC ? Sort.DESC : Sort.ASC;
    } else {
      this.sortField = field;
      this.sortDirection = Sort.ASC;
    }

    if (field === 'age') {
      this.students.data.sort((a, b) => {
        const ageA = this.getAgeFromBirthday(a);
        const ageB = this.getAgeFromBirthday(b);

        if (ageA < ageB) {
          return this.sortDirection === Sort.ASC ? -1 : 1;
        }
        if (ageA > ageB) {
          return this.sortDirection === Sort.ASC ? 1 : -1;
        }
        return 0;
      });
    }

    this.students.data.sort((a, b) => {
      if (a[this.sortField] < b[this.sortField]) {
        return this.sortDirection === Sort.ASC ? -1 : 1;
      }
      if (a[this.sortField] > b[this.sortField]) {
        return this.sortDirection === Sort.ASC ? 1 : -1;
      }
      return 0;
    });
  }

  getColumnDisplayName(column: string): string {
    switch (column) {
      case 'fullname':
        return 'name';
      case 'birthdate':
        return 'age';
      default:
        return column;
    }
  }

  getColumnValue(student: Student, column: string) {
    switch (column) {
      case 'age':
        return this.getAgeFromBirthday(student) ?? '--';
      case 'programs':
        if (student[column]?.length > 0) {
          return student[column][0]?.name;
        } else {
          return '--';
        }
      default:
        return typeof student[column] === 'string' ? student[column].toUpperCase() : student[column];
    }
  }

  public search(searchTerm: string) {
    if (searchTerm && !this.originalStudents) {
      return;
    }

    if (!this.originalStudents) {
      return;
    }
    const filteredStudents = this.handleStudentSearch(this.originalStudents, searchTerm);
    this.students.data = filteredStudents ?? this.originalStudents;
  }

  public handleStudentSearch(items: Student[], searchTerm: string): Student[] {
    if (!items) return [];
    if (!searchTerm) return items;

    const term = searchTerm.toLowerCase().trim();

    return items.filter((item: Student) => {
      return (
        item.fullname.toLocaleLowerCase().includes(term) || item.nickname.toLocaleLowerCase().includes(term) || false
      );
    });
  }

  public async handleAddStudent() {
    if (!this.clientId) {
      this._snackBar.open(
        'Unable to create the student at the moment. Please try again. If the problem persists, contact us!',
        'Close',
        {
          horizontalPosition: 'center',
          verticalPosition: 'top',
        },
      );
      return;
    }

    const dialogRef = this._dialog.open(StudentCreateModalComponent, {
      data: {
        type: ManagerActions.CREATE,
        clientId: this.clientId,
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result?.student) {
        this.isLoading = true;
        const preparedStudent = this.prepareStudent(result.student);
        this.updateDataSource(preparedStudent);
        this.isLoading = false;
      }
    });
  }

  public toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  public exportStudents() {
    this._studentsListService.exportStudents(this.students.data);
    this.toggleDropdown();
  }

  public prepareStudent(student: Student) {
    return {
      ...student,
      tokens: [],
      programs: [],
      progress: [],
    };
  }
}
