<div class="main">
  <div class="container">
    <div class="title">
      <h2>New Course</h2>
    </div>

    <div class="profile-photo-container">
      <div
        *ngIf="courseForm.image"
        class="profile-photo-preview"
      >
        <img
          [src]="courseForm.image"
          alt="Course banner preview"
          class="preview-image"
          (click)="addCourseImage()"
        />
        <div class="hover-content">
          <button (click)="addCourseImage()">
            <fa-icon [icon]="camera"></fa-icon>
            <span>Change banner course</span>
          </button>
        </div>
      </div>
      <div
        *ngIf="!courseForm.image"
        class="profile-photo-button"
      >
        <button (click)="addCourseImage()">
          <fa-icon [icon]="camera"></fa-icon>
        </button>
      </div>
    </div>

    <form
      #form="ngForm"
      novalidate
    >
      <div class="input-block">
        <label for="name">Course name<span>*</span></label>
        <input
          type="text"
          id="name"
          name="name"
          [(ngModel)]="courseForm.name"
          required
          #name="ngModel"
        />
        <div
          *ngIf="name.invalid && (name.dirty || name.touched)"
          class="invalid-feedback"
        >
          <div *ngIf="name.errors?.required">The course name is required.</div>
        </div>
      </div>

      <div class="input-block">
        <label for="title">Course title<span>*</span></label>
        <input
          type="text"
          id="title"
          name="title"
          [(ngModel)]="courseForm.title"
          required
          #title="ngModel"
          [ngClass]="{
            'is-invalid': title.invalid && (title.dirty || title.touched)
          }"
        />
        <div
          *ngIf="title.invalid && (title.dirty || title.touched)"
          class="invalid-feedback"
        >
          <div *ngIf="title.errors?.required">
            The course title is required.
          </div>
        </div>
      </div>

      <div class="input-block-select">
        <label for="language">Language<span>*</span></label>
        <select
          id="language"
          name="language"
          [(ngModel)]="courseForm.language"
          required
          #language="ngModel"
          [ngClass]="{
            'is-invalid':
              language.invalid && (language.dirty || language.touched)
          }"
        >
          <option
            value=""
            disabled
            selected
          >
            Choose the course language
          </option>
          <option
            *ngFor="let language of languages"
            [value]="language.languageCode"
          >
            {{ language.name }}
          </option>
        </select>
        <div
          *ngIf="language.invalid && (language.dirty || language.touched)"
          class="invalid-feedback"
        >
          <div *ngIf="language.errors?.required">
            Language selection is required
          </div>
        </div>
      </div>

      <div class="button-group">
        <button
          class="btn-secondary"
          (click)="closeDialog()"
        >
          Close
        </button>

        <button
          [disabled]="!form.valid"
          class="btn-primary"
          (click)="saveCourse()"
        >
          Save
        </button>
      </div>
    </form>
  </div>
</div>
