import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IconDefinition, faCamera, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { get } from 'lodash';

import { Client } from 'src/app/shared/interfaces';
import { DataManager, ManagerActions, ManagerTypes } from 'src/app/shared/interfaces/Manager.interface';
import { RoleService } from 'src/app/services/roles/role.service';
import { User } from 'src/app/shared/models/user.model';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { ClientsListService } from '../../menus/clients-menu/clients-list/clients-list.service';
import { Organization } from 'src/app/core/openapi';
import { CloudnaryService } from 'src/app/services/cloudnary/cloudnary.service';

@Component({
  selector: 'users-create-modal',
  templateUrl: './users-create-modal.component.html',
  styleUrls: ['./users-create-modal.component.scss'],
})
export class UsersCreateModalComponent implements OnInit {
  public readonly camera: IconDefinition = faCamera;
  public readonly spinnerIcon: IconDefinition = faSpinner;

  public orgAcc: User;
  public org: Organization;
  public stateSave: boolean = false;

  //Form and data properties
  clientForm: Client = {
    fullname: '',
    nickname: '',
    email: '',
    image: '',
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DataManager,
    private _roles: RoleService,
    private _rest: RestAPIService,
    protected _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<UsersCreateModalComponent>,
    private _clientsListService: ClientsListService,
    private cloudinary: CloudnaryService,
  ) {
    if (data?.client) {
      this.clientForm = {
        ...data.client,
        fullname: `${data?.client?.givenName} ${data?.client?.familyName}`,
      };
    }
  }

  async ngOnInit() {
    await this.whoAmI();
  }

  private async whoAmI() {
    this.orgAcc = this._roles.orgAcc;
    const response = await this._rest.get('organization/self', { msg: 'Could not get organization.' });
    if (response) {
      this.org = response?.organization;
    }
  }

  async save() {
    switch (this.data?.type) {
      case ManagerActions.CREATE:
        this.stateSave = true;
        await this.create();
        this.stateSave = false;
        break;

      case ManagerActions.UPDATE:
        this.stateSave = true;
        await this.update();
        this.stateSave = false;
        break;

      default:
        break;
    }
  }

  public async create() {
    try {
      const client = {
        ...this.clientForm,
        verifyType: ManagerTypes.client.toLowerCase(),
        type: ManagerTypes.client,
        org: this.org.name,
        subdomain: this.org.subdomain,
        orgEmail: this.orgAcc.email,
        lang: this.org.language,
      };

      const response = await this._rest.post('account', client);

      if (!response) {
        throw new Error('User creation failed');
      }
      this._clientsListService.notifyClientsUpdated();
      this.close();
      this.openSnackbar('User created successfully!');
    } catch (error) {
      this.openSnackbar(`User creation failed: ${error.message}`);
    }
  }

  public async update() {
    const clientId = get(this.data, 'client.id');
    const accountId = get(this.data, 'client.accountId');

    try {
      const [givenName, familyName = ''] = this.clientForm.fullname.split(' ');
      this.clientForm.givenName = givenName;
      this.clientForm.familyName = familyName;

      const response = await this._rest.put('patron/' + clientId, {
        patron: this.clientForm,
        accountId: accountId,
      });
      if (!response) {
        throw new Error('User updated failed');
      }
      this._clientsListService.notifyClientsUpdated();
      this.close();
      this.openSnackbar('User updated successfully!');
    } catch (error) {
      this.openSnackbar(`User update failed! ${error.message}`);
    }
  }

  public close() {
    this.dialogRef.close(true);
  }

  private openSnackbar(message) {
    this._snackBar.open(message, 'Close', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  public async addClientImage() {
    try {
      const image: string = await this.cloudinary.handleUpload();
      this.clientForm.image = image;
    } catch (error) {
      this.openSnackbar(error.message);
    }
  }
}
