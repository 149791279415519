import { CourseProgress } from 'src/app/pages/configuration-pages/interfaces/global-config.interfaces';
import { Client } from '../interfaces';
import { Organization } from 'src/app/core/openapi';
import { Student } from 'src/app/pages/students/interfaces/student.interface';

export class User {
  id?: string;
  createdAt: Date;
  emailVerified?: boolean;
  passwordUpdated: boolean;
  tokenId: string;
  lastLogin?: string;
  patron?: Patron;
  organization?: Organization;
  student?: Student;
  orgAcc?: Account;
  belongsTo?: Organization;
  stripeAccountId?: string;
  stripeId?: string;
  email?: string;
  role?: string;
  orgId?: string;
  courseProgresses?: CourseProgress[];
  firstTokenPurchased?: boolean;
  pendingRegistration?: boolean;
}

export interface Account {
  id: string;
  email?: string;
  subdomain: string;
  level: string;
  role: string;
  organization?: Organization;
  patron?: Patron;
  emailVerified?: boolean;
  lastLogin: string;
}

export interface Patron extends Client {
  familyName: string;
  fullName?: string;
  givenName: string;
  birthdate?: Date;
  nickname?: string;
  image?: string;
  company?: string;
  address1: string;
  address2?: string;
  city: string;
  province: string;
  country: string;
  postalCode: string;
  archived: boolean;
  phoneBusiness?: string;
  phoneMobile?: string;
  phonePersonal?: string;
  gender?: string;
  language?: string;
  orgId?: string;
  students: unknown[];
  id?: string;
  accountId?: string;
  organizationId?: string;
  name?: string;
  email?: string;
  acceptedTerms?: boolean;
}

export interface Address {
  country?: string;
  address?: string;
  province?: string;
  postalCode?: string;
}

export interface FullAccount {
  user?: User;
  account?: Access;
  orgAcc?: User;
}

export interface Access {
  level: string;
  role: string;
  subdomain: string;
}

export interface PublicProfile {
  name?: string;
  email?: string;
  phoneNumber?: string;
  website?: string;
  address?: string;
  postalCode?: string;
  province?: string;
  country?: string;
  logo?: string;
  description?: string;
  videoUrl?: string;
  languagesProvided?: any[];
}
