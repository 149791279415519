<ng-container *ngIf="template === 'table'">
  <users-table-header heading="Organizations"></users-table-header>

  <div class="search-and-button">
    <users-table-search-input
      [placeholder]="'Search organizations...' | textTransform"
      placeholder="Search organizations..."
      (userKeyUp)="applyFilter($event)"
    ></users-table-search-input>

    <div class="action-button">
      <button
        class="btn-primary main-action"
        (click)="createOrganization()"
      >
        Add New
      </button>
      <button
        class="btn-primary dropdown-toggle"
        (click)="toggleDropdown()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="dropdown-icon"
        >
          <path d="m6 9 6 6 6-6" />
        </svg>
      </button>

      <div
        class="dropdown-menu"
        [class.show]="isDropdownOpen"
      >
        <button
          class="dropdown-item"
          (click)="downloadFileExampleOrganizations()"
        >
          Download template
        </button>
        <div class="loading-container">
          <div class="file-import-container">
            <input
              type="file"
              id="fileInputOrganizations"
              (change)="importOrganizations($event)"
              style="display: none"
            />
            <button
              [ngClass]="{ hidden: isLoadingImport }"
              class="dropdown-item"
              (click)="triggerFileInput()"
            >
              Import {{ 'Organizations' | textTransform }}
            </button>
          </div>
          <div
            class="spinner-icon"
            [ngClass]="{ show: isLoadingImport }"
          ></div>
        </div>
        <div class="loading-container">
          <button
            [ngClass]="{ hidden: isLoadingExport }"
            class="dropdown-item"
            type="button"
            (click)="exportOrganizations()"
          >
            Export {{ 'Organizations' | textTransform }}
          </button>
          <div
            class="spinner-icon"
            [ngClass]="{ show: isLoadingExport }"
          ></div>
        </div>
      </div>
    </div>
  </div>

  <div class="table">
    <mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
        <mat-cell *matCellDef="let org">
          {{ org.name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
        <mat-cell
          *matCellDef="let org"
          class="px-0"
        >
          <users-table-status-item
            [status]="org.status"
          ></users-table-status-item>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
        <mat-cell *matCellDef="let org">
          {{ org.email }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="webpage">
        <mat-header-cell *matHeaderCellDef> Webpage </mat-header-cell>
        <mat-cell *matCellDef="let org">
          <users-table-website-item [website]="org.webpage">
          </users-table-website-item>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="credits">
        <mat-header-cell *matHeaderCellDef> Credits </mat-header-cell>
        <mat-cell
          *matCellDef="let org"
          class="d-flex justify-content-center"
        >
          <users-table-credits-badge-item
            *ngIf="org.credits !== undefined"
            [credits]="org.credits"
          ></users-table-credits-badge-item>

          <fa-icon
            *ngIf="org.credits === undefined"
            [icon]="spinner"
            [spin]="true"
          >
          </fa-icon>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
        <mat-cell *matCellDef="let org">
          <div class="icons">
            <users-table-action-icon
              [icon]="'plus'"
              [tooltip]="'Add credits'"
              (click)="addCredits(org)"
            ></users-table-action-icon>
            <users-table-action-icon
              [icon]="'minus'"
              [tooltip]="'Remove credits'"
              (click)="retrieveCredits(org)"
            ></users-table-action-icon>
            <users-table-action-icon
              [icon]="'edit'"
              [tooltip]="'Edit organization'"
              (click)="editOrganization(org)"
            ></users-table-action-icon>
            <users-table-action-icon
              [icon]="'redo'"
              [tooltip]="getVerifiedTooltip(org.lastLogin)"
              (click)="resendVerificationEmail(org)"
              [disabled]="org.lastLogin"
            ></users-table-action-icon>
            <users-table-action-icon
              *ngIf="!isSchoolSetup"
              [icon]="'shop'"
              [tooltip]="'Adjust custom price'"
              (click)="adjustCustomPrice(org)"
            ></users-table-action-icon>
            <users-table-action-icon
              [icon]="'trash'"
              [tooltip]="'Delete organization' | textTransform"
              (click)="deleteOrg(org)"
            ></users-table-action-icon>
          </div>
        </mat-cell>
      </ng-container>
      <mat-header-row
        *matHeaderRowDef="
          ['name', 'status', 'email', 'webpage', 'credits', 'actions'];
          sticky: true
        "
      ></mat-header-row>
      <mat-row
        *matRowDef="
          let row;
          columns: ['name', 'status', 'email', 'webpage', 'credits', 'actions']
        "
      ></mat-row>
    </mat-table>
    <ng-container *ngIf="isDataSourceEmpty()">
      <users-empty-state
        class="no-users"
        text="There are currently no organizations. You can add an organization by clicking on the Add New button in the top right corner."
        template="noData"
      ></users-empty-state>
    </ng-container>
  </div>
</ng-container>

<ng-container *ngIf="template === 'loading'"
  ><app-loading-indicator></app-loading-indicator
></ng-container>
