import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { LoggerService } from '../logger/logger.service';
import { RoleService } from '../roles/role.service';
import { Roles } from 'src/app/shared/consts/roles-const';
import { includes, get } from 'lodash';
import { UsersHelperService } from 'src/app/pages/users/users-helper.service';
import { User } from 'src/app/shared/models/user.model';
import { Organization } from 'src/app/core/openapi';

@Injectable()
export class AuthGuardService {
  constructor(
    private _auth: AuthService,
    private _router: Router,
    private _logger: LoggerService,
    private _roles: RoleService,
    private userHelper: UsersHelperService,
  ) {}

  public noTokenAllowedPaths = ['programs-pricing', 'purchase-tokens/:bundleId', 'register', 'school-board-signup'];
  public schoolStudentAllowedPath = ['program/:studentId/:programName', 'register'];
  public pendingRegisterAllowedPaths = ['organization-update'];

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const user = await this._auth.getUser();

    if (!this._auth.user) {
      this._router.navigate(['']);
      return false;
    }

    const tokenExpiration = parseInt(localStorage.getItem('expire_at'));

    if (tokenExpiration < Date.now()) {
      this._auth.logout();
      return false;
    }

    this._roles.setupAccount(this._auth.access, this._auth.user, this._auth.orgAcc);
    const organization = get(this._auth.getOrgAcc(), 'organization');

    if (this.pendingRegistration(user) && !includes(this.pendingRegisterAllowedPaths, route.routeConfig.path)) {
      // if the organization didnt completed the registration, redirect the user to sign up completion page

      this._router.navigate(['signup-completion']);
    } else if (this.disabledStripeAccount(organization) || this._roles.isOrgClient()) {
      // if the b2c provider have the stripe warning disabled we act as the b2c did the first purchase, since the purchase will be disabled for this user
      // org clients cant purchase tokens, so if the user is a client we act as the first purchase is completed

      this._auth.user.firstTokenPurchased = true;
    } else if (!this._auth.user.firstTokenPurchased) {
      // Check if the first purchase is completed

      const isFirstPurchaseDone = await this.userHelper.confirmFirstPurchase();

      this._auth.user.firstTokenPurchased = isFirstPurchaseDone;
    } else if (this._roles.isSchoolStudent()) {
      // if the user is a student, check the route and ensure that the user will only have access to the program page
      const student = user.student;

      if (!includes(this.schoolStudentAllowedPath, route.routeConfig.path)) {
        this._router.navigate(['program/' + student.id + '/Neuralign/']);
      }
    }

    if (this._auth.user.firstTokenPurchased === false && !includes(this.noTokenAllowedPaths, route.routeConfig.path)) {
      const url = get(organization, 'isSchool', false) ? 'school-board-signup' : 'programs-pricing';
      this._router.navigate([url]);
    }

    if (route.data.roles) {
      this._logger.info(
        'roles allowed',
        route.data.roles,
        'current roles',
        this._roles.access.role,
        'and should you be here?',
        route.data.roles.indexOf(this._roles.access.role) >= 0,
      );

      if (route.data.roles.indexOf(this._roles.access.role) === -1) {
        if (route.data.redirect) {
          this._router.navigate([route.data.redirect]);
        } else {
          this._router.navigate(['']);
        }
      }
    }

    if (!this._auth.isAuthenticated()) {
      this._router.navigate(['']);
      return false;
    }
    return true;
  }

  public pendingRegistration(user: User) {
    return user.organization && user.pendingRegistration;
  }

  public disabledStripeAccount(organization: Organization) {
    return organization && get(organization, 'disableStripeWarning') === true && this._auth.access.level === Roles.B2C;
  }
}
