<div class="main">
  <div class="container">
    <div class="title">
      <h2 *ngIf="data.type == 'create'">
        {{ 'Create Organization' | textTransform }}
      </h2>
      <h2 *ngIf="data.type == 'update'">
        {{ 'Update Organization' | textTransform }}
      </h2>
    </div>

    <div class="profile-photo-container">
      <div
        *ngIf="organizationForm.logo"
        class="profile-photo-preview"
      >
        <img
          [src]="
            organizationForm.logo || './assets/img/StudentImagePlaceholder.png'
          "
          alt="Student photo preview"
          class="preview-image"
          (click)="addOrganizationImage()"
        />
        <div class="hover-content">
          <button (click)="addOrganizationImage()">
            <fa-icon [icon]="camera"></fa-icon>
            <span>Change your logo</span>
          </button>
        </div>
      </div>
      <div
        *ngIf="!organizationForm.logo"
        class="profile-photo-button"
      >
        <button (click)="addOrganizationImage()">
          <fa-icon [icon]="camera"></fa-icon>
        </button>
      </div>
    </div>

    <form
      #form="ngForm"
      novalidate
    >
      <div class="container-split">
        <div class="container-left-column">
          <div class="input-block">
            <label for="organizationForm.name"
              >{{ 'Organization Name' | textTransform }}<span>*</span></label
            >
            <input
              type="text"
              name="organizationForm.name"
              [(ngModel)]="organizationForm.name"
              (blur)="fillSubdomain()"
              required
              #name="ngModel"
              [ngClass]="{
                'is-invalid': name.invalid && (name.dirty || name.touched)
              }"
            />
            <div
              *ngIf="name.invalid && (name.dirty || name.touched)"
              class="invalid-feedback"
            >
              <div *ngIf="name.errors?.required">The Name is required.</div>
            </div>
          </div>

          <div
            *ngIf="data.type !== 'update'"
            class="input-block"
          >
            <label for="organizationForm.email">Email <span>*</span></label>
            <input
              type="email"
              name="organizationForm.email"
              [(ngModel)]="organizationForm.email"
              required
              #email="ngModel"
              email
              [ngClass]="{
                'is-invalid': email.invalid && (email.dirty || email.touched)
              }"
            />
            <div
              *ngIf="email.invalid && (email.dirty || email.touched)"
              class="invalid-feedback"
            >
              <div *ngIf="email.errors?.required">The email is required.</div>
              <div *ngIf="email.errors?.email">The Email is invalid.</div>
            </div>
          </div>

          <div class="input-block">
            <label for="organizationForm.phoneNumber"
              >Phone Number <span>*</span></label
            >
            <input
              type="text"
              name="organizationForm.phoneNumber"
              [(ngModel)]="organizationForm.phoneNumber"
              required
              #phone="ngModel"
              [ngClass]="{
                'is-invalid': phone.invalid && (phone.dirty || phone.touched)
              }"
            />
            <div
              *ngIf="phone.invalid && (phone.dirty || phone.touched)"
              class="invalid-feedback"
            >
              <div *ngIf="phone.errors?.required">The Phone is required.</div>
            </div>
          </div>

          <div class="input-group">
            <div class="input-block">
              <label for="organizationForm.position"
                >{{ 'Profession' | textTransform }}<span>*</span></label
              >
              <input
                type="text"
                name="organizationForm.position"
                [(ngModel)]="organizationForm.position"
                required
                #position="ngModel"
                [ngClass]="{
                  'is-invalid':
                    position.invalid && (position.dirty || position.touched)
                }"
              />
              <div
                *ngIf="position.invalid && (position.dirty || position.touched)"
                class="invalid-feedback"
              >
                <div *ngIf="position.errors?.required">
                  {{ 'Profession' | textTransform }} is required.
                </div>
              </div>
            </div>

            <div class="input-block">
              <label for="organizationForm.professionalAssociation"
                >{{
                  'Professional Association'
                    | textTransform
                      : (isSchool ? PortalConfig.SCHOOL : PortalConfig.DEFAULT)
                }}
                <span *ngIf="isSchool">*</span>
              </label>
              <input
                type="text"
                name="organizationForm.professionalAssociation"
                [(ngModel)]="organizationForm.professionalAssociation"
                #professionalAssociation="ngModel"
                [required]="isSchool"
              />
              <div
                *ngIf="
                  isSchool &&
                  professionalAssociation.invalid &&
                  (professionalAssociation.dirty ||
                    professionalAssociation.touched)
                "
                class="invalid-feedback"
              >
                <div
                  *ngIf="isSchool && professionalAssociation.errors?.required"
                >
                  {{
                    'Professional Association'
                      | textTransform
                        : (isSchool
                            ? PortalConfig.SCHOOL
                            : PortalConfig.DEFAULT)
                  }}
                  is required
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-right-column">
          <div class="input-group">
            <div class="input-block">
              <label for="organizationForm.address"
                >Address (number & state)<span>*</span></label
              >
              <input
                type="input"
                name="address"
                [(ngModel)]="organizationForm.address.address"
                ngx-gp-autocomplete
                (onAddressChange)="handleAddressChange($event)"
                placeholder="Enter your address"
                required
                #address="ngModel"
                [ngClass]="{
                  'is-invalid':
                    address.invalid && (address.dirty || address.touched)
                }"
              />
              <div
                *ngIf="address.invalid && (address.dirty || address.touched)"
                class="invalid-feedback"
              >
                <div *ngIf="address.errors?.required">
                  The Address is required.
                </div>
              </div>
            </div>

            <div class="input-block">
              <label for="organizationForm.postalCode"
                >Postal/Zip Code<span>*</span></label
              >
              <input
                type="text"
                name="organizationForm.postalCode"
                [(ngModel)]="organizationForm.address.postalCode"
                required
                #postalCode="ngModel"
                [ngClass]="{
                  'is-invalid':
                    postalCode.invalid &&
                    (postalCode.dirty || postalCode.touched)
                }"
              />
              <div
                *ngIf="
                  postalCode.invalid && (postalCode.dirty || postalCode.touched)
                "
                class="invalid-feedback"
              >
                <div *ngIf="postalCode.errors?.required">
                  The Postal Code is required.
                </div>
              </div>
            </div>
          </div>

          <div class="input-group">
            <div class="input-block">
              <label for="organizationForm.country"
                >Country<span>*</span></label
              >
              <input
                disabled
                type="text"
                name="organizationForm.country"
                [(ngModel)]="organizationForm.address.country"
              />
            </div>

            <div class="input-block">
              <label for="organizationForm.province"
                >Province/State<span>*</span></label
              >
              <input
                disabled
                type="text"
                name="organizationForm.province"
                [(ngModel)]="organizationForm.address.province"
              />
            </div>
          </div>

          <div class="input-block">
            <label for="organizationForm.referredBy">Referred by</label>
            <input
              type="text"
              name="organizationForm.referredBy"
              [(ngModel)]="organizationForm.referredBy"
            />
          </div>
        </div>
      </div>
      <div class="subdomain-container">
        <div class="subdomain-label-container">
          <label for="subdomain">Subdomain</label>
        </div>
        <div class="subdomain-input-container">
          <div class="subdomain-prefix">https://</div>
          <input
            type="text"
            id="subdomain"
            name="organizationForm.subdomain"
            [(ngModel)]="organizationForm.subdomain"
            required
          />
          <div class="subdomain-suffix">.ls.works</div>
        </div>
      </div>

      <div class="button-group">
        <button
          *ngIf="!stateSave"
          class="btn-secondary"
          (click)="close()"
        >
          Close
        </button>
        <button
          [disabled]="!form.valid"
          *ngIf="!stateSave"
          class="btn-primary"
          (click)="save()"
        >
          Save
        </button>
        <div
          *ngIf="stateSave"
          class="spinner-container"
        >
          <div class="d-flex justify-content-center align-items-center">
            <fa-icon
              class="mx-2"
              [icon]="spinnerIcon"
              [spin]="true"
            >
            </fa-icon>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
