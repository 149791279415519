import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import {
  Categories,
  DashBoardData,
  ExerciseData,
  Log,
  OrganizationControllerService,
  Progress,
  SchoolStudent,
  StudentControllerService,
} from 'src/app/core/openapi';

import { EXCLUDED_EXERCISES_TYPE, GameTag } from '../program/consts/program-game-consts';
import { get, trim, includes } from 'lodash';
import { GlobalConfigurationHelper } from 'src/app/services/utils/global-configuration-helper';
import { MatDialog } from '@angular/material/dialog';
import { FullSessionResultComponent } from 'src/app/shared/dialogs/full-session-result/full-session-result';
import { uniqBy, orderBy, startCase } from 'lodash';
import { Chart } from 'chart.js';
import { faXmark, faSpinner, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { MatSnackBar } from '@angular/material/snack-bar';
import { firstValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import moment from 'moment';

enum ProgressTypes {
  CT = 'cognitive therapy progress',
  RE = 'reading exercises progress',
}

@Component({
  selector: 'app-school-dashboard',
  templateUrl: './school-dashboard.component.html',
  styleUrl: './school-dashboard.component.scss',
})
export class SchoolDashboardComponent implements OnInit {
  @ViewChild(MatTable) table: MatTable<DashBoardData>;
  public dataSource: MatTableDataSource<DashBoardData> = new MatTableDataSource([]);
  public loading = true;
  public categoryArray: Categories[] = [];
  public logs: Log[] = [];
  public canvas;
  public ctx: HTMLCanvasElement;
  public tableLoading = false;
  public isSchoolBoard = false;

  public selectedGrade: number;
  public studentData: DashBoardData[] = [];

  public schoolFilter: string = '';
  public fullnameFilter: string = '';
  public gradeFilter: number;

  public weekDays = 7;

  public activeChart: Chart | null = null;

  public readonly clear: IconDefinition = faXmark;
  public readonly spinner: IconDefinition = faSpinner;

  public options = [GameTag.CognitiveTherapy, GameTag.ReadingExercises, GameTag.SpeedReading];

  public selectedOption = GameTag.CognitiveTherapy;
  public studentsInProgress = 0;

  constructor(
    private globalConfigHelper: GlobalConfigurationHelper,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private organizationController: OrganizationControllerService,
    private studentController: StudentControllerService,
    private auth: AuthService,
  ) {}

  async ngOnInit() {
    await this._loadDashboardData();
    this.categoryArray = await this.globalConfigHelper.getCategories();
    await this.getStudents(false, GameTag.CognitiveTherapy);
  }

  public async reloadTable() {
    this.tableLoading = true;
    await this.getStudents(false, this.selectedOption);
    this.studentsInProgress = this.getStudentsInProgress();
    this.tableLoading = false;
  }

  public getOptionName(name: string) {
    return startCase(name);
  }

  public async getStudents(refresh: boolean = false, tag: string) {
    await this.auth.getUser(refresh);
    const user = this.auth.getOrgAcc();
    const organization = user.organization;
    const data = await firstValueFrom(this.studentController.studentControllerGetDashboardData(organization.id, tag));

    this.studentData = data;
    this.dataSource.data = data;
    this.dataSource.filterPredicate = this.createFilter();

    if (this.table) {
      this.table.renderRows();
    }
    this.studentsInProgress = this.getStudentsInProgress();
    this.loading = false;
    await this.loadGraph();
  }

  public clearGradeFilter() {
    if (!this.gradeFilter) {
      return;
    }
    this.gradeFilter = undefined;
    this.applyFilter();
  }

  public getGrades() {
    const gradeArray = uniqBy(get(this.dataSource, 'data', []).map((s: SchoolStudent) => s.grade));
    return orderBy(gradeArray);
  }

  public getStudentProgress(student: SchoolStudent) {
    const studentProgress = get(student, 'progressList', []);
    const finishedProgress = studentProgress.filter((p: Progress) => !get(p, 'metadata.savedGame', false));
    return finishedProgress;
  }

  public checkScoreChange(score: string): number {
    return Number(trim(score).replace('+', ''));
  }

  public openFullResultChart(student: SchoolStudent) {
    const progress = this.getStudentProgress(student);
    const scoresArray = this.getFullResultsArray(progress);
    scoresArray.sort((a, b) => a.exerciseScores.session - b.exerciseScores.session);

    this.dialog.open(FullSessionResultComponent, {
      width: '1100px',
      maxHeight: '650px',
      data: {
        scoresArray,
        progress,
      },
      panelClass: 'modal-border',
    });
  }

  public getFullResultsArray(progress: Progress[]) {
    const fullProgressChart = progress
      .filter((p) => p.tag.includes(this.selectedOption))
      .map((p) => {
        const filteredExercises = this.getFilteredExercises(p.metadata.exercises);
        const colums = filteredExercises.map(
          (e, index) =>
            this.getExerciseName(e.name, p.tag) +
            (this.exerciseHasDuplicate(e.name, filteredExercises) === true ? index : ''),
        );
        const completeColums = ['session', 'level', ...colums, 'total'];

        const exerciseScores = filteredExercises
          .map((e, index) => {
            const name =
              this.getExerciseName(e.name, p.tag) +
              (this.exerciseHasDuplicate(e.name, filteredExercises) === true ? index : '');
            return {
              [name]: e.score,
              session: p.session,
              total: p.metadata.exercises.reduce((a, b) => a + b.score, 0),
              level: e.level,
            };
          })
          .reduce((a, b) => Object.assign(a, b));

        return {
          colums: completeColums,
          exerciseScores,
        };
      });

    return fullProgressChart;
  }

  public getFilteredExercises(exercises: ExerciseData[]) {
    return exercises.filter((exercise) => includes(EXCLUDED_EXERCISES_TYPE, exercise.name) === false && exercise.name);
  }

  public getExerciseName(name: string, tag: string): string {
    const categories = this.categoryArray;
    const category = categories.find((c) => c.name.toLowerCase() === tag);

    if (!category) {
      return name;
    }

    const gameList = category.games;
    const game = gameList.find((g) => name === g.type);

    return game ? game.type : name;
  }

  public exerciseHasDuplicate(name: string, filteredExercises: ExerciseData[]): boolean {
    const exercise = filteredExercises.filter((e) => e.name === name) || [];

    return exercise.length > 1;
  }

  public getStudentsInProgress() {
    const studentsInProgress = this.studentData.filter((l) =>
      l.progressList.some((p) => p.tag.toLowerCase().includes(this.selectedOption.toLowerCase())),
    );

    if (studentsInProgress.length === 0) {
      return 0;
    }

    return studentsInProgress.length;
  }

  public processLogs(logs: Log[], tag: string, days: number) {
    const data = [];
    if (days > 0) {
      for (let i = 0; i < days; i += 1) {
        const day = moment().subtract(i, 'days');
        const lDays = logs.filter((l) => {
          const lDate = new Date(l.date);
          return lDate.getDate() === day.date() && lDate.getMonth() === day.month();
        });

        data.push(
          lDays.filter((l) => {
            return l.tag.includes(this.selectedOption);
          }).length,
        );
      }
    } else {
      const users = logs.map((l) => l.accountId);
      const students = logs.map((l) => l.studentId);
      data.push(
        logs.filter((l, index) => {
          return (
            (users.indexOf(l.accountId) === index || students.indexOf(l.studentId) === index) &&
            l.tag.includes(this.selectedOption)
          );
        }).length,
      );
    }
    data.reverse();
    const count = data.reduce((prev, d) => prev + d);
    return {
      data,
      count,
    };
  }

  public getActiveUsers = () => {
    const latestLogs = this.logs.filter(
      (l) => this.isRecentLog(l.date, l.tag) && l.tag === this.selectedOption + ' progress',
    );

    return uniqBy(latestLogs, 'studentId').length;
  };

  private isRecentLog(time, tag) {
    const givenDate = moment(time);
    const now = moment();
    const differenceInHours = Math.abs(givenDate.diff(now, 'hours'));
    const isAtLeastOneHour: boolean = differenceInHours <= 1;

    return isAtLeastOneHour;
  }

  public async _loadDashboardData(): Promise<void> {
    try {
      const logs = await firstValueFrom(this.organizationController.organizationControllerReadLogs());

      if (!logs) {
        throw Error('Could not load the dashboard logs, please refresh the page');
      }

      const orgAccount = this.auth.getOrgAcc();
      this.isSchoolBoard = get(orgAccount, 'organization.isReseller', false);
      this.logs = logs;
    } catch (error) {
      this.snackbar.open(error.message, 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    }
  }

  public async loadGraph() {
    const prog = this.processLogs(this.logs, this.selectedOption + ' progress', this.weekDays).data;

    await this._generateGraph(prog);
  }

  public waitForElement(selector: string) {
    return new Promise((resolve, reject) => {
      if (document.querySelector(selector)) {
        return resolve(document.querySelector(selector));
      }

      const observer = new MutationObserver((mutations) => {
        if (document.querySelector(selector)) {
          observer.disconnect();
          resolve(document.querySelector(selector));
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });

      setTimeout(() => {
        observer.disconnect();
        reject();
      }, 5000);
    });
  }

  createFilter() {
    return (data: SchoolStudent, filter: string): boolean => {
      const matchesSchool = this.schoolFilter
        ? data.school.toLowerCase().includes(this.schoolFilter.toLowerCase())
        : true;

      const matchesFullname = this.fullnameFilter
        ? data.fullname.toLowerCase().includes(this.fullnameFilter.toLowerCase())
        : true;

      const matchesGrade = this.gradeFilter ? data.grade?.toString().includes(this.gradeFilter?.toString()) : true;

      return matchesSchool && matchesFullname && matchesGrade;
    };
  }

  applyFilter() {
    this.dataSource.filter = `${this.schoolFilter}${this.fullnameFilter}${this.gradeFilter}`;
  }

  public getChartConfig(data: number[]) {
    const labels = [];
    for (let i = 0; i < data.length; i += 1) {
      const day = new Date(Date.now() - 1000 * 60 * 60 * 24 * i);
      labels.push(day.getMonth() + 1 + '/' + day.getDate() + '/' + day.getFullYear());
    }
    labels.reverse();

    const config = {
      type: 'line',
      data: {
        labels,
        datasets: [
          {
            data,
            borderColor: '#3a3372',
            borderWidth: 2,
            lineTension: 0,
          },
        ],
      },
      options: {
        responsive: false,
        legend: { display: false },
        scales: {
          xAxes: [
            {
              gridLines: { display: false },
              ticks: {
                fontColor: '#3A3372',
                fontSize: 10,
              },
            },
          ],
          yAxes: [
            {
              gridLines: { display: true },
              ticks: {
                min: 0,
                max: Math.max(...data) + 10,
                stepSize: 5,
                fontColor: '#3A3372',
                fontSize: 10,
              },
            },
          ],
        },
      },
    };

    return config;
  }

  public async _generateGraph(data: number[]) {
    if (this.activeChart) {
      this.activeChart.data.datasets.forEach((dataset) => {
        dataset.data = data;
      });
      this.activeChart.update();
      return;
    }
    const canvas = await this.waitForElement('#DashboardResults');
    this.canvas = canvas;
    this.ctx = this.canvas.getContext('2d');
    this.activeChart = new Chart(this.ctx, this.getChartConfig(data));
  }
}
