import { NgModule } from '@angular/core';
import { RouterModule, Routes, mapToCanActivate } from '@angular/router';

import { FAQComponent } from './pages/faq/faq.component';
import { HelpComponent } from './pages/help/help.component';
import { HomeComponent } from './pages/home/home.component';
import { DemoComponent } from './pages/demo/demo.component';
import { NotFoundComponent } from './pages/404/404.component';
import { UsersComponent } from './pages/users/users.component';
import { UpdatesComponent } from './pages/updates/updates.component';
import { ContactComponent } from './pages/contact/contact.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { ProgramComponent } from './pages/program/program.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { RegisterComponent } from './pages/register/register.component';
import { StudentsComponent } from './pages/students/students.component';
import { ProgramsComponent } from './pages/programs/programs.component';
import { PurchaseComponent } from './pages/purchase/purchase.component';
import { GameComponent } from './shared/components/game/game.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DocumentsComponent } from './pages/documents/documents.component';
import { TutorialsComponent } from './pages/tutorials/tutorials.component';
import { BiographyComponent } from './pages/biography/biography.component';
import { TakeATourComponent } from './pages/take-a-tour/take-a-tour.component';
import { TermsOfServiceComponent } from './pages/terms-of-service/terms.component';
import { AdminPricingComponent } from './pages/admin-pricing/admin-pricing.component';
import { TutorialsOrgComponent } from './pages/tutorials-org/tutorials-org.component';
import { PublicProfileComponent } from './pages/public-profile/public-profile.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { AuthCallbackComponent } from './shared/components/call-back/call-back.component';
import { ManageUpdateComponent } from './pages/updates/manage-update/manage-update.component';
import { ProgramsPricingComponent } from './pages/programs-pricing/programs-pricing.component';
import { ManageUserComponent } from './pages/users/components/manage-user/manage-user.component';
import { AdminDashboardComponent } from './pages/admin/admin-dashboard/admin-dashboard.component';
import { StudentProfileComponent } from './pages/students/student-profile/student-profile.component';
import { StudentResultsComponent } from './pages/students/student-results/student-results.component';
import { UploadGameFilesComponent } from './pages/admin/upload-game-files/upload-game-files.component';
import { StudentProgramsComponent } from './pages/students/student-programs/student-programs.component';
import { SubscriptionControlCenterComponent } from './pages/subscription-control-center/subscription-control-center.component';
import { RegionConfigurationsComponent } from './pages/configuration-pages/region-configurations/region-configurations.component';
import { CourseConfigurationsComponent } from './pages/configuration-pages/course-configurations/course-configurations.component';
import { GlobalConfigurationsComponent } from './pages/configuration-pages/global-configurations/global-configurations.component';
import { ContentConfigurationsComponent } from './pages/configuration-pages/content-configurations/content-configurations.component';

import { AuthGuardService } from './services/auth/auth.guard';
import { PortalTypeGuard } from './services/portal/portal-type.guard';
import { CallbackGuard } from './services/auth/auth-callback/callback.guard';
import { CourseOverviewPageComponent } from './pages/courses/pages/course-overview/course-overview.component';
import { CoursesListPageComponent } from './pages/courses/pages/courses-list/courses-list.component';
import { LearningSectionPageComponent } from './pages/courses/pages/learning-section/learning-section.component';
import { InstallmentPaymentsControlCenter } from './pages/installment-payments-control-center/installment-payments-control-center';
import { PasswordUpdatedGuard } from './services/auth/password-update/password-update.guard';
import { PublicMessageBannerComponent } from './pages/public-message-banner/public-message-banner.component';
import { OrganizationSignupComplete } from './pages/org-signup-completion/org-signup-completion.component';
import { SignupCompletionGuard } from './pages/org-signup-completion/signup-completion-guard/signup-completion-guard';
import { Roles } from './shared/consts/roles-const';
import { MessagesDashboardComponent } from './pages/messages/messages-dashboard.component';
import { ClassWalletDashboardComponent } from './pages/region-dashboard/classwallet/cw-dashboard.component';
import { UsaGuardService } from './services/region/USA/usa.guard';
import { CouponComponent } from './pages/users/components/coupons/coupon.component';
import { SchoolDashboardComponent } from './pages/school-dashboard/school-dashboard.component';
import { SchoolBoardSignupComponent } from './pages/school-board-signup/school-board-signup.component';
import { SchoolBoardGuard } from './shared/guards/school-board/school-board.guard';
import { PurchaseGuard } from './shared/guards/purchase/purchase.guard';

const appRoutes: Routes = [
  { path: '', component: HomeComponent, canActivate: mapToCanActivate([PortalTypeGuard]) },
  { path: 'take-a-tour', component: TakeATourComponent },
  { path: 'pricing', component: PricingComponent },
  { path: 'register', component: RegisterComponent, canActivate: mapToCanActivate([AuthGuardService]) },
  { path: 'faq', component: FAQComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'terms-of-service', component: TermsOfServiceComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'biography', component: BiographyComponent },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: mapToCanActivate([AuthGuardService, PasswordUpdatedGuard]),
    data: {
      roles: ['Portal Owner', 'Owner', 'Organization Admin', 'Organization Manager'],
    },
  },
  {
    path: 'authCallback',
    component: AuthCallbackComponent,
    canActivate: mapToCanActivate([CallbackGuard]),
  },
  {
    path: 'signup-completion',
    component: OrganizationSignupComplete,
    canActivate: mapToCanActivate([SignupCompletionGuard]),
  },
  {
    path: 'programs',
    component: ProgramsComponent,
    canActivate: mapToCanActivate([AuthGuardService, PasswordUpdatedGuard]),
  },

  // Messages --
  {
    path: 'admin/messages',
    component: MessagesDashboardComponent,
    canActivate: [AuthGuardService, PasswordUpdatedGuard],
    data: { roles: [Roles.PortalOwner] },
  },

  // Courses --
  {
    path: 'installment-payments-control-center',
    component: InstallmentPaymentsControlCenter,
    canActivate: mapToCanActivate([AuthGuardService]),
  },
  {
    path: 'courses',
    component: CoursesListPageComponent,
    canActivate: mapToCanActivate([AuthGuardService]),
  },
  {
    path: 'courses/overview/:id',
    component: CourseOverviewPageComponent,
    canActivate: mapToCanActivate([AuthGuardService]),
  },
  {
    path: 'courses/learning/:id',
    component: LearningSectionPageComponent,
    canActivate: mapToCanActivate([AuthGuardService, PasswordUpdatedGuard]),
  },
  {
    path: 'students',
    component: StudentsComponent,
    canActivate: mapToCanActivate([AuthGuardService, PasswordUpdatedGuard]),
  },
  {
    path: 'users',
    component: UsersComponent,
    canActivate: mapToCanActivate([AuthGuardService, PasswordUpdatedGuard]),
    data: { roles: ['Portal Owner', 'Owner', 'Organization Admin', 'Organization Manager'], redirect: '/students' },
  },
  {
    path: 'users/manage/:userId',
    component: ManageUserComponent,
    canActivate: mapToCanActivate([AuthGuardService, PasswordUpdatedGuard]),
    data: { roles: ['Portal Owner', 'Owner', 'Organization Admin', 'Organization Manager'], redirect: '/students' },
  },
  {
    path: 'users/coupon',
    component: CouponComponent,
    canActivate: [AuthGuardService, PasswordUpdatedGuard],
    data: { roles: [Roles.PortalOwner] },
  },
  {
    path: 'admin/dashboard',
    component: AdminDashboardComponent,
    canActivate: mapToCanActivate([AuthGuardService, PasswordUpdatedGuard]),
    data: { roles: ['Portal Owner'] },
  },
  {
    path: 'school/dashboard',
    component: SchoolDashboardComponent,
    canActivate: mapToCanActivate([AuthGuardService, PasswordUpdatedGuard]),
  },
  {
    path: 'global-configurations',
    component: GlobalConfigurationsComponent,
    canActivate: mapToCanActivate([AuthGuardService, PasswordUpdatedGuard]),
    data: { roles: ['Portal Owner'] },
  },
  {
    path: 'content-configurations',
    component: ContentConfigurationsComponent,
    canActivate: mapToCanActivate([AuthGuardService, PasswordUpdatedGuard]),
    data: { roles: ['Portal Owner'] },
  },
  {
    path: 'region-configurations',
    component: RegionConfigurationsComponent,
    canActivate: mapToCanActivate([AuthGuardService, PasswordUpdatedGuard]),
    data: { roles: ['Portal Owner'] },
  },
  {
    path: 'courses-configurations',
    component: CourseConfigurationsComponent,
    canActivate: mapToCanActivate([AuthGuardService, PasswordUpdatedGuard]),
    data: { roles: ['Portal Owner'] },
  },
  {
    path: 'subscription-control-center',
    component: SubscriptionControlCenterComponent,
    canActivate: mapToCanActivate([AuthGuardService, PasswordUpdatedGuard]),
  },
  {
    path: 'public-profile',
    component: PublicProfileComponent,
    canActivate: mapToCanActivate([AuthGuardService, PasswordUpdatedGuard]),
  },
  {
    path: 'admin/updates',
    component: UpdatesComponent,
    canActivate: mapToCanActivate([AuthGuardService, PasswordUpdatedGuard]),
  },
  {
    path: 'admin/updates/:id',
    component: ManageUpdateComponent,
    canActivate: mapToCanActivate([AuthGuardService, PasswordUpdatedGuard]),
    data: { roles: ['Portal Owner'] },
  },
  {
    path: 'students/profile/:client',
    component: StudentProfileComponent,
    canActivate: mapToCanActivate([AuthGuardService, PasswordUpdatedGuard]),
  },
  {
    path: 'students/profile',
    component: StudentProfileComponent,
    canActivate: mapToCanActivate([AuthGuardService, PasswordUpdatedGuard]),
  },
  {
    path: 'students/programs/:name/:studentId',
    component: StudentProgramsComponent,
    canActivate: mapToCanActivate([AuthGuardService, PasswordUpdatedGuard]),
    data: { roles: ['Portal Owner', 'Organization Admin', 'Organization Manager', 'Owner'], redirect: '/students' },
  },
  {
    path: 'students/:programName/progress/:name/:studentId',
    component: StudentResultsComponent,
    canActivate: mapToCanActivate([AuthGuardService, PasswordUpdatedGuard]),
  },
  {
    path: 'purchase-tokens/:bundleId',
    component: PurchaseComponent,
    canActivate: mapToCanActivate([AuthGuardService, PasswordUpdatedGuard, PurchaseGuard]),
  },
  {
    path: 'program/:studentId/:programName',
    component: ProgramComponent,
    canActivate: mapToCanActivate([AuthGuardService, PasswordUpdatedGuard]),
  },
  {
    path: 'programs-pricing',
    component: ProgramsPricingComponent,
    canActivate: mapToCanActivate([AuthGuardService, PasswordUpdatedGuard, PurchaseGuard]),
    data: { roles: ['Portal Owner', 'Owner', 'OrgOwner', 'Organization Admin', 'Client'], redirect: '/' },
  },
  {
    path: 'school-board-signup',
    component: SchoolBoardSignupComponent,
    canActivate: mapToCanActivate([AuthGuardService, SchoolBoardGuard]),
    data: { roles: ['Portal Owner', 'Owner', 'OrgOwner'], redirect: '/' },
  },
  {
    path: 'admin-pricing',
    component: AdminPricingComponent,
    canActivate: mapToCanActivate([AuthGuardService, PasswordUpdatedGuard]),
  },
  {
    path: 'classwallet-dashboard',
    component: ClassWalletDashboardComponent,
    canActivate: mapToCanActivate([AuthGuardService, PasswordUpdatedGuard, UsaGuardService]),
  },
  {
    // TODO: Remove this, testing purposes
    path: 'uploadFiles',
    component: UploadGameFilesComponent,
  },
  {
    path: 'tutorials',
    component: TutorialsComponent,
    canActivate: mapToCanActivate([AuthGuardService, PasswordUpdatedGuard]),
  },
  {
    path: 'documents',
    component: DocumentsComponent,
    canActivate: mapToCanActivate([AuthGuardService, PasswordUpdatedGuard]),
  },
  {
    path: 'demo',
    component: DemoComponent,
  },
  {
    path: 'tutorials-org',
    component: TutorialsOrgComponent,
    canActivate: mapToCanActivate([AuthGuardService, PasswordUpdatedGuard]),
  },
  {
    path: 'help',
    component: HelpComponent,
    canActivate: mapToCanActivate([AuthGuardService, PasswordUpdatedGuard]),
  },
  {
    path: 'game',
    component: GameComponent,
    canActivate: mapToCanActivate([AuthGuardService, PasswordUpdatedGuard]),
  },
  {
    path: 'public-message-banner',
    component: PublicMessageBannerComponent,
  },
  {
    path: '404',
    component: NotFoundComponent,
  },
  { path: '**', redirectTo: '404' },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
