import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LoadingService } from 'src/app/services/utils/loading.service';
import { get } from 'lodash';
import { OrganizationControllerService } from 'src/app/core/openapi';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-school-board-signup',
  templateUrl: './school-board-signup.component.html',
  styleUrl: './school-board-signup.component.scss',
})
export class SchoolBoardSignupComponent {
  constructor(
    private _auth: AuthService,
    private _snackBar: MatSnackBar,
    private loading: LoadingService,
    private organizarionService: OrganizationControllerService,
  ) {}

  public async contactAdministrator() {
    try {
      this.loading.ActivateLoading();
      await this._auth.getUser(true);

      const account = this._auth.getOrgAcc();
      const org = get(account, 'organization');

      if (!org) {
        throw Error('Org not found');
      }

      if (org.schoolBoardRequest) {
        this._snackBar.open(`We already received your request and we will contact you soon`, 'Close', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });

        this.loading.DisableLoading();
        return;
      }

      await firstValueFrom(this.organizarionService.organizationControllerAskForSchoolBoardActivation(org.id));

      this._snackBar.open(
        `The notification has been sent to our administrator and your school board will be activated soon`,
        'Close',
        {
          horizontalPosition: 'center',
          verticalPosition: 'top',
        },
      );

      this.loading.DisableLoading();
    } catch (error) {
      this.loading.DisableLoading();
      this._snackBar.open(`Could not send the notification, please try again!`, 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    }
  }
}
