import { Component, Inject, OnInit } from '@angular/core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  Account,
  Organization,
  OrganizationControllerService,
  SchoolBoard,
  SchoolBoardControllerService,
} from 'src/app/core/openapi';
import { firstValueFrom } from 'rxjs';
import { sortBy, get, isEmpty, trim } from 'lodash';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface DialogData {
  schoolBoard: SchoolBoard;
  orgList: Organization[];
}

@Component({
  selector: 'app-school-board-creation',
  templateUrl: './school-board-creation-dialog.html',
  styleUrls: ['./school-board-creation-dialog.scss'],
})
export class SchoolBoardCreationDialog implements OnInit {
  public readonly loadingIcon: IconDefinition = faSpinner;
  public loading: boolean = true;
  public orgList: Organization[] = [];

  public schoolBoard: SchoolBoard = {
    name: '',
    orgId: '',
    studentDomains: [],
    teacherDomains: [],
  };

  public studentDomainText = '';
  public teacherDomainText = '';

  public loadInProgress = false;

  constructor(
    private organizationService: OrganizationControllerService,
    private schoolBoardService: SchoolBoardControllerService,
    protected _snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<SchoolBoardCreationDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}

  async ngOnInit() {
    if (this.data.schoolBoard) {
      const schoolBoard = this.data.schoolBoard;
      this.schoolBoard.name = schoolBoard.name;
      this.schoolBoard.orgId = schoolBoard.orgId;
      this.schoolBoard.teacherDomains = schoolBoard.teacherDomains;
      this.schoolBoard.studentDomains = schoolBoard.studentDomains;
      this.schoolBoard.id = schoolBoard.id;

      this.studentDomainText = schoolBoard.studentDomains.join(',');
      this.teacherDomainText = schoolBoard.teacherDomains.join(',');
    }

    await this.getOrganizations();
    this.loading = false;
  }

  public close() {
    this.dialogRef.close();
  }

  public async getOrganizations() {
    if (this.data.orgList) {
      this.orgList = this.data.orgList;
    } else {
      const users = await firstValueFrom(this.organizationService.organizationControllerGetAllOrganizations());

      this.orgList = sortBy(users, (u: Account) => get(u, 'organization.name'))
        .map((acc: Account) => get(acc, 'organization'))
        .filter((u: Organization) => !isEmpty(get(u, 'name')));

      this.loading = false;
    }
  }

  public async handleConfirm() {
    if (this.data.schoolBoard) {
      await this.editSchoolBoard();
    } else {
      await this.createSchoolBoard();
    }
  }

  public async editSchoolBoard() {
    try {
      this.schoolBoard.studentDomains = this.studentDomainText.split(',').map((d) => trim(d));
      this.schoolBoard.teacherDomains = this.teacherDomainText.split(',').map((d) => trim(d));
      this.loadInProgress = true;

      const response = await firstValueFrom(
        this.schoolBoardService.schoolBoardControllerEditSchoolBoard(this.schoolBoard),
      );

      if (!response) {
        throw new Error('Could not create the school board');
      }

      this._snackBar.open('School board created!', 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    } catch (error) {
      this.loadInProgress = false;
      this._snackBar.open(error.message, 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    }
  }

  public async createSchoolBoard() {
    try {
      this.schoolBoard.studentDomains = this.studentDomainText.split(',').map((d) => trim(d));
      this.schoolBoard.teacherDomains = this.teacherDomainText.split(',').map((d) => trim(d));
      this.loadInProgress = true;

      const newBoard = await firstValueFrom(
        this.schoolBoardService.schoolBoardControllerCreateSchoolBoardService(this.schoolBoard),
      );

      if (!newBoard) {
        throw new Error('Could not create the school board');
      }

      this._snackBar.open('School board created!', 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });

      this.dialogRef.close(true);
      this.loadInProgress = false;
    } catch (error) {
      this.loadInProgress = false;
      this._snackBar.open(error.message, 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    }
  }
}
