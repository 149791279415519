<app-main-layout
  [breadcrumbs]="[
    { name: 'Home', url: '/' },
    { name: 'School signup', url: '/school-board-signup' }
  ]"
>
  <div class="container mt-5">
    <div class="header my-3">
      Join the growing list of School Boards that offer Neuralign
    </div>

    <div class="my-5">
      <div class="my-2 body">- Comprehensive online teacher certification</div>

      <div class="my-2 body">
        - 2 complimentary credits to familiarize yourself with the program
      </div>

      <div class="my-2 body">- Assign schools their own portals</div>

      <div class="my-2 body">- Track student progress</div>

      <div class="my-2 body">
        - Unique branded URL with your school board’s name
      </div>

      <div class="my-2 body">
        - Customize your Neuralign portal with your logo.
      </div>
    </div>

    <div class="button my-3">
      <app-simple-button
        (click)="contactAdministrator()"
        [big]="true"
        [text]="'Contact Us to Sign Up'"
      >
      </app-simple-button>
    </div>

    <div class="footer mt-5">
      <span> Each credit provides access to Neuralign for one student </span>
      <br />
      <span>
        All providers must complete the Neuralign provider certification
      </span>
    </div>
  </div>
</app-main-layout>
