<div
  class="mainContainer"
  *ngIf="!loading"
>
  <div
    class="header col-12 px-0 d-flex justify-content-center align-items-center"
  >
    <app-simple-button
      (click)="openCreationDialog()"
      class="mx-auto"
      [text]="'Create School Board'"
    >
    </app-simple-button>
  </div>
</div>

<div
  *ngIf="loading"
  class="loading h-100 w-100 d-flex justify-content-center align-items-center"
>
  <fa-icon
    [icon]="loadingIcon"
    [spin]="true"
  ></fa-icon>
</div>

<div
  class="emptyState"
  *ngIf="!loading && schoolBoards.length === 0"
>
  You dont have dont school boards
</div>

<table
  mat-table
  [dataSource]="schoolBoards"
  *ngIf="!loading && schoolBoards.length > 0"
>
  <ng-container matColumnDef="name">
    <th
      mat-header-cell
      [ngClass]="'name-col'"
      *matHeaderCellDef
    >
      Name
    </th>
    <td
      mat-cell
      class="pointer"
      *matCellDef="let row"
    >
      {{ row.name }}
    </td>
  </ng-container>

  <ng-container matColumnDef="orgId">
    <th
      mat-header-cell
      *matHeaderCellDef
      class="orgId-col"
    >
      Org Id
    </th>
    <td
      mat-cell
      *matCellDef="let row"
      class="orgId-col"
    >
      {{ row.orgId }}
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th
      mat-header-cell
      *matHeaderCellDef
    >
      Actions
    </th>

    <td
      mat-cell
      class="text-center d-flex mt-2"
      *matCellDef="let row"
    >
      <a
        class="mx-1"
        matTooltip="Delete School Board"
        matTooltipPosition="above"
        (click)="deleteSchoolBoard(row.id)"
      >
        <fa-icon [icon]="trash"></fa-icon>
      </a>

      <a
        (click)="editSchoolBoard(row)"
        class="mx-1"
        matTooltip="Edit School Board"
        matTooltipPosition="above"
      >
        <fa-icon [icon]="edit"></fa-icon>
      </a>
    </td>
  </ng-container>

  <tr
    mat-header-row
    *matHeaderRowDef="schoolBoardColumns"
  ></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: schoolBoardColumns"
  ></tr>
</table>
