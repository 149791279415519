import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { GameTag } from 'src/app/pages/program/consts/program-game-consts';
import { Progress } from '../../models';
import { get } from 'lodash';
import { ExerciseData } from 'src/app/core/openapi';
import { READING_COMP } from '../../components/game/game.constants';
import { ReadingCompReportDialog } from 'src/app/pages/program/reports/readingcomp-report/readingcomp-report.component';
import { faEye, IconDefinition } from '@fortawesome/free-solid-svg-icons';

interface Scores {
  [key: string]: number;
}

interface FullSessionData {
  colums: string[];
  scoresArray: Scores[];
  progress: Progress[];
}

@Component({
  selector: 'full-session-result-dialog',
  templateUrl: './full-session-result.html',
  styleUrls: ['./full-session-result.scss'],
})
export class FullSessionResultComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<FullSessionResultComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: FullSessionData,
  ) {}

  public datasource: MatTableDataSource<Scores>;
  public readonly file: IconDefinition = faEye;

  ngOnInit(): void {
    this.datasource = new MatTableDataSource(this.data.scoresArray);
  }

  public getColumValue(value: string) {
    return value != undefined ? value.toString().toUpperCase() : '---';
  }

  public removeExerciseIndex(exercise: string) {
    return exercise.replace(/\d/g, '');
  }

  public createDataSource() {
    return new MatTableDataSource(['1']);
  }

  public isReadingComp(column) {
    return column === 'readingcomp';
  }

  public getRecords(index, tag: GameTag, selectedExercise: string) {
    const progressList = this.data.progress.filter((p) => p.tag === tag);
    const exerciseList: ExerciseData[] = get(progressList[index], 'metadata.exercises', []);
    const exercise = exerciseList.find((e) => e.name == selectedExercise);
    return get(exercise, 'records', []);
  }

  public progressHaveRecords(index) {
    const records = this.getRecords(index, GameTag.ReadingExercises, READING_COMP);

    return records.length > 0;
  }

  public openReadingCompReport(index) {
    const records = this.getRecords(index, GameTag.ReadingExercises, READING_COMP);

    if (records.length > 0) {
      this.dialog.open(ReadingCompReportDialog, {
        width: '1100px',
        minHeight: '500px',
        maxHeight: '650px',
        autoFocus: false,
        hasBackdrop: true,
        data: records,
        panelClass: 'modal-border',
      });
    }
  }
}
