import { Component, OnInit, Inject } from '@angular/core';
import { IconDefinition, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Progress } from 'src/app/shared/models';
import { MatTableDataSource } from '@angular/material/table';
import { readLsLevels } from 'src/app/pages/configuration-pages/content-configurations/components/themes/consts/theme.constants';
import { ConfirmationService } from 'src/app/services/confirmation/confirmation.service';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GameTag } from 'src/app/pages/program/consts/program-game-consts';

@Component({
  selector: 'app-progress-edit-dialog',
  templateUrl: './progress-edit-dialog.html',
  styleUrls: ['./progress-edit-dialog.scss'],
})
export class ProgressEditDialog implements OnInit {
  public readonly spinnerIcon: IconDefinition = faSpinner;
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['game', 'level', 'score'];

  public studentLevels = readLsLevels;

  constructor(
    public dialogRef: MatDialogRef<ProgressEditDialog>,
    private confirm: ConfirmationService,
    private _rest: RestAPIService,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: Progress,
  ) {}

  async ngOnInit() {
    if (this.isAssessmentProgress()) {
      this.dataSource = new MatTableDataSource([this.data.metadata]);
    } else {
      this.dataSource = new MatTableDataSource(this.data.metadata.exercises || this.data.metadata.exerciseList);
    }
  }

  public isAssessmentProgress() {
    return this.data.tag === GameTag.Assessment;
  }

  public returnStudentLevel(level: number) {
    const studentLevel = this.studentLevels.find((l) => l.assessmentLevel === level);

    if (!studentLevel) {
      return;
    }

    return studentLevel.level;
  }

  public save(progress: Progress) {
    this.confirm
      .createConfirmation(
        'Warning',
        'Are you sure you want to edit this progress? This action cannot be undone',
        'Yes',
        'No',
      )
      .then(async () => {
        this._rest.put('/progress/' + progress.id, { msg: 'Could not delete this language', progress }).then(() => {
          this._snackBar.open(`Progress updated`, 'Close', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });

          this.dialogRef.close(true);
        }),
          (err) => {
            // Send error to clarity
            (window as any)?.clarity?.('event', 'progress_edit_error');
            this._snackBar.open(`${err}`, 'Close', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });
          };
      });
  }

  public close() {
    this.dialogRef.close();
  }
}
