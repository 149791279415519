import { Roles } from 'src/app/shared/consts/roles-const';
import { Document } from './document.model';
import { Languages } from './documents-languages.const';

export const documentList: Document[] = [
  {
    title: 'Content Folder',
    thumbnail: './assets/img/NeurAlign main logo.png',
    url: 'https://drive.google.com/drive/folders/1XFdI7Oc_uGgD6ZsKt3iVJLCtRu7N6NTS',
    lang: [Languages.English, Languages.French, Languages.Arabic],
    roles: [Roles.Organization],
  },
  {
    title: 'Practice Books and Documents',
    thumbnail: './assets/img/NeurAlign main logo.png',
    url: 'https://drive.google.com/drive/folders/1I2Y29zpFMr9z0IrPP89BRn6hRzAoImks',
    lang: [Languages.English, Languages.French, Languages.Arabic],
    roles: [Roles.B2C],
  },
];
