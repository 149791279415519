<ng-container *ngIf="template === 'table'">
  <users-table-header heading="Managers"></users-table-header>

  <div class="search-and-button">
    <users-table-search-input
      [placeholder]="'Search managers...' | textTransform"
      (userKeyUp)="applyFilter($event)"
    ></users-table-search-input>
    <div class="action-button">
      <button
        class="btn-primary main-action"
        (click)="createManager()"
      >
        Add New
      </button>
      <button
        class="btn-primary dropdown-toggle"
        (click)="toggleDropdown()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="dropdown-icon"
        >
          <path d="m6 9 6 6 6-6" />
        </svg>
      </button>

      <div
        class="dropdown-menu"
        [class.show]="isDropdownOpen"
      >
        <button
          class="dropdown-item"
          (click)="downloadFileExampleManagers()"
        >
          Download template
        </button>
        <div class="loading-container">
          <div class="file-import-container">
            <input
              type="file"
              id="fileInputManager"
              (change)="importManagers($event)"
              style="display: none"
            />
            <button
              class="dropdown-item"
              (click)="triggerFileInput()"
              [ngClass]="{ hidden: isLoadingImport }"
            >
              Import {{ 'Managers' | textTransform }}
            </button>
            <div
              class="spinner-icon"
              [ngClass]="{ show: isLoadingImport }"
            ></div>
          </div>
        </div>
        <div class="loading-container">
          <button
            class="dropdown-item"
            (click)="exportManagers()"
            [ngClass]="{ hidden: isLoadingExport }"
          >
            Export {{ 'Managers' | textTransform }}
          </button>
          <div
            class="spinner-icon"
            [ngClass]="{ show: isLoadingExport }"
          ></div>
        </div>
      </div>
    </div>
  </div>

  <div class="table">
    <mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
        <mat-cell *matCellDef="let manager">
          {{ manager.name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
        <mat-cell
          *matCellDef="let manager"
          class="px-0"
        >
          <users-table-status-item
            [status]="manager.status"
          ></users-table-status-item>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
        <mat-cell *matCellDef="let manager">
          <div class="icons">
            <users-table-action-icon
              [icon]="'edit'"
              [tooltip]="'Edit manager'"
              (click)="editManager(manager)"
            ></users-table-action-icon>
            <users-table-action-icon
              [icon]="'redo'"
              [tooltip]="getVerifiedTooltip(manager.lastLogin)"
              [disabled]="manager.lastLogin"
              (click)="resendEmailVerification(manager)"
            ></users-table-action-icon>
            <users-table-action-icon
              [icon]="'trash'"
              [tooltip]="'Delete manager'"
              (click)="deleteManager(manager)"
            ></users-table-action-icon>
          </div>
        </mat-cell>
      </ng-container>
      <mat-header-row
        *matHeaderRowDef="['name', 'status', 'actions']; sticky: true"
      ></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: ['name', 'status', 'actions']"
      ></mat-row>
    </mat-table>
  </div>
  <ng-container *ngIf="isDataSourceEmpty()">
    <users-empty-state
      class="no-users"
      text="This organization currently has no managers. You can add a manager by clicking on the Add New button in the top right corner."
      template="noData"
    ></users-empty-state>
  </ng-container>
</ng-container>

<ng-container *ngIf="template === 'loading'"
  ><app-loading-indicator></app-loading-indicator
></ng-container>
