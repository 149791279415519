// PUBLIC --

import {
  Course,
  CourseLesson,
  CourseProgress,
  Section,
} from 'src/app/pages/configuration-pages/interfaces/global-config.interfaces';
import { LessonType } from '../../utils/LessonType.type';
import { LessonContentFEM, CourseFEM, SectionFEM, LessonFEM } from './courses.service.models';
import { uniq } from 'lodash';

const DEFAULT_LANG = 'en_ca';
/**
 * @purpose Transform course data into a shape that is easy for the front-end to work with this adds a layer of protection from changes to the API causing cascading changes to the front-end
 */
export function transformCourseData(courses: Course[], progresses: CourseProgress[]): CourseFEM[] {
  return courses.map((course: Course) => {
    const courseProgress = progresses.find((progress) => progress.courseId === course.id);

    return {
      id: course.id,
      title: course.title,
      description: course.name,
      hasCourseStarted: courseProgress !== undefined, // Course progress could be empty
      percentOfCourseComplete: calculateCourseCompletionPercent(course, progresses),
      isCourseComplete: calculateCourseCompletionPercent(course, progresses) === 100 ? true : false,
      numberOfLessons: countNumberOfLessons(course),
      image: course.image,
      numberOfSections: countNumberOfSections(course),
      hasCertificate: course.hasCertificate,
      language: course.language ?? DEFAULT_LANG,
      lessons: course.lessons.map((lesson, index): LessonFEM => {
        return {
          id: lesson._id,
          index: index,
          title: lesson.title,
          isCompleted: isLessonComplete(lesson, courseProgress),
          sequence: index + 1,
          isDraft: lesson.draft,
          numberOfLessons: countNumberOfLessons(course),
          isFirstInCourse: isLessonFirstInCourse(index),
          isLastInCourse: isLessonLastInCourse(course, index),
          sections: lesson.sections.map((section): SectionFEM => {
            return {
              id: section._id,
              title: section.title,
              isCompleted: isSectionCompleted(section, courseProgress),
              type: section.type as LessonType,
              isDraft: section.draft,
              content: section.content as LessonContentFEM,
              isFirstInLesson: isSectionFirstInLesson(section, lesson),
              isLastInLesson: isSectionLastInLesson(section, lesson),
              sequence: getSectionSequence(section, lesson),
              index: getSectionIndex(course, section),
              isSelected: false,
              belongsTo: lesson._id,
            };
          }),
        };
      }),
    } as CourseFEM;
  });
}

export enum AllowedUsers {
  organizations = 'organizations',
  b2c = 'b2c',
  clients = 'clients',
}
export function getUserType(isOrganization, isB2c): AllowedUsers {
  if (isOrganization) {
    return AllowedUsers.organizations;
  } else if (isB2c) {
    return AllowedUsers.b2c;
  } else {
    return AllowedUsers.clients;
  }
}

// PRIVATE --

function isLessonFirstInCourse(index): boolean {
  return index === 0;
}

function isLessonLastInCourse(course, index): boolean {
  return course.lessons.length - 1 === index;
}

function isSectionFirstInLesson(section, lesson): boolean {
  const sectionId = section._id;
  const sectionList = lesson.sections.map((l) => l._id).flat();

  const check = sectionList.indexOf(sectionId);

  if (check === 0) {
    return true;
  } else {
    return false;
  }
}

function isSectionLastInLesson(section, lesson): boolean {
  const sectionId = section._id;
  const sectionList = lesson.sections.map((l) => l._id).flat();

  const check = sectionList.indexOf(sectionId);

  if (check === sectionList.length - 1) {
    return true;
  } else {
    return false;
  }
}

function isLessonComplete(lesson: CourseLesson, courseProgress: CourseProgress): boolean {
  if (!courseProgress) {
    return false;
  }

  const selectedLesson = courseProgress.lessons.find((l) => l.lessonId === lesson._id);

  return selectedLesson ? selectedLesson.completed : false;
}

function isSectionCompleted(section: Section, courseProgress: CourseProgress): boolean {
  if (!courseProgress) {
    return false;
  }

  const lesson = courseProgress.lessons.find((l) => l.sections.map((s) => s.sectionId).includes(section._id));

  if (!lesson) {
    return false;
  }

  const selectedSection = lesson.sections.find((s) => s.sectionId === section._id);

  return selectedSection.completed;
}

function getSectionSequence(section, lesson): number {
  const sectionId = section._id;
  const sections = lesson.sections.map((l) => l._id).flat();

  return sections.indexOf(sectionId) + 1;
}

function getSectionIndex(course, section): number {
  const sectionList = course.lessons
    .map((l) => l.sections)
    .flat()
    .map((l) => l._id);

  const index = sectionList.indexOf(section._id);

  return index;
}

function countNumberOfSections(course: Course): number {
  let count = 0;

  course.lessons.forEach((lesson) => {
    lesson.sections.forEach(() => {
      count++;
    });
  });

  return count;
}

function countNumberOfLessons(course: Course): number {
  return course.lessons.length;
}

function calculateCourseCompletionPercent(course: Course, progresses: CourseProgress[]): number {
  const courseProgress = progresses.find((p) => p.courseId === course.id);

  if (!courseProgress) {
    return 0;
  }

  const allCourseSectionsIds = uniq(
    course.lessons
      .map((l) => l.sections)
      .flat()
      .map((l) => l._id),
  );

  const completedSectionProgress = uniq(
    courseProgress.lessons
      .map((l) => l.sections)
      .flat()
      .filter((s) => s.completed),
  );

  return parseInt(((completedSectionProgress.length / allCourseSectionsIds.length) * 100).toFixed(0));
}
