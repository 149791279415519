import { Component, Input } from '@angular/core';
import { SelectBundleService } from '../../services/select-bundle.service';
import { Router } from '@angular/router';
import { PricingBundle } from 'src/app/pages/pricing/interfaces/pricing-bundle.interface';

@Component({
  selector: 'card-buy-btn',
  templateUrl: './card-buy-btn.component.html',
  styleUrls: ['./card-buy-btn.component.scss'],
})
export class CardBuyBtnComponent {
  @Input() bundle: PricingBundle;

  constructor(private _bundleService: SelectBundleService, private _router: Router) {}

  public addToCart() {
    this._bundleService.selectedBundle = this.bundle;
    this._router.navigate(['purchase-tokens/' + this.bundle.id], { queryParams: { ...this.bundle } });
  }
}
