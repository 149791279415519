import { EventEmitter, Injectable } from '@angular/core';
import { Token } from 'src/app/shared/models';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  private userTokens: Token[] = [];

  public refreshCredits = new EventEmitter<void>();

  constructor() {}

  public setTokens(tokens: Token[]) {
    this.userTokens = tokens;
  }

  public getTokens() {
    if (!this.userTokens) {
      return [];
    }

    return this.userTokens;
  }

  public getAvaliableTokens() {
    if (!this.userTokens) {
      return [];
    }

    const avaliableTokens = this.userTokens.filter((t) => !t.studentId);

    return avaliableTokens;
  }
}
