export const oldThemeLabel = 'Classic Neuralign';

export const classicThemeLabel = 'classic';

export enum LockTypes {
  session = 'session',
  interval = 'interval',
  maxSessions = 'maxSessions',
  failedPayment = 'failedPayment',
  completeAtHome = 'completeAtHome',
  noToken = 'noToken',
}
