<app-main-layout
  [breadcrumbs]="[
    { name: 'Home', url: '/' },
    { name: 'Global configs', url: '/global-configurations' }
  ]"
>
  <div
    *ngIf="loading"
    class="loading"
  >
    <mat-spinner></mat-spinner>
  </div>

  <div class="mainContainer border">
    <div class="header col-8 mx-auto px-0 d-flex">
      <div
        class="py-2 col-2 text-center"
        (click)="selectedOption = 'warningMessage'"
        [ngClass]="{ selectedOption: selectedOption === 'warningMessage' }"
      >
        <fa-icon [icon]="warningMessage"></fa-icon> <br />
        <span>Warning message</span>
      </div>

      <div
        class="py-2 col-2 text-center"
        (click)="selectedOption = 'exports'"
        [ngClass]="{ selectedOption: selectedOption === 'exports' }"
      >
        <fa-icon [icon]="exports"></fa-icon> <br />
        <span>Exports</span>
      </div>

      <div
        class="py-2 col-2 text-center"
        (click)="selectedOption = 'schoolBoards'"
        [ngClass]="{ selectedOption: selectedOption === 'schoolBoards' }"
      >
        <fa-icon [icon]="school"></fa-icon> <br />
        <span>School Boards</span>
      </div>
    </div>

    <div
      class="col-8 mx-auto px-0 mt-5"
      *ngIf="selectedOption === 'warningMessage'"
    >
      <app-warning-message
        [globalConfigurations]="globalConfigurations"
      ></app-warning-message>
    </div>

    <div
      class="col-9 mx-auto px-0 mt-5"
      *ngIf="selectedOption === 'exports'"
    >
      <app-exports (setLoading)="setLoading($event)"></app-exports>
    </div>

    <div
      class="col-9 mx-auto px-0 mt-5"
      *ngIf="selectedOption === 'schoolBoards'"
    >
      <app-school-board></app-school-board>
    </div>
  </div>
</app-main-layout>
