import { Component, OnInit } from '@angular/core';
import { documentList } from './documents-list.const';
import { Languages } from './documents-languages.const';
import { toArray } from 'lodash';
import { RoleService } from 'src/app/services/roles/role.service';
import { Document } from './document.model';
import { get } from 'lodash';

interface EventDocuments {
  value: Languages;
}

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent implements OnInit {
  public languageList = toArray(Languages);

  public documentList = documentList;

  public filteredDocuments: Document[] = [];

  public languageSelected = Languages.English;

  constructor(private _roles: RoleService) {}

  async ngOnInit() {
    this.selectLanguage({ value: Languages.English });
  }

  public goTo(url: string) {
    window.open(url, '_blank');
  }

  public selectLanguage(e: EventDocuments) {
    const roles = get(this._roles, 'roles', []);
    this.filteredDocuments = this.documentList.filter(
      (d) => d.lang.includes(e.value) && d.roles.some((role) => roles.includes(role)),
    );
  }
}
