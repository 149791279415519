<ng-container *ngIf="template === 'table'">
  <users-table-header heading="B2C"></users-table-header>

  <div class="search-and-sort">
    <users-table-search-input
      placeholder="Search B2C clients..."
      (userKeyUp)="applyFilter($event)"
    ></users-table-search-input>

    <app-simple-button
      class="btn-sort"
      [text]="sortOrderLabel"
      (click)="reverseB2CListOrder()"
      [smaller]="true"
    ></app-simple-button>
  </div>

  <div class="table">
    <mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
        <mat-cell
          *matCellDef="let b2c"
          (click)="getStudents(b2c.id)"
          class="clickable"
          [matTooltip]="'Click for B2C students'"
        >
          {{ b2c.name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
        <mat-cell *matCellDef="let b2c">
          {{ b2c.email }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="credits">
        <mat-header-cell *matHeaderCellDef> Credits </mat-header-cell>
        <mat-cell *matCellDef="let b2c">
          <users-table-credits-badge-item
            [credits]="b2c.credits"
          ></users-table-credits-badge-item>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
        <mat-cell *matCellDef="let b2c">
          <div class="icons">
            <users-table-action-icon
              [icon]="'plus'"
              [tooltip]="'Add credits'"
              (click)="addCredits(b2c)"
            ></users-table-action-icon>
            <users-table-action-icon
              [icon]="'trash'"
              [tooltip]="'Delete client'"
              (click)="deleteB2C(b2c)"
            ></users-table-action-icon>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="['name', 'email', 'credits', 'actions']; sticky: true"
      ></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: ['name', 'email', 'credits', 'actions']"
      ></mat-row>
    </mat-table>

    <ng-container *ngIf="isDataSourceEmpty()">
      <users-empty-state
        class="no-users"
        text="There are currently no B2C providers."
        template="noData"
      ></users-empty-state>
    </ng-container>
  </div>
</ng-container>

<ng-container *ngIf="template === 'loading'"
  ><app-loading-indicator></app-loading-indicator
></ng-container>
