import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { RestAPIService } from '../../services/rest/rest-api.service';
import { MaterialComponentsModule } from 'src/app/shared/modules/material-components.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { LayoutComponentsModule } from 'src/app/shared/components/layout/layout-components.module';
import { SafeUrlModule } from 'src/app/shared/pipes/safe-url/safe-url.module';
import { LayoutsModule } from 'src/app/shared/layouts/layouts.module';
import { SchoolBoardSignupComponent } from './school-board-signup.component';

@NgModule({
  declarations: [SchoolBoardSignupComponent],
  imports: [
    BrowserModule,
    RouterModule,
    HttpClientModule,
    MaterialComponentsModule,
    FontAwesomeModule,
    MatTooltipModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatCardModule,
    ReactiveFormsModule,
    LayoutComponentsModule,
    SafeUrlModule,
    LayoutsModule,
  ],
  providers: [RestAPIService],
})
export class SchoolSignupModule {}
