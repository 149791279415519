<app-main-layout
  [breadcrumbs]="[
    { name: 'Home', url: '/' },
    { name: 'Students', url: '/students' }
  ]"
>
  <div class="header-container">
    <span>My Students</span>
    <div class="search-and-add">
      <input
        type="text"
        placeholder="Search student..."
        [(ngModel)]="searchTerm"
        (input)="search(searchTerm)"
      />
      <div
        *ngIf="isB2CPortal"
        class="action-button"
      >
        <button
          class="btn-primary main-action"
          (click)="handleAddStudent()"
        >
          Add New
        </button>
        <button
          class="btn-primary dropdown-toggle"
          (click)="toggleDropdown()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="dropdown-icon"
          >
            <path d="m6 9 6 6 6-6" />
          </svg>
        </button>

        <div
          class="dropdown-menu"
          [class.show]="isDropdownOpen"
        >
          <!-- TODO: IMPROVE IMPORT WITHOUT EMAIL OR NOT SCHOOL -->
          <!-- <button
            class="dropdown-item"
            (click)="downloadStudentFile()"
          >
            Download template
          </button> -->
          <!-- <div class="file-import-container">
            <input
              type="file"
              id="fileInputStudents"
              (change)="importStudents($event)"
              style="display: none"
            />
            <button
              class="dropdown-item"
              (click)="triggerFileInput()"
            >
              Import Students
            </button>
          </div> -->
          <button
            class="dropdown-item"
            (click)="exportStudents()"
            [disabled]="isLoading"
          >
            Export Students
          </button>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="!isLoading">
    <div
      *ngIf="students?.data?.length > 0"
      class="table_responsive"
    >
      <span> Total of {{ students.data.length }} students</span>
      <table>
        <thead>
          <tr>
            <th
              *ngFor="let column of columns"
              (click)="sortTable(column)"
            >
              {{ getColumnDisplayName(column) | uppercase }}
              <span *ngIf="sortField === column && sortDirection === 'asc'">
                &#9650;
              </span>
              <span *ngIf="sortField === column && sortDirection === 'desc'">
                &#9660;
              </span>
            </th>
            <th>ACTIONS</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let student of students.data">
            <td *ngFor="let column of columns">
              <ng-container *ngIf="column === 'programs'">
                <div class="ReadLSTag">
                  {{ getColumnValue(student, column) }}
                </div>
              </ng-container>
              <ng-container *ngIf="column !== 'programs'">
                {{ getColumnValue(student, column) }}
              </ng-container>
            </td>
            <td class="action-col">
              <ng-container *ngIf="isActionLoading[student.id]">
                <a
                  class="action-spinner-icon"
                  [ngClass]="{ show: isActionLoading[student.id] }"
                >
                </a>
              </ng-container>
              <ng-container *ngIf="!isActionLoading[student.id]">
                <a
                  *ngIf="
                    (!student.programs || student.programs.length === 0) &&
                    !isOrgClient()
                  "
                  (click)="associateStudent(student)"
                  matTooltip="Add program"
                  matTooltipPosition="above"
                >
                  <fa-icon [icon]="plusIcon"></fa-icon>
                </a>

                <a
                  *ngIf="
                    !isOrgClient() && canDisassociateStudent(student);
                    else disabledRemoveProgram
                  "
                  (click)="disassociateStudent(student)"
                  matTooltip="Remove program"
                  matTooltipPosition="above"
                >
                  <fa-icon [icon]="minusIcon"></fa-icon>
                </a>
                <a
                  (click)="editClientStudent(student)"
                  *ngIf="!isOrgClient()"
                  matTooltip="Edit student"
                  matTooltipPosition="above"
                >
                  <fa-icon [icon]="editIcon"></fa-icon>
                </a>
                <a
                  class="mx-1"
                  matTooltip="Student's calendar"
                  matTooltipPosition="above"
                >
                  <fa-icon
                    [ngClass]="{ disabledIcon: !student.agenda }"
                    [icon]="calendarIcon"
                    (click)="openAgenda(student)"
                  ></fa-icon>
                </a>
                <a
                  *ngIf="
                    student.programs && student.programs.length > 0;
                    else disabledProgram
                  "
                  [routerLink]="'/program/' + student.id + '/Neuralign'"
                  matTooltip="Program Page"
                  matTooltipPosition="above"
                >
                  <fa-icon [icon]="gamepadIcon"></fa-icon>
                </a>
                <ng-template #disabledProgram>
                  <a
                    matTooltip="Access Neuralign"
                    matTooltipPosition="above"
                  >
                    <fa-icon
                      [icon]="gamepadIcon"
                      class="disabledIcon"
                    ></fa-icon>
                  </a>
                </ng-template>
                <a
                  (click)="presentArchiveStudent(student)"
                  *ngIf="isB2CPortal"
                  matTooltip="Delete student"
                  matTooltipPosition="above"
                >
                  <fa-icon [icon]="trash"></fa-icon>
                </a>
              </ng-container>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-container>

  <ng-container *ngIf="students && students.data.length === 0">
    <app-empty-state
      text="{{
        !searchTerm ? 'No students registered yet!' : 'No students were found!'
      }}"
      [icon]="usersIcon"
    ></app-empty-state>
  </ng-container>

  <ng-container *ngIf="isLoading">
    <div
      class="spinner-icon"
      [ngClass]="{ show: isLoading }"
    ></div>
  </ng-container>
</app-main-layout>
