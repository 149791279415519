import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoursesListPageComponent } from './courses-list.component';
import { LayoutsModule } from 'src/app/shared/layouts/layouts.module';

import { CardModule } from './card/card.module';
import { GridComponent } from './grid/grid.component';
import { CoursesSharedModule } from '../../shared/courses.shared.module';
import { CurtainModule } from 'src/app/shared/components/curtain/curtain.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [CoursesListPageComponent, GridComponent],
  imports: [
    CommonModule,
    LayoutsModule,
    CoursesSharedModule,
    CardModule,
    CurtainModule,
    FontAwesomeModule,
    FormsModule,
  ],
})
export class CoursesListModule {}
