<div class="exportsDiv mb-4">
  <div class="col-3 mx-2 bordered test">
    <h4 class="mb-2">Progresses</h4>
    <div class="form-group test">
      <mat-form-field appearance="fill">
        <mat-label class="test">Select Progress Type</mat-label>
        <mat-select [(value)]="selectedProgressType">
          <mat-option value="assessment">Assessments</mat-option>
          <mat-option value="cognitive therapy">Cognitive Therapy</mat-option>
          <mat-option value="speed reading">Speed Reading</mat-option>
          <mat-option value="reading exercises">Reading Exercises</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="form-group test">
      <mat-form-field appearance="fill">
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input
          [formGroup]="progressQuery"
          [rangePicker]="progressPicker"
        >
          <input
            matStartDate
            formControlName="formFilterStart"
            placeholder="Start date"
          />
          <input
            matEndDate
            formControlName="formFilterEnd"
            placeholder="End date"
          />
        </mat-date-range-input>
        <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle
          matSuffix
          [for]="progressPicker"
        ></mat-datepicker-toggle>
        <mat-date-range-picker #progressPicker></mat-date-range-picker>
      </mat-form-field>
    </div>
    <div class="buttonsWrapper">
      <button
        mat-raised-button
        (click)="exportData('progress', selectedProgressType)"
      >
        Export Data
      </button>
    </div>
  </div>

  <div class="col-3 mx-2 bordered">
    <h4 class="mb-2">Users</h4>
    <div class="buttonsWrapper">
      <button
        mat-raised-button
        (click)="exportData('user')"
      >
        Data
      </button>
    </div>
  </div>

  <div class="col-3 mx-2">
    <h4 class="mb-2">Students</h4>
    <form
      class="formWrapper"
      [formGroup]="studentQuery"
    >
      <mat-form-field appearance="outline">
        <mat-label>Select a filter</mat-label>
        <mat-select formControlName="formFilterType">
          <mat-option value="language">Language</mat-option>
          <mat-option value="theme">Theme</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Filter Value</mat-label>
        <mat-select formControlName="formFilterValue">
          <ng-container
            [ngSwitch]="studentQuery.controls['formFilterType'].value"
          >
            <ng-container *ngSwitchCase="'language'">
              <mat-option
                *ngFor="let optLang of studentsOptList.language"
                [value]="optLang"
              >
                {{ optLang }}
              </mat-option>
            </ng-container>
            <ng-container *ngSwitchCase="'theme'">
              <mat-option
                *ngFor="let optTheme of studentsOptList.theme"
                [value]="optTheme"
              >
                {{ optTheme }}
              </mat-option>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <input
                matInput
                placeholder="Enter a value"
              />
            </ng-container>
          </ng-container>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input
          [formGroup]="studentQuery"
          [rangePicker]="studentPicker"
        >
          <input
            matStartDate
            formControlName="formFilterStart"
            placeholder="Start date"
          />
          <input
            matEndDate
            formControlName="formFilterEnd"
            placeholder="End date"
          />
        </mat-date-range-input>
        <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle
          matSuffix
          [for]="studentPicker"
        ></mat-datepicker-toggle>
        <mat-date-range-picker #studentPicker></mat-date-range-picker>
        <mat-error
          *ngIf="
            studentQuery.controls.formFilterStart.hasError(
              'matStartDateInvalid'
            )
          "
        >
          Invalid start date
        </mat-error>
        <mat-error
          *ngIf="
            studentQuery.controls.formFilterEnd.hasError('matEndDateInvalid')
          "
        >
          Invalid end date
        </mat-error>
      </mat-form-field>
      <button
        mat-raised-button
        type="button"
        (click)="reportStudents()"
        [disabled]="!studentQuery.valid"
      >
        Generate
      </button>
    </form>
  </div>
</div>
