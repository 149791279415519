<div class="mainContainer">
  <div class="content">
    <div class="header p-3 text-center">School Board</div>
  </div>

  <div
    class="info my-3 mx-auto"
    *ngIf="!loading"
  >
    <div class="keyInput m-2">
      <div class="col-4 px-0 d-flex align-items-center">
        <span> School board name </span>
      </div>

      <div class="mx-2">
        <input
          required
          name="name"
          class="text-center basicInput"
          [(ngModel)]="schoolBoard.name"
          autocomplete="off"
          placeholder="School board name"
        />
      </div>
    </div>

    <div class="keyInput m-2">
      <div class="col-4 px-0 d-flex align-items-center">
        <span> Organization </span>
      </div>

      <div class="mx-2">
        <select
          required
          class="basicInput basicSelectable"
          [(ngModel)]="schoolBoard.orgId"
          aria-placeholder="Select the organization"
        >
          <option
            class="disabledOption"
            value=""
            disabled
            selected
          >
            Choose the organization
          </option>
          <option
            class="p-2"
            *ngFor="let org of orgList"
            [value]="org.id"
            autocomplete="off"
          >
            {{ org?.name }}
          </option>
        </select>
      </div>
    </div>

    <div
      *ngIf="!loadInProgress"
      class="buttons mt-5 d-flex justify-content-center align-items-center"
    >
      <app-simple-button
        class="mx-2"
        text="CONFIRM"
        (click)="handleConfirm()"
      ></app-simple-button>

      <app-simple-button
        class="mx-2"
        text="CANCEL"
        (click)="close()"
      ></app-simple-button>
    </div>

    <div
      *ngIf="loadInProgress"
      class="loadingSpinner mt-5 d-flex justify-content-center align-items-center"
    >
      <fa-icon
        [icon]="loadingIcon"
        [spin]="true"
      ></fa-icon>
    </div>
  </div>

  <div
    *ngIf="loading"
    class="loading"
  >
    <fa-icon
      [icon]="loadingIcon"
      [spin]="true"
    ></fa-icon>
  </div>
</div>
