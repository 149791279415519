import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'assessment-report-dialog',
  templateUrl: 'assessment-report.component.html',
  styleUrls: ['assessment-report.component.scss'],
})
export class AssessmentReportDialog implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public _dialog: MatDialog,
    public translate: TranslateService,
    public dialogRef: MatDialogRef<AssessmentReportDialog>,
  ) {}

  ngOnInit(): void {}

  public close() {
    this.dialogRef.close();
  }

  public isCorrectAnswer(data, choice): boolean {
    return choice === data.correctAnswer || (data.isCorrect && choice === data.selectedAnswer);
  }

  public getTotalQuestions() {
    const nonSampleData = this.data.filter((d) => !d.isSample);
    return nonSampleData.length;
  }

  public isWrongAnswer(data, choice): boolean {
    return !data.isCorrect;
  }

  public shouldShowCorrectAnswer(data, choice): boolean {
    return !data.isCorrect && choice === data.correctAnswer;
  }

  public getSkippedQuestions() {
    const skippedQuestions = this.data.filter((d) => d.skipped);
    return skippedQuestions.length;
  }

  public getPoints() {
    const nonSampleData = this.data.filter((d) => !d.isSample);
    const correctAnswers = nonSampleData.filter((d) => d.isCorrect);
    return correctAnswers.length;
  }

  public getWrongAnswers() {
    const nonSampleData = this.data.filter((d) => !d.isSample && !d.skipped);
    const wrongAnswers = nonSampleData.filter((d) => !d.isCorrect);
    return wrongAnswers.length;
  }
}
