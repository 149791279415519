import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { progressTypes } from 'src/app/shared/consts/global-constants';
import { Progress } from 'src/app/shared/models';

interface DialogData {
  level: number;
  assessmentProgresses: Progress[];
  studentId: string;
  programId: string;
}

@Component({
  selector: 'app-program-completion-dialog',
  templateUrl: 'program-completion-dialog.html',
  styleUrls: ['program-completion-dialog.scss'],
})
export class ProgramCompletionDialog implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<ProgramCompletionDialog>,
    private rest: RestAPIService,
    private _snackBar: MatSnackBar,
  ) {}

  public selectedLevel = 0;

  ngOnInit(): void {
    this.selectedLevel = this.data.level;
  }

  public async closeDialog(shouldChangeLevel: boolean) {
    if (this.selectedLevel != this.data.level && shouldChangeLevel) {
      await this.saveAssessmentLevel();

      this.dialogRef.close(false);
    } else {
      this.dialogRef.close(shouldChangeLevel);
    }
  }

  public async saveAssessmentLevel() {
    const assessments = this.data.assessmentProgresses;

    if (assessments.length === 0) {
      const progress = {
        progress: {
          session: 1,
          metadata: {
            level: this.selectedLevel,
            score: 0,
          },
          studentId: this.data.studentId,
          programId: this.data.programId,
          tag: progressTypes.ASSESSMENT,
        },
      };

      await this.rest.post('progress', progress, { msg: 'Could not post progress' });
    } else {
      assessments.forEach(async (assessment) => {
        assessment.metadata.level = this.selectedLevel;
        await this.rest.put(
          'progress/' + assessment.id,
          {
            progress: assessment,
          },
          { msg: 'Could not put progress' },
        );
      });
    }

    this._snackBar.open(`Level updated!`, 'Close', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }
}
