import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'readingcomp-report-dialog',
  templateUrl: 'readingcomp-report.component.html',
  styleUrls: ['readingcomp-report.component.scss'],
})
export class ReadingCompReportDialog implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public _dialog: MatDialog,
    public dialogRef: MatDialogRef<ReadingCompReportDialog>,
  ) {}

  ngOnInit(): void {}

  public close() {
    this.dialogRef.close();
  }

  public isCorrectAnswer(data, choice): boolean {
    return choice === data.correctAnswer;
  }

  public isWrongAnswer(data, choice): boolean {
    return !data.isCorrect && choice === data.selectedAnswer;
  }

  public getLetter(index: number): string {
    return String.fromCharCode(97 + index);
  }

  public getCorrectAnswers() {
    const correctAnswers = this.data.filter((d) => d.isCorrect);
    return correctAnswers.length;
  }
}
