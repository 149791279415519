<div class="mainContainer">
  <div class="header p-4 position-relative">Reading Compreheension Report</div>

  <div class="answerInfo">
    Correct Answers: {{ getCorrectAnswers() }}

    <div class="info">
      <div
        class="d-flex justify-content-center"
        *ngIf="data[0]?.title"
      >
        {{ data[0]?.title }}
      </div>

      <div class="d-flex justify-content-center text">
        {{ data[0]?.content }}
      </div>
    </div>

    <div class="container p-3">
      <div
        class="reportBox p-2"
        *ngFor="let recordData of data; let i = index"
      >
        <div class="question title">
          {{ i + 1 }} - {{ recordData.question }}
        </div>
        <div class="choices">
          <span
            *ngFor="let choice of recordData.choices; let z = index"
            [ngClass]="{
              correctAnswer: isCorrectAnswer(recordData, choice),
              wrongAnswer: isWrongAnswer(recordData, choice)
            }"
          >
            {{ getLetter(z) }} - {{ choice }} <br />
          </span>
        </div>
      </div>
    </div>

    <div class="button d-flex justify-content-center my-3">
      <app-simple-button
        [text]="'Close'"
        (click)="close()"
      >
      </app-simple-button>
    </div>
  </div>
</div>
