import { Component, OnInit, OnDestroy } from '@angular/core';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { NotificationClass } from 'src/app/shared/classes/notification.class';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/shared/models/user.model';
import { UserType } from './mock-data/user-type';
import { UserTypeEnum } from './mock-data/user-type.enum';
import { RoleService } from 'src/app/services/roles/role.service';
import { get } from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { ClientListDialogComponent } from './client-list-dialog/client-list-dialog-component';
import { PatronListSaver } from 'src/app/services/utils/patron-list-saver.service';
import { ConfirmationService } from 'src/app/services/confirmation/confirmation.service';
import { ClientsListService } from '../../menus/clients-menu/clients-list/clients-list.service';
import { ManagersListService } from '../../menus/managers-and-admins-menu/managers-list/managers-list.service';
import { AdminsListService } from '../../menus/managers-and-admins-menu/admins-list/admins-list.service';
import { Client } from 'src/app/shared/interfaces';
import { FormTypes } from 'src/app/shared/enums/forms/form-enums';
import { OrganizationsListService } from '../../menus/organizations-menu/organizations-list/organizations-list.service';
import { Organization } from 'src/app/core/openapi';
import { DialogWithLinkComponent } from 'src/app/shared/dialogs/dialog-with-link/dialog-with-link.component';

declare let cloudinary: any;

@Component({
  selector: 'app-manage-user',
  templateUrl: './manage-user.component.html',
  styleUrls: ['./manage-user.component.scss'],
})
export class ManageUserComponent extends NotificationClass implements OnInit, OnDestroy {
  public formGroup: UntypedFormGroup;
  public user: User;
  public org: Organization;
  public orgAcc: User;
  public userId: string;
  public accountId: string;
  public saving = false;
  public isPortalAdmin = false;
  public isOrgAdmin = false;
  public isOrgManager = false;
  public isReseller = false;
  public isPortalOwner = false;
  public outsiderOrgId: string;
  public resellerId: string;
  public editingUser = undefined;
  public formType = FormTypes.CREATE;

  // This pattern prevents the inputs to be only white spaces
  public noWhiteSpace = /^[\S\s]+$/;

  constructor(
    protected _snackBar: MatSnackBar,
    private _rest: RestAPIService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _confirm: ConfirmationService,
    private _userType: UserType,
    private _roles: RoleService,
    private dialog: MatDialog,
    private patronListSaver: PatronListSaver,
    private _clientsListService: ClientsListService,
    private _managersListService: ManagersListService,
    private _adminsListService: AdminsListService,
    private _orgsListService: OrganizationsListService,
  ) {
    super(_snackBar);
    this.formGroup = this._createUserFormGroup();
    this.formGroup.get('allowFullAccess').setValue(true);
  }

  async ngOnInit() {
    this.dialog.open(DialogWithLinkComponent, {
      width: '600px',
      data: {
        message:
          'To register, go to the Active Users page (or click Go to Page), select the Users tab, and then click Add New.',
        link: '/users',
      },
    });
    await this.whoAmI();
    const userId = this._activatedRoute.snapshot.paramMap.get('userId');
    this.userId = userId && userId !== '0' ? userId : null;

    if (this.userId) {
      const editingUserStr = localStorage.getItem('LS_OrgEditingUser');
      if (!editingUserStr) {
        this._router.navigate(['/users']);
      } else {
        const editingUser: Client = JSON.parse(editingUserStr);

        if (editingUser.type === 'Organization') {
          this.accountId = this.userId;
          this.outsiderOrgId = editingUser.organization.id;
        } else {
          this.accountId = editingUser.accountId ? editingUser.accountId : editingUser.id;
        }

        const managerAccess = get(editingUser, 'patron.allowFullAccess', true);
        const managerClientList = get(editingUser, 'patron.allowedClients', []);

        this.formType = FormTypes.EDIT;

        this.formGroup.patchValue({
          email: editingUser.email,
          fullname: editingUser.fullname || editingUser.name,
          nickname: editingUser.nickname,
          image: editingUser.image,
          type: editingUser.type,
          allowFullAccess: managerAccess,
          allowedClients: managerClientList,
        });
      }
    }
  }

  public async openClientList() {
    const patrons = await this.patronListSaver.getOrganizationPatronList();

    if (!patrons) {
      return;
    }

    const dialog = this.dialog.open(ClientListDialogComponent, {
      width: '550px',
      height: '550px',
      panelClass: 'client-list-modalBox',
      data: {
        patrons: patrons,
        allowedClients: this.formGroup.value.allowedClients || [],
      },
    });

    dialog.afterClosed().subscribe((allowedClients) => {
      if (allowedClients) {
        this.formGroup.value.allowedClients = allowedClients;
      }
    });
  }

  private async whoAmI() {
    const user = (this.user = this._roles.user);
    this.resellerId = user.id;
    this.orgAcc = this._roles.orgAcc;

    this.isPortalOwner = this._roles.isPortalOwner();
    this.isPortalAdmin = this._roles.isOrgOwner();
    this.isOrgAdmin = this._roles.isOrgAdmin();
    this.isOrgManager = this._roles.isOrgManager();
    this.isReseller = this._roles.isReseller();

    const response = await this._rest.get('organization/self', { msg: 'Could not get organization.' });
    if (response) {
      this.org = response?.organization;
    }
  }

  public resetForm($event?: any) {
    this.formGroup.reset();
    if ($event) {
      this.formGroup.patchValue({
        type: $event.value,
        allowFullAccess: true,
        allowedClients: [],
      });
    }
  }

  public checkFormDisabled(): boolean {
    const controls = this.formGroup.controls;
    return Object.keys(controls).some((key: string) => {
      return controls[key].touched && !controls[key].valid;
    });
  }

  public async save(): Promise<void> {
    const type = this.formGroup.get('type');
    if (!this.userId && [UserTypeEnum.OrganizationAdmin, UserTypeEnum.OrganizationManager].indexOf(type.value) !== -1) {
      const message = this._userType.getMessage(type.value);

      this._confirm
        .createConfirmation(
          message.title,
          message.description,
          message.confirmation,
          'No Thanks',
          '500px',
          false,
          '',
          'custom-modalbox',
        )
        .then(
          async () => this.doSave(),
          () => {
            type.setValue(UserTypeEnum.Client);
          },
        );
    } else {
      this.doSave();
    }
  }

  public async doSave(): Promise<void> {
    try {
      const formValue = this.formGroup.getRawValue();
      this.saving = true;

      switch (this.formType) {
        case FormTypes.EDIT:
          await this._saveEditingUser(this.userId, formValue);
          break;
        case FormTypes.CREATE:
          await this._saveNewUser(formValue);
          break;
        default:
          break;
      }
    } catch {
      this.saving = false;
    }
  }

  public async createRedirectToSame() {
    await this._router.navigate(['/users/manage/0']);
    this.resetForm();
    await this.ngOnInit();
  }

  private async _saveNewUser(formValue): Promise<void> {
    try {
      const newFormValue = {
        ...formValue,
        verifyType: 'client',
        org: this.org.name,
        subdomain: this.org.subdomain,
        orgEmail: this.orgAcc.email,
        lang: this.org.language,
      };

      const response = await this._rest.post('account', newFormValue);

      if (!response) {
        throw new Error('User creation failed');
      }

      this.notify('User created successfully!');
      await this.refreshLists();
      this._router.navigate(['/users']);
    } catch (error) {
      this.saving = false;
    }
  }

  private async _saveEditingUser(userId: string, formValue): Promise<void> {
    try {
      const [givenName, familyName = ''] = formValue.fullname.split(' ');
      formValue.givenName = givenName;
      formValue.familyName = familyName;

      const response = await this._rest.put('patron/' + userId, {
        patron: formValue,
        accountId: this.accountId,
      });

      if (!response) {
        throw new Error('User edit failed');
      }

      this.notify('User updated successfully!');
      await this.refreshLists();
      localStorage.removeItem('LS_OrgEditingUser');
      this._router.navigate(['/users'], { queryParams: { userId: this.userId, type: formValue.type } });
    } catch (error) {
      this.saving = false;
    }
  }

  public uploadToCloudinary(): void {
    const cloudinaryConfig = {
      cloud_name: environment.CLOUDINARY_CLOUD_NAME,
      upload_preset: environment.CLOUDINARY_UPLOAD_PRESET,
      secure: true,
    };

    cloudinary.openUploadWidget(cloudinaryConfig, (error, result) => {
      if (result) {
        const [imageResponse] = result;
        const { secure_url, url } = imageResponse;
        this.formGroup.get('image').setValue(secure_url || url);
      } else if (error && error.message !== 'User closed widget') {
        this.notify('There was an error while uploading the image, please try again later');
      }
    });
  }

  private _createUserFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      fullname: new UntypedFormControl('', [Validators.required, Validators.pattern(this.noWhiteSpace)]),
      nickname: new UntypedFormControl(''),
      type: new UntypedFormControl('', [Validators.required]),
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      image: new UntypedFormControl('./assets/img/StudentImagePlaceholder.png'),
      allowFullAccess: new UntypedFormControl(true),
      allowedClients: new UntypedFormControl([]),
    });
  }

  private async refreshLists() {
    await this._clientsListService.getClients({ refresh: true });
    await this._adminsListService.getAdmins({ refresh: true });
    await this._managersListService.getManagers({ refresh: true });
    await this._orgsListService.getOrganizations({ refresh: true });
  }

  ngOnDestroy() {
    localStorage.removeItem('LS_OrgEditingUser');
  }
}
