<div class="mainContainer h-100 pb-2">
  <div class="header text-center">Courses</div>

  <div
    class="d-flex justify-content-center col-12 p-2"
    *ngIf="data.lessons.length > 0"
  >
    <div class="col-4 px-0">
      <div class="optionsHeader col-12 p-2">
        <div class="options d-flex justify-content-center">
          <div
            class="mx-2"
            *ngFor="let option of getCourseContainerOptions()"
            [ngClass]="{
              selectedOption: selectedOption === courseContainerOptions[option],
              nonSelected: selectedOption !== courseContainerOptions[option]
            }"
            (click)="selectedOption = courseContainerOptions[option]"
          >
            {{ courseContainerOptions[option] }}
          </div>
        </div>
      </div>

      <div
        class="lessonsContainer col-12 p-2"
        *ngIf="selectedOption === courseContainerOptions.lessonInformation"
      >
        <div
          class="containerWrapper list"
          cdkDropList
          (cdkDropListDropped)="dropLesson($event)"
        >
          <div
            cdkDrag
            class="lessonList col-12 px-0 mx-auto position-relative"
            *ngFor="let lesson of data.lessons; let l = index"
          >
            <mat-accordion>
              <mat-expansion-panel
                class="my-1 expansionPanel"
                [expanded]="choosenLesson === lesson"
                id="{{ 'lesson' + l }}"
              >
                <mat-expansion-panel-header
                  (click)="choosenLesson = lesson; choosenSection = undefined"
                >
                  <div>
                    {{ lesson.title }}
                  </div>
                </mat-expansion-panel-header>

                <div class="sections">
                  <div
                    class="list"
                    cdkDropList
                    (cdkDropListDropped)="dropSection($event, lesson)"
                  >
                    <div
                      cdkDrag
                      *ngFor="let section of lesson.sections; let i = index"
                      class="d-flex my-2 sectionInfo"
                      (click)="selectSection(section)"
                    >
                      <fa-icon
                        class="icon handler mx-2"
                        [icon]="handleDots"
                        cdkDragHandle
                      >
                      </fa-icon>

                      <fa-icon
                        class="icon mx-2"
                        [icon]="getSectionIcon(section.type)"
                      >
                      </fa-icon>

                      <div class="sectionTitle">
                        {{ section.title }}
                      </div>

                      <draft-badge
                        [isDraft]="section.draft"
                        *ngIf="section.draft"
                      ></draft-badge>
                    </div>
                  </div>
                </div>

                <div class="buttons d-flex">
                  <div class="mx-auto mt-2">
                    <app-simple-button
                      [text]="'Add section'"
                      (click)="openSectionCreator(lesson)"
                    ></app-simple-button>
                  </div>
                </div>
              </mat-expansion-panel>

              <fa-icon
                class="icon handler lessonHandler mx-2"
                [icon]="handleDots"
                cdkDragHandle
              >
              </fa-icon>
            </mat-accordion>
          </div>
        </div>

        <div class="d-flex justify-content-center align-items-end addLesson">
          <app-simple-button
            [text]="'ADD LESSON'"
            (click)="createLesson()"
          >
          </app-simple-button>
        </div>
      </div>

      <div
        class="courseInfoContainer col-12 p-2"
        *ngIf="selectedOption === courseContainerOptions.courseInformation"
      >
        <div class="containerWrapper">
          <div
            class="my-2 d-flex justify-content-center align-items-center col-12 px-0"
          >
            <div class="inputHeader">Draft</div>

            <mat-checkbox
              class="mx-2"
              [(ngModel)]="data.draft"
              disabled
              *ngIf="isEmptyCourse(data)"
              matTooltip="This course doesn't have content! Add content to enable the draft status."
              matTooltipPosition="above"
            >
            </mat-checkbox>

            <mat-checkbox
              (change)="handleDraftStatus('course', $event)"
              class="mx-2"
              [(ngModel)]="data.draft"
              *ngIf="!isEmptyCourse(data)"
            >
            </mat-checkbox>
          </div>

          <div class="my-2">
            <div class="inputHeader">Course title</div>

            <input
              class="courseContainerInput"
              [(ngModel)]="data.title"
            />
          </div>

          <div class="my-2">
            <div class="inputHeader">Course name</div>

            <input
              class="courseContainerInput"
              [(ngModel)]="data.name"
            />
          </div>

          <div class="my-2">
            <div class="inputHeader">Language</div>

            <select
              class="basicSelectLanguage"
              [(ngModel)]="data.language"
            >
              <option
                value=""
                disabled
                selected
              >
                Choose the course language
              </option>
              <option
                *ngFor="let language of languages"
                [value]="language.languageCode"
              >
                {{ language.name }}
              </option>
            </select>
          </div>

          <div *ngIf="!data.useForGameCategory">
            <div class="my-2 certificateOptions">
              <div class="inputHeader">Course certificate</div>

              <mat-radio-button
                *ngFor="let option of getCertificateTypes()"
                [checked]="checkCertificateType(option)"
                class="mx-2"
                [value]="option"
                (click)="changeCertificateType(option)"
              >
                {{ option | titlecase }}
              </mat-radio-button>

              <div class="my-2">
                <app-simple-button
                  *ngIf="!loadingPreview"
                  class="my-2"
                  [text]="'Preview certificate'"
                  (click)="previewCertificate()"
                  [disabled]="!data.hasCertificate"
                >
                </app-simple-button>

                <fa-icon
                  class="my-2"
                  *ngIf="loadingPreview"
                  [icon]="load"
                  [spin]="true"
                >
                </fa-icon>
              </div>
            </div>

            <div class="my-4">
              <div class="inputHeader text-center">Allowed users type</div>

              <div class="d-flex justify-content-center">
                <div
                  *ngFor="let user of getUserTypes()"
                  class="mx-2"
                >
                  <mat-checkbox
                    (click)="addAllowedUser(userTypes[user])"
                    [checked]="checkUserType(userTypes[user])"
                  >
                    {{ userTypes[user] | titlecase }}
                  </mat-checkbox>
                </div>
              </div>
            </div>

            <div class="my-4">
              <div class="inputHeader text-center">Allowed users</div>

              <div class="d-flex justify-content-center">
                <mat-radio-group
                  class="d-flex"
                  [(ngModel)]="data.enabledToAll"
                >
                  <mat-radio-button
                    (click)="changeEnabledStatus(true)"
                    class="mx-2"
                    [value]="true"
                    [checked]="data.enabledToAll"
                    [disabled]="data.enabledToAll"
                    [ngClass]="{ disabled: data.enabledToAll }"
                  >
                    Enable to all users
                  </mat-radio-button>

                  <mat-radio-button
                    (click)="changeEnabledStatus(false)"
                    class="mx-2"
                    [value]="false"
                    [checked]="!data.enabledToAll"
                    [disabled]="!data.enabledToAll"
                    [ngClass]="{ disabled: !data.enabledToAll }"
                  >
                    Enable manually
                  </mat-radio-button>
                </mat-radio-group>
              </div>
            </div>

            <div class="d-flex justify-content-center">
              <app-simple-button
                class="my-2"
                [text]="'Select users'"
                [disabled]="data.enabledToAll"
                (click)="openAllowedOrgsDialog()"
              >
              </app-simple-button>
            </div>
          </div>

          <div
            class="categoryWarning"
            *ngIf="data.useForGameCategory && !creatingCategory"
          >
            This course is being used as a game category, click
            <span
              class="link-span"
              (click)="redirectToCategoryDashboard()"
              >here</span
            >
            to check the category details
          </div>

          <div class="my-4">
            <div class="inputHeader text-center">
              Use course as game category
            </div>

            <div class="d-flex justify-content-center">
              <mat-radio-group
                class="d-flex"
                [(ngModel)]="data.useForGameCategory"
              >
                <mat-radio-button
                  class="mx-2"
                  [value]="true"
                  (click)="addCourseAsGameCategory()"
                  [checked]="data.useForGameCategory"
                  [disabled]="data.useForGameCategory"
                  [ngClass]="{ disabled: data.useForGameCategory }"
                >
                  Yes
                </mat-radio-button>

                <mat-radio-button
                  class="mx-2"
                  [value]="false"
                  (click)="removeCourseAsGameCategory()"
                  [checked]="!data.useForGameCategory"
                  [disabled]="!data.useForGameCategory"
                  [ngClass]="{ disabled: !data.useForGameCategory }"
                >
                  No
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>

          <div class="my-4">
            <div class="inputHeader text-center">Image</div>

            <div class="d-flex justify-content-center">
              <div
                class="d-flex justify-content-center w-100 position-relative"
              >
                <img
                  class="courseImage"
                  [src]="data.image"
                />

                <div
                  class="imageFooter position-absolute replaceCourseImage"
                ></div>

                <div
                  class="replaceFooter"
                  (click)="editImage()"
                >
                  REPLACE
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-center align-items-end addLesson">
          <app-simple-button
            class="mx-2"
            [text]="'Save'"
            (click)="saveCourse()"
          >
          </app-simple-button>

          <app-simple-button
            class="mx-2"
            [text]="'Close'"
            (click)="closeDialog()"
          >
          </app-simple-button>
        </div>
      </div>
    </div>

    <div
      class="col-8 px-0 empty-state"
      *ngIf="!choosenLesson && !choosenSection"
    >
      <div class="text-center">
        <fa-icon
          class="editIcon mx-auto"
          [icon]="edit"
        >
        </fa-icon>

        <br />

        <div>Choose a lesson or section to get started</div>
      </div>
    </div>

    <div
      class="col-8 p-4"
      *ngIf="choosenLesson && !choosenSection"
    >
      <div class="d-flex lessonHeader">
        <h3>Edit: {{ choosenLesson.title }}</h3>

        <div class="buttons d-flex mx-3">
          <button
            mat-raised-button
            color="warm"
            class="mx-2 basicButton"
            (click)="deleteLesson(lesson)"
          >
            Delete lesson
          </button>

          <button
            mat-raised-button
            color="primary"
            class="mx-2 basicButton"
            (click)="saveCourse()"
          >
            Save
          </button>
        </div>
      </div>

      <div class="card mx-2">
        <div
          class="my-2 d-flex justify-content-center align-items-center col-12 px-0"
        >
          <div class="inputHeader">Draft</div>
          <mat-checkbox
            class="mx-2"
            [(ngModel)]="choosenLesson.draft"
            disabled
            *ngIf="isEmptyLesson(choosenLesson)"
            matTooltip="This lesson doesn't have content! Add content to enable the draft status."
            matTooltipPosition="above"
          >
          </mat-checkbox>

          <mat-checkbox
            (change)="handleDraftStatus('lesson', $event)"
            class="mx-2"
            [(ngModel)]="choosenLesson.draft"
            *ngIf="!isEmptyLesson(choosenLesson)"
          >
          </mat-checkbox>
        </div>
        <div class="inputHeader">Lesson title</div>

        <input
          class="basicInput"
          [(ngModel)]="choosenLesson.title"
        />
      </div>
    </div>

    <div
      class="col-8 p-4"
      *ngIf="choosenSection"
    >
      <div
        class="videoSection"
        *ngIf="choosenSection.type === 'video'"
      >
        <div class="d-flex lessonHeader">
          <h3>
            {{ choosenSection.title }}
          </h3>
        </div>

        <div class="sectionCard mx-2">
          <div
            class="my-2 d-flex justify-content-center align-items-center col-12 px-0"
          >
            <div class="inputHeader">Draft</div>
            <mat-checkbox
              class="mx-2"
              [(ngModel)]="choosenSection.draft"
              disabled
              *ngIf="isEmptySection(choosenSection)"
              matTooltip="This section doesn't have content! Add content to enable the draft status."
              matTooltipPosition="above"
            >
            </mat-checkbox>

            <mat-checkbox
              (change)="handleDraftStatus($event)"
              class="mx-2"
              [(ngModel)]="choosenSection.draft"
              *ngIf="!isEmptySection(choosenSection)"
            >
            </mat-checkbox>
          </div>

          <div class="my-2">
            <div class="inputHeader">Title</div>

            <input
              class="basicInput"
              [(ngModel)]="choosenSection.title"
            />
          </div>

          <div class="my-2">
            <div class="inputHeader">Video url</div>

            <input
              class="basicInput"
              [(ngModel)]="choosenSection.content.url"
            />

            <button
              mat-raised-button
              color="primary"
              class="mx-2 my-2"
              (click)="uploadVideo(choosenSection)"
            >
              Upload video
            </button>
          </div>

          <div class="col-12 px-0 mt-3">
            <video
              width="100%"
              controls
              *ngIf="!choosenSection.content.url"
            >
              <source
                src=""
                type="video/mp4"
              />
            </video>

            <iframe
              *ngIf="choosenSection.content.url"
              width="100%"
              height="450"
              [src]="returnVideoUrl(choosenSection.content.url) | safeUrl"
              allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            >
            </iframe>
          </div>

          <mat-accordion multi>
            <mat-expansion-panel class="my-1 slidesPanel">
              <mat-expansion-panel-header>
                <div>
                  Add text
                  <span class="subHeader"> ( Optional ) </span>
                </div>
              </mat-expansion-panel-header>

              <angular-editor
                [placeholder]="'Enter content here...'"
                [(ngModel)]="choosenSection.content.text"
              ></angular-editor>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>

      <div
        class="textSection"
        *ngIf="choosenSection.type === 'text'"
      >
        <div class="d-flex lessonHeader">
          <h3>
            {{ choosenSection.title }}
          </h3>
        </div>

        <div class="sectionCard mx-2">
          <div
            class="my-2 d-flex justify-content-center align-items-center col-12 px-0"
          >
            <div class="inputHeader">Draft</div>

            <mat-checkbox
              class="mx-2"
              [(ngModel)]="choosenSection.draft"
              disabled
              *ngIf="isEmptySection(choosenSection)"
              matTooltip="This section doesn't have content! Add content to enable the draft status."
              matTooltipPosition="above"
            >
            </mat-checkbox>

            <mat-checkbox
              (change)="handleDraftStatus($event)"
              class="mx-2"
              [(ngModel)]="choosenSection.draft"
              *ngIf="!isEmptySection(choosenSection)"
            >
            </mat-checkbox>
          </div>
          <div class="my-2">
            <div class="inputHeader">Title</div>

            <input
              class="basicInput"
              [(ngModel)]="choosenSection.title"
            />
          </div>

          <div class="my-2">
            <div class="inputHeader">Content</div>

            <angular-editor
              [placeholder]="'Enter content here...'"
              [(ngModel)]="choosenSection.content.text"
            ></angular-editor>
          </div>
        </div>
      </div>

      <div
        class="slideSection"
        *ngIf="choosenSection.type === 'slides'"
      >
        <div class="d-flex lessonHeader">
          <h3>
            {{ choosenSection.title }}
          </h3>
        </div>

        <div class="sectionCard mx-2">
          <div
            class="my-2 d-flex justify-content-center align-items-center col-12 px-0"
          >
            <div class="inputHeader">Draft</div>

            <mat-checkbox
              class="mx-2"
              [(ngModel)]="choosenSection.draft"
              disabled
              *ngIf="isEmptySection(choosenSection)"
              matTooltip="This section doesn't have content! Add content to enable the draft status."
              matTooltipPosition="above"
            >
            </mat-checkbox>

            <mat-checkbox
              (change)="handleDraftStatus($event)"
              class="mx-2"
              [(ngModel)]="choosenSection.draft"
              *ngIf="!isEmptySection(choosenSection)"
            >
            </mat-checkbox>
          </div>
          <div class="my-2">
            <div class="inputHeader">Title</div>

            <input
              class="basicInput"
              [(ngModel)]="choosenSection.title"
            />
          </div>
        </div>

        <div class="sectionCard mx-2">
          <div class="d-flex lessonHeader">
            <div class="presentationHeader">Presentation</div>
          </div>

          <div class="col-12 px-0 my-4">
            <div class="d-flex justify-content-center">
              <button
                mat-raised-button
                color="primary"
                class="mx-2"
                (click)="addSlide(choosenSection)"
              >
                Add slide
              </button>

              <button
                [disabled]="selectedSlidesList.length === 0"
                mat-raised-button
                color="primary"
                class="mx-2"
                (click)="deleteSlides()"
              >
                Delete slides
              </button>
            </div>

            <br />
            <br />

            <div class="d-flex justify-content-center">
              <button
                [disabled]="choosenSection.content.slides.length === 0"
                mat-raised-button
                color="primary"
                class="mx-2"
                (click)="selectAllSlides(choosenSection)"
              >
                Select all
              </button>

              <button
                [disabled]="choosenSection.content.slides.length === 0"
                mat-raised-button
                color="primary"
                class="mx-2"
                (click)="reverseSlideSelection()"
              >
                Reverse selection
              </button>
            </div>
          </div>

          <div
            class="my-2"
            *ngIf="choosenSection.content.slides.length > 0"
          >
            <div
              class="col-12 px-0"
              cdkDropList
              (cdkDropListDropped)="dropSlide($event, choosenSection)"
            >
              <div
                cdkDrag
                class="col-12 px-0 d-flex justify-content-start align-items-center"
                *ngFor="
                  let slide of choosenSection.content.slides;
                  let i = index
                "
              >
                <fa-icon
                  class="icon handler mx-2"
                  [icon]="handleDots"
                  cdkDragHandle
                >
                </fa-icon>
                <mat-accordion
                  multi
                  class="w-100"
                >
                  <mat-expansion-panel class="my-1 slidesPanel">
                    <mat-expansion-panel-header>
                      <div>Slide {{ i + 1 }}</div>
                    </mat-expansion-panel-header>

                    <div class="d-flex lessonHeader border-top p-2 col-12">
                      <div>Preview</div>
                    </div>

                    <div class="col-12 px-0 d-flex justify-content-center">
                      <div class="col-11 px-0 position-relative">
                        <img
                          class="w-100"
                          [src]="slide"
                        />

                        <div
                          class="imageFooter position-absolute col-12 px-0"
                        ></div>

                        <div
                          class="replaceFooter"
                          (click)="replaceSlide(choosenSection, slide)"
                        >
                          REPLACE
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>

                <mat-checkbox
                  class="mx-2"
                  checked
                  (click)="selectSlide(slide)"
                  *ngIf="checkIfSlideIsSelected(slide)"
                >
                </mat-checkbox>

                <mat-checkbox
                  class="mx-2"
                  unchecked
                  (click)="selectSlide(slide)"
                  *ngIf="!checkIfSlideIsSelected(slide)"
                >
                </mat-checkbox>
              </div>
            </div>
          </div>

          <div
            class="my-2 emptyPresentation"
            *ngIf="choosenSection.content.slides.length === 0"
          >
            You don't have slides for this presentation
          </div>
        </div>
      </div>

      <div
        class="quizSection"
        *ngIf="choosenSection.type === 'quiz'"
      >
        <div class="d-flex lessonHeader">
          <h3>
            {{ choosenSection.title }}
          </h3>
        </div>

        <div class="sectionCard mx-2">
          <div
            class="my-2 d-flex justify-content-center align-items-center col-12 px-0"
          >
            <div class="inputHeader">Draft</div>

            <mat-checkbox
              class="mx-2"
              [(ngModel)]="choosenSection.draft"
              disabled
              *ngIf="isEmptySection(choosenSection)"
              matTooltip="This section doesn't have content! Add content to enable the draft status."
              matTooltipPosition="above"
            >
            </mat-checkbox>

            <mat-checkbox
              (change)="handleDraftStatus($event)"
              class="mx-2"
              [(ngModel)]="choosenSection.draft"
              *ngIf="!isEmptySection(choosenSection)"
            >
            </mat-checkbox>
          </div>
          <div class="my-2">
            <div class="inputHeader">Title</div>

            <input
              class="basicInput"
              [(ngModel)]="choosenSection.title"
            />
          </div>
        </div>

        <div class="sectionCard mx-2">
          <div class="d-flex lessonHeader">
            <div class="presentationHeader">Quiz</div>
          </div>

          <div
            class="col-12 px-0 d-flex justify-content-start align-items-center"
            *ngIf="choosenSection.content.quizes.length > 0"
          >
            <div class="my-2 col-12 px-0">
              <div
                class="d-flex justify-content-start align-items-center"
                *ngFor="
                  let quiz of choosenSection.content.quizes;
                  let i = index
                "
              >
                <mat-accordion
                  multi
                  class="col-10 px-0"
                >
                  <mat-expansion-panel class="my-1 slidesPanel">
                    <div class="questionType">
                      <select
                        class="basicSelect"
                        [(ngModel)]="quiz.type"
                      >
                        <option
                          class="p-3"
                          *ngFor="let type of getQuizTypeOptions()"
                          (click)="checkAnswerType(choice, quiz)"
                        >
                          {{ getQuizType(type) }}
                        </option>
                      </select>
                    </div>

                    <mat-expansion-panel-header>
                      <div class="questionTitle">
                        Question #{{ i + 1 }} {{ quiz.question }}
                      </div>
                    </mat-expansion-panel-header>

                    <div class="my-2">
                      <div class="inputHeader">Question</div>

                      <input
                        class="basicInput"
                        [(ngModel)]="quiz.question"
                      />
                    </div>

                    <div
                      class="my-2"
                      *ngFor="let choice of quiz.choices; let i = index"
                    >
                      <div
                        class="inputHeader d-flex justify-content-between choiceHeader"
                      >
                        <span> Choice #{{ i + 1 }} </span>

                        <span>
                          <mat-checkbox
                            (click)="checkAnswerType(choice, quiz)"
                            class="mx-2"
                            [(ngModel)]="choice.correctChoice"
                          >
                            This is the correct answer
                          </mat-checkbox>
                        </span>
                      </div>

                      <div
                        class="d-flex justify-content-start align-items-center"
                      >
                        <input
                          class="basicInput"
                          [(ngModel)]="choice.text"
                        />
                        <fa-icon
                          class="icon mx-2"
                          [icon]="trash"
                          (click)="removeChoice(choice, quiz)"
                        ></fa-icon>
                      </div>
                    </div>

                    <div class="my-5">
                      <div
                        class="inputHeader d-flex justify-content-between choiceHeader"
                      >
                        <span> Answer feedback </span>
                      </div>

                      <angular-editor
                        [placeholder]="'Enter content here...'"
                        [(ngModel)]="quiz.feedBack"
                      >
                      </angular-editor>
                    </div>

                    <div class="d-flex justify-content-start my-3">
                      <app-simple-button
                        [text]="'Add choice'"
                        (click)="addChoice(quiz)"
                      >
                      </app-simple-button>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>

                <fa-icon
                  class="icon mx-2"
                  [icon]="trash"
                  (click)="deleteQuestion(choosenSection, quiz)"
                ></fa-icon>
              </div>
            </div>
          </div>

          <div
            class="my-2 emptyPresentation"
            *ngIf="choosenSection.content.quizes.length === 0"
          >
            You don't have questions for this quiz
          </div>

          <div class="col-12 px-0 d-flex justify-content-center my-2">
            <button
              mat-raised-button
              color="primary"
              class="mx-auto"
              (click)="addQuestion(choosenSection)"
            >
              Add question
            </button>
          </div>
        </div>
      </div>

      <div class="buttons d-flex mx-auto justify-content-center my-3 p-2">
        <button
          mat-raised-button
          color="primary"
          class="mx-2"
          (click)="saveCourse()"
        >
          Save
        </button>

        <button
          mat-raised-button
          color="warn"
          class="mx-2"
          (click)="deleteSection(choosenSection)"
        >
          Delete section
        </button>
      </div>
    </div>
  </div>

  <div
    class="m-auto empty-lesson-state d-flex justify-content-center align-items-center"
    *ngIf="data.lessons.length === 0"
  >
    <div>
      <div class="col-12 px-0 empty-state">
        <div class="text-center h-100">
          <div>This course doesn't have any created lessons!</div>
        </div>
      </div>

      <div class="d-flex justify-content-center align-items-center addLesson">
        <app-simple-button
          [text]="'ADD LESSON'"
          (click)="createLesson()"
        >
        </app-simple-button>
      </div>
    </div>
  </div>
</div>
