<div class="h-100">
  <div class="header col-12 p-3 mx-0">{{ data.category.name }} results</div>

  <div
    class="p-2"
    *ngIf="rerunOptions.length > 1 && data.category.mandatory"
  >
    <mat-form-field appearance="outline">
      <mat-label>Select a rerun</mat-label>
      <mat-select
        [(ngModel)]="selectedRerun"
        (selectionChange)="selectProgress()"
      >
        <mat-option
          *ngFor="let rerun of rerunOptions"
          [value]="rerun"
        >
          {{ rerun + 1 }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div
    class="labels d-flex col-12 px-0 mx-0 justify-content-center mt-3"
    *ngIf="data.category.graphType != 'bar'"
  >
    <div
      *ngFor="let day of [1, 2, 3, 4, 5]; let i = index"
      class="mx-1"
    >
      <span class="day{{ i + 1 }} labelDays"></span>
      <span> {{ 'Day ' + day }} </span>
    </div>
  </div>

  <div class="graphContainer">
    <canvas
      *ngFor="let chart of charts; let i = index"
      (click)="chartClickEvent($event, i)"
      [ngClass]="{ pieChart: chart.type != 'bar' }"
      [width]="chart.width"
      [height]="chart.height"
      [id]="'chart' + chart.id"
    >
    </canvas>
  </div>

  <div class="closeBtn w-100 d-flex justify-content-center">
    <button
      class="actionButton text-center p-2"
      (click)="closeDialog()"
    >
      Close
    </button>
    <button
      *ngIf="data.category.name !== 'Assessment'"
      (click)="openFullResultChart()"
      class="actionButton text-center p-2"
    >
      Open full result chart
    </button>
  </div>
</div>
