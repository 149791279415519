import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { defaultDescriptions } from '../consts/program-game-consts';
import { get } from 'lodash';
import { Category } from '../../configuration-pages/interfaces/global-config.interfaces';

@Component({
  selector: 'app-category-info',
  templateUrl: 'category-info-dialog.html',
  styleUrls: ['category-info-dialog.scss'],
})
export class CategoryInfoDialog implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: Category) {}

  public categoryDetails = {
    name: get(this.data, 'name', ''),
    maxSessions: get(this.data, 'maxSessions', ''),
  };

  public categoryInfo = {
    Unlock: this.getUnlockDetails(),
    Sessions: this.getSessionDetails(),
    Description: get(this.data, 'description', ''),
  };

  ngOnInit(): void {}

  public buildUnlockMessage() {
    const unlockMessages = [];

    const unlockConditionList = this.data?.unlockCondition || [];

    for (const condition of unlockConditionList) {
      if (condition.session > 0) {
        unlockMessages.push(`${condition.name} session ${condition.session}`);
      } else {
        unlockMessages.push(`at least one session of ${condition.name}`);
      }
    }

    if (unlockMessages.length === 0) {
      return `This category is always unlocked`;
    } else {
      return `This category will unlock after ${unlockMessages.join('and')} ${
        unlockMessages.length > 1 ? 'are' : 'is'
      } completed`;
    }
  }

  public getSessionDetails() {
    const categoryName = this.categoryDetails.name;
    const maxSessions = this.categoryDetails.maxSessions;

    if (defaultDescriptions[categoryName.toLowerCase()]) {
      return defaultDescriptions[categoryName.toLowerCase()].Sessions;
    } else {
      return `${categoryName} has ${maxSessions} sessions in total`;
    }
  }

  public getUnlockDetails() {
    if (defaultDescriptions[this.categoryDetails.name.toLowerCase()]) {
      const unlockConditionList = get(this.data, 'unlockCondition[0]', undefined);
      const session = unlockConditionList ? unlockConditionList.session : '';
      return defaultDescriptions[this.categoryDetails.name.toLowerCase()].Unlocks.replace('%SESSION%', session);
    } else {
      return this.buildUnlockMessage();
    }
  }

  public getInfoObjectKeys() {
    return Object.keys(this.categoryInfo);
  }
}
