import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationService } from 'src/app/services/confirmation/confirmation.service';
import { MatDialogRef } from '@angular/material/dialog';
import { SectionTypes, UserTypes } from '../utils/course-utils';
import { Course, Language } from 'src/app/pages/configuration-pages/interfaces/global-config.interfaces';
import { faCamera, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { CloudnaryService } from 'src/app/services/cloudnary/cloudnary.service';
import { firstValueFrom } from 'rxjs';
import { LanguagesControllerService } from 'src/app/core/openapi';

interface CourseForm {
  name?: string;
  title?: string;
  image?: string;
  language?: string;
}

@Component({
  selector: 'app-course-creator',
  templateUrl: './course-creator.component.html',
  styleUrls: ['./course-creator.component.scss'],
})
export class CourseCreator implements OnInit {
  public readonly camera: IconDefinition = faCamera;
  public courses = [];
  public loading = true;

  public sectionTypes = SectionTypes;
  public sectionTitle: string;
  public sectionType: string;

  public courseForm: CourseForm = {};
  public languages: Language[] = [];

  @Output() reset = new EventEmitter();

  constructor(
    public rest: RestAPIService,
    public snack: MatSnackBar,
    public confirm: ConfirmationService,
    private dialogRef: MatDialogRef<CourseCreator>,
    private cloudinary: CloudnaryService,
    private languagesService: LanguagesControllerService,
  ) {}

  async ngOnInit() {
    this.languages = await this.getLanguages();
  }

  public saveCourse() {
    const newCourse: Course = {
      name: this.courseForm.name,
      title: this.courseForm.title,
      image: this.courseForm.image,
      language: this.courseForm.language,
      lessons: [],
      allowedUsers: [UserTypes.B2C, UserTypes.CLIENTS, UserTypes.ORGANIZATIONS],
      draft: true,
      hasCertificate: false,
      enabledToAll: true,
      allowedOrganizations: [],
    };

    this.dialogRef.close(newCourse);
  }

  public closeDialog() {
    this.dialogRef.close();
  }

  private async getLanguages() {
    const languages = await firstValueFrom(this.languagesService.languagesControllerGetLanguages());

    return languages;
  }

  public async addCourseImage() {
    try {
      const image: string = await this.cloudinary.handleUpload();
      this.courseForm.image = image;
    } catch (error) {
      this.openSnackbar(error.message);
    }
  }

  private openSnackbar(message: string) {
    this.snack.open(message, 'Close', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }
}
