<div class="text-center">
  <div class="header">Neuralign</div>
  <div class="content">{{ message }}</div>
  <div class="group-button">
    <button (click)="goToLink()">Go to page</button>
    <button
      class="button-close"
      (click)="closeDialog()"
    >
      Close
    </button>
  </div>
</div>
