<ng-container *ngIf="template === 'table'">
  <users-table-header heading="Admins"></users-table-header>

  <div class="search-and-button">
    <users-table-search-input
      placeholder="Search admins..."
      (userKeyUp)="applyFilter($event)"
    ></users-table-search-input>

    <div class="action-button">
      <button
        class="btn-primary main-action"
        (click)="createAdmin()"
      >
        Add New
      </button>
      <button
        class="btn-primary dropdown-toggle"
        (click)="toggleDropdown()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="dropdown-icon"
        >
          <path d="m6 9 6 6 6-6" />
        </svg>
      </button>

      <div
        class="dropdown-menu"
        [class.show]="isDropdownOpen"
      >
        <button
          class="dropdown-item"
          (click)="downloadFileExampleAdmins()"
        >
          Download template
        </button>
        <div class="loading-container">
          <div class="file-import-container">
            <input
              type="file"
              id="fileInputAdmin"
              (change)="importAdmins($event)"
              style="display: none"
            />
            <button
              class="dropdown-item"
              (click)="triggerFileInput()"
              [ngClass]="{ hidden: isLoadingImport }"
            >
              Import Admins
            </button>
            <div
              class="spinner-icon"
              [ngClass]="{ show: isLoadingImport }"
            ></div>
          </div>
        </div>
        <div class="loading-container">
          <button
            class="dropdown-item"
            (click)="exportAdmins()"
            [ngClass]="{ hidden: isLoadingExport }"
          >
            Export Admins
          </button>
          <div
            class="spinner-icon"
            [ngClass]="{ show: isLoadingExport }"
          ></div>
        </div>
      </div>
    </div>
  </div>

  <div class="table">
    <mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
        <mat-cell *matCellDef="let admin">
          {{ admin.name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
        <mat-cell
          *matCellDef="let admin"
          class="px-0"
        >
          <users-table-status-item
            [status]="admin.status"
          ></users-table-status-item>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
        <mat-cell *matCellDef="let admin">
          <div class="icons">
            <users-table-action-icon
              [icon]="'edit'"
              [tooltip]="'Edit admin'"
              (click)="editAdmin(admin)"
            ></users-table-action-icon>
            <users-table-action-icon
              [icon]="'redo'"
              [tooltip]="getVerifiedTooltip(admin.lastLogin)"
              [disabled]="admin.lastLogin"
              (click)="resendEmailVerification(admin)"
            ></users-table-action-icon>
            <users-table-action-icon
              [icon]="'trash'"
              [tooltip]="'Delete admin'"
              (click)="deleteAdmin(admin)"
            ></users-table-action-icon>
          </div>
        </mat-cell>
      </ng-container>
      <mat-header-row
        *matHeaderRowDef="['name', 'status', 'actions']; sticky: true"
      ></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: ['name', 'status', 'actions']"
      ></mat-row>
    </mat-table>

    <ng-container *ngIf="isDataSourceEmpty()">
      <users-empty-state
        class="no-users"
        text="This organization currently has no admins. You can add an admin by clicking on the Add New button in the top right corner."
        template="noData"
      ></users-empty-state>
    </ng-container>
  </div>
</ng-container>

<ng-container *ngIf="template === 'loading'"
  ><app-loading-indicator></app-loading-indicator
></ng-container>
