import { AfterViewInit, Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { firstValueFrom } from 'rxjs';
import {
  Order,
  ShopifyControllerGetOrders200ResponseCompletedOrdersInner,
  ShopifyControllerService,
} from 'src/app/core/openapi';
import { upperFirst } from 'lodash';
import {
  faCircle,
  faSpinner,
  IconDefinition,
  faCheckToSlot,
  faPlusCircle,
  faBoxArchive,
} from '@fortawesome/free-solid-svg-icons';
import { get, uniq } from 'lodash';
import { OrderStatus } from 'src/app/shared/enums/orders-enums';
import moment from 'moment';
import { DatePipe } from '@angular/common';
import { MatSort } from '@angular/material/sort';
import { OrdersByProvince } from 'src/app/shared/interfaces/classwallet.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ExcelService } from 'src/app/services/excel/excel.service';
import { ConfirmationService } from 'src/app/services/confirmation/confirmation.service';

@Component({
  selector: 'app-cw-dashboard',
  templateUrl: './cw-dashboard.component.html',
  styleUrl: './cw-dashboard.component.scss',
  providers: [DatePipe],
})
export class ClassWalletDashboardComponent implements OnInit, AfterViewInit {
  @ViewChildren(MatTable) tables: QueryList<MatTable<ShopifyControllerGetOrders200ResponseCompletedOrdersInner>>;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private shopifyService: ShopifyControllerService,
    private snackbar: MatSnackBar,
    private excelService: ExcelService,
    private confirmationService: ConfirmationService,
  ) {}

  public openColumns: string[] = [
    'email',
    'cost',
    'createdAt',
    'status',
    'account created',
    'token delivered',
    'actions',
  ];

  public approvedColumns: string[] = [
    'poNumber',
    'orderNumber',
    'email',
    'cost',
    'approvedAt',
    'status',
    'account created',
    'token delivered',
    'actions',
  ];

  public filterApprovedColumns: string[] = ['Approval date', 'Status', 'Account created', 'Token delivered', 'State'];

  public filterOpenColumns: string[] = ['Creation date', 'Status', 'Account created', 'State'];

  public activeColumn: string[] = [];
  public activeState = undefined;
  public stateInput = undefined;

  public readonly orderOptions = OrderStatus;
  public pageLoading: boolean = true;
  public readonly spinner: IconDefinition = faSpinner;
  public readonly circle: IconDefinition = faCircle;
  public readonly fulfill: IconDefinition = faCheckToSlot;
  public readonly plus: IconDefinition = faPlusCircle;
  public readonly archive: IconDefinition = faBoxArchive;

  public tableToLoad = null;

  public dataSource: MatTableDataSource<ShopifyControllerGetOrders200ResponseCompletedOrdersInner> =
    new MatTableDataSource([]);

  public activeTable = OrderStatus.OPEN;

  public completedOrders = [];
  public openOrders = [];

  public activeList: OrdersByProvince[] = [];

  async ngOnInit() {
    await this.getOrders();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  public buildDataSource(orders) {
    return new MatTableDataSource(orders);
  }

  public async getOrders() {
    this.activeColumn = this.openColumns;
    this.dataSource.sort = this.sort;
    const orders = await firstValueFrom(this.shopifyService.shopifyControllerGetOrders());
    this.completedOrders = orders.completedOrders || [];
    this.openOrders = orders.openOrders || [];
    this.dataSource.data = orders.openOrders;

    this.activeList = this.createCustomersTables(this.openOrders);

    this.pageLoading = false;
  }

  public createCustomersTables(orders: ShopifyControllerGetOrders200ResponseCompletedOrdersInner[]) {
    const provinces = uniq(orders.map((o) => o.customerProvince));
    const fullList: OrdersByProvince[] = [];

    for (const province of provinces) {
      const ordersByProvince = orders.filter((o) => o.customerProvince === province);
      fullList.push({
        province,
        orders: ordersByProvince,
        dataSource: new MatTableDataSource(ordersByProvince),
      });
    }

    this.stateInput = get(fullList, '[0].province', undefined);

    return fullList;
  }

  applyFilter(event: Event) {
    for (const orderList of this.activeList) {
      const dataSource = orderList.dataSource;

      dataSource.filterPredicate = (
        data: ShopifyControllerGetOrders200ResponseCompletedOrdersInner,
        filter: string,
      ) => {
        return data.email.trim().toLowerCase().includes(filter);
      };

      const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
      dataSource.filter = filterValue;

      if (dataSource.paginator) {
        dataSource.paginator.firstPage();
      }
    }
  }

  public changeOrders(status: OrderStatus) {
    switch (status) {
      case OrderStatus.COMPLETED:
        this.dataSource.data = this.completedOrders;
        this.activeTable = OrderStatus.COMPLETED;
        this.activeColumn = this.approvedColumns;
        this.activeList = this.createCustomersTables(this.completedOrders);
        this.renderTable();
        break;
      case OrderStatus.OPEN:
        this.dataSource.data = this.openOrders;
        this.activeTable = OrderStatus.OPEN;
        this.activeColumn = this.openColumns;
        this.activeList = this.createCustomersTables(this.openOrders);
        this.renderTable();
        break;
      default:
        break;
    }
  }

  public renderTable() {
    if (this.tables) {
      this.tables.forEach((table) => {
        table.renderRows();
      });
    }
  }

  public isEmptyDataSource(): boolean {
    return get(this.dataSource, 'data', []).length === 0;
  }

  public getStatus(status: string) {
    return upperFirst(status);
  }

  public getStatusClass(status: OrderStatus) {
    switch (status) {
      case OrderStatus.APPROVED:
        return 'approved';
      case OrderStatus.OPEN:
        return 'open';
      case OrderStatus.PAST_DUE:
        return 'past_due';
      default:
        break;
    }
  }

  public isPaid(status: string) {
    return status === 'paid';
  }

  public isApproved(status: string) {
    return status === 'approved';
  }

  public allowForceFulfillment(order: ShopifyControllerGetOrders200ResponseCompletedOrdersInner): boolean {
    if (!order.approvedAt) {
      return false;
    }

    const currentDate = moment();
    const givenDate = moment(order.approvedAt);
    const differenceInHours = currentDate.diff(givenDate, 'hours');

    // if the order is approved by more than one hour , have a PO number but the token is not delivered, allow a force fulfillment
    const isApproved: boolean = order.orderStatus === 'approved';
    const haveTimeThreshold: boolean = differenceInHours >= 1;
    const havePoNumber: boolean = order.poNumber !== undefined;

    return isApproved && haveTimeThreshold && havePoNumber && !order.tokenDelivered;
  }

  public async forceTokenDelivery(order) {
    if (!this.allowForceFulfillment(order)) {
      return;
    }

    this.confirmationService
      .createConfirmation(
        'Warning',
        'This will complete this order and deliver this customer token. Are you sure you want to proceed',
        'Yes',
        'No',
      )
      .then(async () => {
        try {
          const orderList = this.activeList.find((l) => l.orders.some((o) => o.id === order.id));
          const index = this.activeList.indexOf(orderList);
          this.tableToLoad = index;

          const updatedOrder = await firstValueFrom(this.shopifyService.shopifyControllerForceTokenDelivery(order.id));

          if (!updatedOrder) {
            throw Error('Failed to get update the order');
          }

          if (orderList) {
            const relatedOrder = orderList.dataSource.data[order];

            if (relatedOrder) {
              relatedOrder.accountId = updatedOrder.accountId;
              relatedOrder.tokenDelivered = updatedOrder.tokenDelivered;
            }

            this.renderTable();
          }

          this.snackbar.open('Order completed!', 'Close', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });

          this.tableToLoad = null;
        } catch (error) {
          this.tableToLoad = null;
          this.snackbar.open(error.message, 'Close', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        }
      });
  }

  public async archiveOrder(order) {
    this.confirmationService
      .createConfirmation('Warning', 'Are you sure you want to archive this order?', 'Yes', 'No')
      .then(async () => {
        try {
          const orderList = this.activeList.find((l) => l.orders.some((o) => o.id === order.id));
          const index = this.activeList.indexOf(orderList);
          this.tableToLoad = index;

          const updatedOrder = await firstValueFrom(this.shopifyService.shopifyControllerArchiveOrder(order.id));

          if (!updatedOrder) {
            throw Error('Failed to archive the order');
          }

          if (orderList) {
            this.removeOrderFromTable(index, orderList, order);
          }

          this.snackbar.open('Order archived!', 'Close', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });

          this.tableToLoad = null;
        } catch (error) {
          this.tableToLoad = null;
          this.snackbar.open(error.message, 'Close', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        }
      });
  }

  public removeOrderFromTable(index: number, orderList: OrdersByProvince, order: Order) {
    const orderIndex = orderList.dataSource.data.indexOf(order);
    orderList.dataSource.data.splice(orderIndex, 1);

    if (orderList.dataSource.data.length === 0) {
      this.activeList.splice(index, 1);
    }

    this.renderTable();
    this.removeOrderFromList(order);
  }

  public removeOrderFromList(order: Order) {
    switch (this.activeTable) {
      case OrderStatus.OPEN: {
        const index = this.openOrders.indexOf(order);
        this.openOrders.splice(index, 1);
        break;
      }
      case OrderStatus.COMPLETED: {
        const index = this.completedOrders.indexOf(order);
        this.completedOrders.splice(index, 1);
        break;
      }
      default:
        break;
    }
  }

  public getDate(date: string) {
    const correctDate = Number(date + '000');
    const dateFormat = new Date(correctDate);
    return ` ${dateFormat.getDate()} / ${dateFormat.getMonth() + 1} / ${dateFormat.getFullYear()} `;
  }

  public formatTitle(title: string): string {
    return title
      .replace(/([a-z0-9])([A-Z])/g, '$1 $2') // Add space before capital letters
      .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
  }

  public exportStatement(): void {
    this.confirmationService
      .createConfirmation(
        'Neuralign',
        'Do you want to export your completed orders statement to a excel file?',
        'Yes',
        'No',
      )
      .then(async () => {
        const orders = this.activeList.map((l) => l.dataSource.filteredData).flat();
        const formattedOrders = orders
          .filter((o) => o.orderStatus === OrderStatus.APPROVED)
          .map((o) => {
            return {
              customerName: o.customerName,
              classwalletPO: get(o, 'poNumber', ''),
              invoiceNumber: get(o, 'orderNumber', ''),
              date: new Intl.DateTimeFormat('en-US', {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit',
              }).format(new Date(o.approvedAt)),
              totalOrderAmt: o.total_price,
              discountedAmt: Number(o.total_price) - Number(o.total_price) * 0.15,
            };
          });

        const headers = ['customerName', 'classwalletPO', 'invoiceNumber', 'date', 'totalOrderAmt', 'discountedAmt'];

        const rows = formattedOrders.map((order) => [
          order.customerName,
          order.classwalletPO,
          order.invoiceNumber,
          order.date,
          order.totalOrderAmt,
          order.discountedAmt,
        ]);

        const formattedHeaders = headers.map((header) => this.formatTitle(header));
        this.excelService.exportClasswalletStatement(formattedHeaders, rows);
      });
  }
}
