import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';

declare let cloudinary: any;

interface CloudinaryResponse {
  secure_url?: string;
  url?: string;
  width?: number;
  height?: number;
  error?: {
    message: string;
  };
}

interface CloudinaryConfig {
  cloud_name: string;
  upload_preset: string;
  secure: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class CloudnaryService {
  private config: CloudinaryConfig;

  constructor(private _snackBar: MatSnackBar) {
    this.getCloudnaryConfig();
  }

  public async handleUpload(logo?: boolean) {
    const response: CloudinaryResponse = await this.openUploadWidget();
    if (response.width < 200 || response.height < 200) {
      // Extract the public_id from the original URL
      // After, construct URL with transformation (w_200,h_200,c_pad,b_white // width, height, crop, background)
      const publicId = response.secure_url.split('/upload/')[1].split('/')[1].split('.')[0];
      const transformedUrl = `https://res.cloudinary.com/${this.config.cloud_name}/image/upload/w_200,h_200,c_pad,b_white/${publicId}`;

      return transformedUrl;
    }

    return response.secure_url ?? response.url;
  }

  private async openUploadWidget() {
    return new Promise((resolve) => {
      cloudinary.openUploadWidget(this.config, (error, result: CloudinaryResponse[]) => {
        if (result) {
          const [imageResponse] = result;
          resolve(imageResponse);
        } else if (error && error.message !== 'User closed widget') {
          throw new error(error);
        }
      });
    });
  }

  private getCloudnaryConfig() {
    this.config = {
      cloud_name: environment.CLOUDINARY_CLOUD_NAME,
      upload_preset: environment.CLOUDINARY_UPLOAD_PRESET,
      secure: true,
    };
  }
}
